export const Coaching = {
  title: "Mein Coaching",
  link: "/coaching",
  isRelative: true,
  items: [
    { name: "Mein Coaching", link: "/coaching", isRelative: true },
    { name: "Erstgespräch", link: "/erstgespraech", isRelative: true },
    {
      name: "Coaching PRO",
      link: "/coaching-pro",
      isRelative: true,
    },
  ],
};

export const App = {
  title: "App",
  link: "/app",
  isRelative: true,
  items: [
    { name: "App", link: "/app", isRelative: true },
    {
      name: "App erstellen",
      link: "/app-erstellen",
      isButton: true,
    },
  ],
};

export const NavbarMenusOnMobile = [
  {
    id: 1,
    title: Coaching.title,
    link: Coaching.link,
    isRelative: Coaching.isRelative,
    items: Coaching.items,
  },
  {
    id: 2,
    title: App.title,
    link: App.link,
    isRelative: App.isRelative,
    items: App.items,
  },
];
