import React, { useEffect, useState } from "react";

import Topbar from "components/topbar/Topbar";
import Card from "components/card/Card";
import * as S from "./start.styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "components/formComponents/button/Button";
import Input from "components/formComponents/input/Input";
import TranslationMessage from "configs/translations/TranslationMessage";

import * as AuthActions from "redux/auth/auth.actions";
import * as CoachActions from "redux/coach/coach.actions";

import { useForm } from "react-hook-form";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import format from "format-number";
import GreenCheck from "assets/images/GreenCheck.svg";
import ProfilePic from "assets/images/profile.png";
import AnnaPic from "assets/images/reviews/Anna-Seiner.png";
import MariePic from "assets/images/reviews/Marie-Sophie-Silmar-01.png";

import Rating from "assets/images/rating.svg";
import CloseIcon from "assets/images/close-icon.svg";
import PlusIcon from "assets/images/plus-icon.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SELECTED_PRICE_ID = "price_1N819zA7QE5sDrDRZqpzYbAF";

const initialUserData = {
  sex: "male",
  aimID: 1,
  height: "0",
  weight: "0",
  nutritiontypeID: 2,
  birthday: "",
  email: "",
  prename: "",
  surname: "",
  grant_type: "password",
  isTraining: true,
  trainingdays: [],
  totalTrainingDays: 0,
  routines: {},
  dailyRoutineData: [],
  movementtypes: [],
  mobile: "",
  sleepValue: 60,
  lyingDownValue: 60,
  standingValue: 60,
  sittingValue: 60,
  walkingValue: 60,
  exercisingValue: 60,
  eatingHabit1: "registration.eatingHabit.yoghurtWithFruit",
  eatingHabit2: "registration.eatingHabit.apple",
  eatingHabit3: "registration.eatingHabit.Avocado",
  eatingHabit4: "registration.eatingHabit.Oatmeal",
  eatingHabit5: "registration.eatingHabit.chilliConCarne",
};

const BASE_URL = "https://app.foodiary.app/api/"; //"http://3.64.74.74/api/";

const Start = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [prices, setPrices] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(SELECTED_PRICE_ID);
  const [validVoucher, setValidVoucher] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(-1);
  const [perks, serPerks] = useState([
    "Persönlicher Ernährungsplan",
    "Rezeptfilter mit künstlicher Intelligenz",
    "Tagesplanung mit über 1200 geprüften Rezepten",
    "Einfache Tages- und Wochenplanung",
    "Intelligente Einkaufsliste",
    "Dauerhafte Betreuung",
  ]);
  const [tools, setTools] = useState([
    {
      textId: "start.offer.tools1",
      isAvailableinBasic: true,
    },
    {
      textId: "start.offer.tools2",
      isAvailableinBasic: true,
    },
    {
      textId: "start.offer.tools3",
      isAvailableinBasic: true,
    },
    {
      textId: "start.offer.tools4",
      isAvailableinBasic: false,
    },
    {
      textId: "start.offer.tools5",
      isAvailableinBasic: false,
    },
    {
      textId: "start.offer.tools6",
      isAvailableinBasic: false,
    },
    {
      textId: "start.offer.tools7",
      isAvailableinBasic: false,
    },
  ]);

  const [questions, setQuestions] = useState([
    {
      question: "start.faq.question1",
      answer: "start.faq.answer1",
    },
    {
      question: "start.faq.question2",
      answer: "start.faq.answer2",
    },
    {
      question: "start.faq.question3",
      answer: "start.faq.answer3",
    },
    {
      question: "start.faq.question4",
      answer: "start.faq.answer4",
    },
    {
      question: "start.faq.question5",
      answer: "start.faq.answer5",
    },
  ]);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm();

  dispatch(AuthActions.setRegistrationData(initialUserData));

  useEffect(() => {
    // const URL = "https://leyla-fci80404.foodiary.app".split(".app")[0] + ".app";
    const URL = window.location.href.split(".app")[0] + ".app";

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: URL },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.add("app-register");
    const url = new URL(window.location.href);
    window.localStorage.setItem("price_id", SELECTED_PRICE_ID);
    const load = async () => {
      const res = await axios.get(BASE_URL + "v1/stripe/prices");
      const prices = res.data.data
        .filter(
          (item) => item.type == "recurring" && item.id === SELECTED_PRICE_ID
        )
        .sort((a, b) => a.unit_amount + b.unit_amount)
        .map((item, idx) => {
          const {
            id,
            unit_amount,
            recurring: { interval, interval_count },
          } = item;
          return {
            id,
            duration: interval == "year" ? 12 : interval_count,
            heading: `start.card.headerHeading.${idx + 1}`,
            price: format({ suffix: " €", round: 2 })(unit_amount / 100),
            weeklyPrice: format({ suffix: " €", round: 2 })(
              unit_amount / 100 / (interval == "year" ? 12 : interval_count) / 4
            ),
            month: "start.card.months",
          };
        });
      setPrices(prices);
    };
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
    load();
    window.addEventListener("resize", handleResize);
    return () => {
      document.body.classList.remove("app-register");
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleNext = () => {
    if (selectedPrice === -1) {
      history.push("/app-erstellen");
    } else {
      history.push("/registration/gender");
    }
  };

  const handlePrev = () => {
    history.push("/dashboard");
  };

  const CardData = [
    {
      id: "price_1NH3NHA7QE5sDrDRcCxTno3P",
      duration: "12",
      heading: "start.card.headerHeading.1",
      price: "54,99 €",
      weeklyPrice: "1,06 €",
      month: "start.card.months",
    },
    {
      id: "price_1LyXLbA7QE5sDrDRmDiONjkC",
      duration: "3",
      heading: "start.card.headerHeading.2",
      price: "21,99 €",
      weeklyPrice: "1,83 €",
      month: "start.card.months",
    },
    {
      id: "price_1LyXL1A7QE5sDrDRUAIwsxcC",
      duration: "1",
      heading: "start.card.headerHeading.3",
      price: "8,99 €",
      weeklyPrice: "2,05 €",
      month: "start.card.month",
    },
  ];

  return (
    <>
      {!isMobile && (
        <Topbar
          next={
            selectedPrice === -1 ? "/app-erstellen" : "/registration/gender"
          }
        />
      )}
      <S.StartContainer>
        <S.StartHeading>
          <TranslationMessage id="start.heading" />
        </S.StartHeading>
        <S.StartSubHeading>
          <TranslationMessage id="start.subHeading" />
        </S.StartSubHeading>
        <S.StartCardContainer>
          {prices.map((card, index) => {
            return (
              <Card
                width={!isMobile ? "100%" : "96%"}
                height="auto"
                margin="auto"
                textAlign="left"
                key={card.id}
                onClick={() => {
                  setSelectedPrice(card.id);
                  window.localStorage.setItem("price_id", card.id);
                }}
                style={{
                  marginRight: isMobile ? 10 : 20,
                  cursor: "pointer",
                  display: !isMobile ? "flex" : "",
                  flexDirection: !isMobile ? "row" : "",
                  justifyContent: isMobile ? "center" : "left",
                  marginBottom: isMobile ? 10 : 20,
                  fontFamily: "RubikMedium, Georgia !important", // Replace "Your Font Family" with the desired font family name
                  border:
                    card.id === selectedPrice
                      ? "3px solid #1C9D87"
                      : "2px solid white",
                }}
              >
                {/* <S.StartCardHeader cardIndex={index}>
                  <S.HeaderText>
                    {" "}
                    <TranslationMessage id={card.heading} />{" "}
                  </S.HeaderText>
                </S.StartCardHeader> */}

                <S.StartCardInnerContainer>
                  <S.DurationText> {card.duration}</S.DurationText>
                  <S.MonthText>
                    <TranslationMessage id={card.month} />
                  </S.MonthText>
                  <S.Price>
                    <TranslationMessage id={card.price} />
                  </S.Price>
                  {/* {!isMobile && ( */}
                  <S.PerMonth>
                    <TranslationMessage id="start.card.threeMonths" />
                  </S.PerMonth>
                  {/* )} */}
                  <S.PerWeek>
                    <TranslationMessage id={card.weeklyPrice} />
                    <TranslationMessage id="start.card.perWeek" />
                  </S.PerWeek>
                </S.StartCardInnerContainer>
                {isMobile && (
                  <div
                    style={{
                      borderBottom: "1px solid rgba(28, 157, 135, 0.1)",
                      width: 90 + "%",
                      marginTop: 4,
                      marginBottom: 4,
                      marginLeft: 5 + "%",
                    }}
                  >
                    {" "}
                  </div>
                )}
                <S.PerksContainer>
                  {perks.map((currentItem, index) => {
                    return (
                      <S.RowDiv style={{ marginBottom: 15 }}>
                        <img
                          src={GreenCheck}
                          alt="GreenCheck"
                          style={{ width: 22, height: 20, marginTop: 5 }}
                        />
                        <S.PerksText>{currentItem}</S.PerksText>
                      </S.RowDiv>
                    );
                  })}
                </S.PerksContainer>
              </Card>
            );
          })}
        </S.StartCardContainer>
        {isMobile && (
          <>
            <S.NextButtonContainer>
              <Button
                type="submit"
                color="#fff"
                onClick={() => handleNext()}
                style={{
                  width: 95 + "%",
                  borderRadius: "6px",
                  background:
                    "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <TranslationMessage id="button.next" />
              </Button>
            </S.NextButtonContainer>
          </>
        )}

        {isMobile ? (
          <S.StartOfferContainer>
            <S.StartOfferDiv style={{ marginTop: 20 }}>
              <S.RowDiv>
                <img
                  src={GreenCheck}
                  alt="GreenCheck"
                  style={{ marginRight: 16, marginTop: -90 }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <S.StartOfferHeading>
                    <TranslationMessage id="start.offer.subHeading1" />
                  </S.StartOfferHeading>
                  <S.StartOfferDetails>
                    <TranslationMessage id="start.offer.details1" />
                  </S.StartOfferDetails>
                </div>
              </S.RowDiv>
            </S.StartOfferDiv>
            <S.StartOfferDiv>
              <S.RowDiv>
                <img
                  src={GreenCheck}
                  alt="GreenCheck"
                  style={{ marginRight: 16, marginTop: -90 }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <S.StartOfferHeading>
                    <TranslationMessage id="start.offer.subHeading2" />
                  </S.StartOfferHeading>
                  <S.StartOfferDetails>
                    <TranslationMessage id="start.offer.details2" />
                  </S.StartOfferDetails>
                </div>
              </S.RowDiv>
            </S.StartOfferDiv>
            <S.StartOfferDiv>
              <S.RowDiv>
                <img
                  src={GreenCheck}
                  alt="GreenCheck"
                  style={{ marginRight: 16, marginTop: -90 }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <S.StartOfferHeading>
                    <TranslationMessage id="start.offer.subHeading3" />
                  </S.StartOfferHeading>
                  <S.StartOfferDetails>
                    <TranslationMessage id="start.offer.details3" />
                  </S.StartOfferDetails>
                </div>
              </S.RowDiv>
            </S.StartOfferDiv>
            <S.StartOfferDiv>
              <S.RowDiv>
                <img
                  src={GreenCheck}
                  alt="GreenCheck"
                  style={{ marginRight: 16, marginTop: -90 }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <S.StartOfferHeading>
                    <TranslationMessage id="start.offer.subHeading4" />
                  </S.StartOfferHeading>
                  <S.StartOfferDetails>
                    <TranslationMessage id="start.offer.details4" />
                  </S.StartOfferDetails>
                </div>
              </S.RowDiv>
            </S.StartOfferDiv>
            <S.StartOfferDiv>
              <S.RowDiv>
                <img
                  src={GreenCheck}
                  alt="GreenCheck"
                  style={{ marginRight: 16, marginTop: -110 }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <S.StartOfferHeading>
                    <TranslationMessage id="start.offer.subHeading5" />
                  </S.StartOfferHeading>
                  <S.StartOfferDetails>
                    <TranslationMessage id="start.offer.details5" />
                  </S.StartOfferDetails>
                </div>
              </S.RowDiv>
            </S.StartOfferDiv>
            <S.StartOfferDiv>
              <S.RowDiv>
                <img
                  src={GreenCheck}
                  alt="GreenCheck"
                  style={{ marginRight: 16, marginTop: -110 }}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <S.StartOfferHeading>
                    <TranslationMessage id="start.offer.subHeading6" />
                  </S.StartOfferHeading>
                  <S.StartOfferDetails>
                    <TranslationMessage id="start.offer.details6" />
                  </S.StartOfferDetails>
                </div>
              </S.RowDiv>
            </S.StartOfferDiv>
          </S.StartOfferContainer>
        ) : (
          <S.StartOfferContainer>
            <S.CenterDiv>
              <S.StartSubHeading>
                <TranslationMessage id="start.offer.heading" />
              </S.StartSubHeading>
            </S.CenterDiv>
            <br />
            <S.RowDiv>
              <S.StartOfferDiv>
                <S.RowDiv>
                  <img
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{ marginRight: 16, marginTop: -90 }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.StartOfferHeading>
                      <TranslationMessage id="start.offer.subHeading1" />
                    </S.StartOfferHeading>
                    <S.StartOfferDetails>
                      <TranslationMessage id="start.offer.details1" />
                    </S.StartOfferDetails>
                  </div>
                </S.RowDiv>
              </S.StartOfferDiv>
              <S.StartOfferDiv>
                <S.RowDiv style={{ marginLeft: 20 }}>
                  <img
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{ marginRight: 16, marginTop: -110 }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.StartOfferHeading>
                      <TranslationMessage id="start.offer.subHeading2" />
                    </S.StartOfferHeading>
                    <S.StartOfferDetails>
                      <TranslationMessage id="start.offer.details2" />
                    </S.StartOfferDetails>
                  </div>
                </S.RowDiv>
              </S.StartOfferDiv>
            </S.RowDiv>
            <S.RowDiv>
              <S.StartOfferDiv>
                <S.RowDiv>
                  <img
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{ marginRight: 16, marginTop: -90 }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.StartOfferHeading>
                      <TranslationMessage id="start.offer.subHeading3" />
                    </S.StartOfferHeading>
                    <S.StartOfferDetails>
                      <TranslationMessage id="start.offer.details3" />
                    </S.StartOfferDetails>
                  </div>
                </S.RowDiv>
              </S.StartOfferDiv>
              <S.StartOfferDiv>
                <S.RowDiv style={{ marginLeft: 20 }}>
                  <img
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{ marginRight: 16, marginTop: -90 }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.StartOfferHeading>
                      <TranslationMessage id="start.offer.subHeading4" />
                    </S.StartOfferHeading>
                    <S.StartOfferDetails>
                      <TranslationMessage id="start.offer.details4" />
                    </S.StartOfferDetails>
                  </div>
                </S.RowDiv>
              </S.StartOfferDiv>
            </S.RowDiv>
            <S.RowDiv>
              <S.StartOfferDiv>
                <S.RowDiv>
                  <img
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{ marginRight: 16, marginTop: -110 }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.StartOfferHeading>
                      <TranslationMessage id="start.offer.subHeading5" />
                    </S.StartOfferHeading>
                    <S.StartOfferDetails>
                      <TranslationMessage id="start.offer.details5" />
                    </S.StartOfferDetails>
                  </div>
                </S.RowDiv>
              </S.StartOfferDiv>
              <S.StartOfferDiv>
                <S.RowDiv style={{ marginLeft: 20 }}>
                  <img
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{ marginRight: 16, marginTop: -110 }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.StartOfferHeading>
                      <TranslationMessage id="start.offer.subHeading6" />
                    </S.StartOfferHeading>
                    <S.StartOfferDetails>
                      <TranslationMessage id="start.offer.details6" />
                    </S.StartOfferDetails>
                  </div>
                </S.RowDiv>
              </S.StartOfferDiv>
            </S.RowDiv>
          </S.StartOfferContainer>
        )}
        <S.ToolsContainer>
          <S.CenterDiv>
            <S.ToolText>
              <TranslationMessage id="start.offer.tools" />
            </S.ToolText>
          </S.CenterDiv>
          <S.CenterDiv>
            <div style={{ width: 75 + "%" }}></div>
            <div style={{ width: 15 + "%" }}>
              <S.StartOfferRegular>
                <TranslationMessage id="start.offer.basic" />
              </S.StartOfferRegular>
            </div>
            <div style={{ width: 10 + "%" }}>
              <S.StartOfferRegular>
                <TranslationMessage id="start.offer.pro" />
              </S.StartOfferRegular>
            </div>
          </S.CenterDiv>
          {tools.map((currentTool, index) => {
            return (
              <S.CenterDiv
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "",
                  height: 60,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: 75 + "%",
                    textAlign: "left",
                    paddingLeft: 20,
                    borderRadius: 8,
                  }}
                >
                  <S.StartOfferRegular
                    style={{ paddingTop: 12, fontWeight: "normal" }}
                  >
                    <TranslationMessage id={currentTool.textId} />
                  </S.StartOfferRegular>
                </div>
                <div style={{ width: 15 + "%" }}>
                  <div style={{ width: 10 + "%" }}>
                    <img
                      src={
                        currentTool.isAvailableinBasic ? GreenCheck : CloseIcon
                      }
                      alt="Check"
                      style={{ marginRight: 36, marginTop: -90 }}
                    />
                  </div>
                </div>
                <div style={{ width: 10 + "%" }}>
                  <img
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{ marginRight: 36, marginTop: -90 }}
                  />
                </div>
              </S.CenterDiv>
            );
          })}
        </S.ToolsContainer>
        {isMobile && (
          <Button
            onClick={() => window.scrollTo(0, 0)}
            color="#1C9D87"
            outlined
            style={{
              width: isMobile ? 85 + "%" : "",
              marginBottom: 30,
              marginTop: -20,
            }}
          >
            <TranslationMessage id="start.subscription.button" />
          </Button>
        )}
        <S.CenterDiv>
          <S.StartSubHeading>
            <TranslationMessage id="start.review.heading" />
          </S.StartSubHeading>
        </S.CenterDiv>
        {isMobile ? (
          <Carousel showArrows={false} showStatus={false} infiniteLoop={true}>
            <div>
              <S.ReviewContainer>
                <S.RowDiv>
                  <div style={{ width: 25 + "%" }}>
                    <img
                      src={MariePic}
                      alt="Profile"
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 50 + "%",
                        border: "3px solid #8DCEC3",
                        padding: 1.5,
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 10, textAlign: "left" }}>
                    <div>
                      <img
                        src={Rating}
                        alt="Rating"
                        style={{ width: 60, height: 15, paddingTop: 6 }}
                      />
                    </div>
                    <S.StartOfferRegular
                      style={{ color: "#142C1F", marginTop: 1 }}
                    >
                      Marie Sophie Silmar
                    </S.StartOfferRegular>
                    <S.PerWeek style={{ textAlign: "left", marginTop: -10 }}>
                      26 Jahre
                    </S.PerWeek>
                  </div>
                </S.RowDiv>
                <div style={{ marginTop: 20 }}>
                  <S.StartOfferRegular
                    style={{ fontSize: 14, lineHeight: 1.4 }}
                  >
                    <TranslationMessage id="start.review.details1" />
                  </S.StartOfferRegular>
                </div>
              </S.ReviewContainer>
            </div>
            <div>
              <S.ReviewContainer>
                <S.RowDiv>
                  <div style={{ width: 25 + "%" }}>
                    <img
                      src={AnnaPic}
                      alt="Profile"
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 50 + "%",
                        border: "3px solid #8DCEC3",
                        padding: 1.5,
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 10, textAlign: "left" }}>
                    <div>
                      <img
                        src={Rating}
                        alt="Rating"
                        style={{ width: 60, height: 15, paddingTop: 6 }}
                      />
                    </div>
                    <S.StartOfferRegular
                      style={{ color: "#142C1F", marginTop: 1 }}
                    >
                      Anna Seiner
                    </S.StartOfferRegular>
                    <S.PerWeek style={{ textAlign: "left", marginTop: -10 }}>
                      26 Jahre
                    </S.PerWeek>
                  </div>
                </S.RowDiv>
                <div style={{ marginTop: 20 }}>
                  <S.StartOfferRegular
                    style={{ fontSize: 14, lineHeight: 1.4 }}
                  >
                    <TranslationMessage id="start.review.details2" />
                  </S.StartOfferRegular>
                </div>
              </S.ReviewContainer>
            </div>
          </Carousel>
        ) : (
          <S.CenterDiv>
            <S.RowDiv style={{ maxWidth: 670 }}>
              <S.ReviewContainer>
                <S.RowDiv>
                  <div style={{ width: 25 + "%" }}>
                    <img
                      src={MariePic}
                      alt="Profile"
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 50 + "%",
                        border: "3px solid #8DCEC3",
                        padding: 1.5,
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 15, textAlign: "left" }}>
                    <div>
                      <img src={Rating} alt="Rating" style={{}} />
                    </div>
                    <S.StartOfferRegular
                      style={{ color: "#142C1F", marginTop: 6 }}
                    >
                      Marie Sophie Silmar
                    </S.StartOfferRegular>
                    <S.PerWeek style={{ textAlign: "left", marginTop: -10 }}>
                      26 Jahre
                    </S.PerWeek>
                  </div>
                </S.RowDiv>
                <div style={{ marginTop: 20 }}>
                  <S.StartOfferRegular
                    style={{ fontSize: 14, lineHeight: 1.4 }}
                  >
                    <TranslationMessage id="start.review.details1" />
                  </S.StartOfferRegular>
                </div>
              </S.ReviewContainer>
              <S.ReviewContainer style={{ marginLeft: 20 }}>
                <S.RowDiv>
                  <div style={{ width: 25 + "%" }}>
                    <img
                      src={AnnaPic}
                      alt="Profile"
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 50 + "%",
                        border: "3px solid #8DCEC3",
                        padding: 1.5,
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 15, textAlign: "left" }}>
                    <div>
                      <img src={Rating} alt="Rating" />
                    </div>
                    <S.StartOfferRegular
                      style={{ color: "#142C1F", marginTop: 6 }}
                    >
                      Anna Seiner
                    </S.StartOfferRegular>
                    <S.PerWeek style={{ textAlign: "left", marginTop: -10 }}>
                      26 Jahre
                    </S.PerWeek>
                  </div>
                </S.RowDiv>
                <div style={{ marginTop: 20 }}>
                  <S.StartOfferRegular
                    style={{ fontSize: 14, lineHeight: 1.4 }}
                  >
                    <TranslationMessage id="start.review.details2" />
                  </S.StartOfferRegular>
                </div>
              </S.ReviewContainer>
            </S.RowDiv>
          </S.CenterDiv>
        )}
        <S.CenterDiv style={{ marginBottom: 20 }}>
          <S.FAQHeading>FAQ</S.FAQHeading>
        </S.CenterDiv>
        {isMobile ? (
          <S.CenterDiv style={{ marginBottom: 40 }}>
            <div>
              {questions.map((currentFaq, index) => {
                return (
                  <div>
                    <S.RowDiv
                      onClick={() =>
                        setSelectedAnswer(index !== selectedAnswer ? index : -1)
                      }
                      style={{
                        cursor: "pointer",
                        justifyContent: "space-between",
                        width: 100 + "%",
                      }}
                    >
                      <S.StartOfferRegular
                        style={{
                          paddingTop: 12,
                          fontWeight: "normal",
                          fontSize: 14,
                          marginLeft: 30,
                        }}
                      >
                        <TranslationMessage id={currentFaq.question} />
                      </S.StartOfferRegular>
                      <img
                        src={PlusIcon}
                        style={{
                          marginRight: 20,
                          marginTop: 12,
                          width: 15,
                          height: 15,
                        }}
                        alt="plus-icon"
                      />
                    </S.RowDiv>
                    <div
                      style={{
                        borderBottom:
                          index !== 4
                            ? "1px solid rgba(28, 157, 135, 0.1)"
                            : "",
                        width: 100 + "%",
                        marginTop: 4,
                        marginBottom: 4,
                      }}
                    >
                      {index == selectedAnswer && (
                        <div
                          style={{
                            maxWidth: 560,
                            textAlign: "left",
                            marginBottom: -13,
                            marginLeft: 30,
                            marginRight: 40,
                          }}
                        >
                          <S.StartOfferDetails>
                            <TranslationMessage id={currentFaq.answer} />
                          </S.StartOfferDetails>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </S.CenterDiv>
        ) : (
          <S.CenterDiv style={{ marginBottom: 40 }}>
            <div>
              {questions.map((currentFaq, index) => {
                return (
                  <div>
                    <S.RowDiv
                      onClick={() =>
                        setSelectedAnswer(index !== selectedAnswer ? index : -1)
                      }
                      style={{
                        cursor: "pointer",
                        justifyContent: "space-between",
                        width: 124 + "%",
                        marginLeft: -60,
                      }}
                    >
                      <S.StartOfferRegular
                        style={{ paddingTop: 12, fontWeight: "normal" }}
                      >
                        <TranslationMessage id={currentFaq.question} />
                      </S.StartOfferRegular>
                      <img
                        src={PlusIcon}
                        style={{
                          marginRight: 20,
                          marginTop: 15,
                          width: 15,
                          height: 15,
                        }}
                        alt="plus-icon"
                      />
                    </S.RowDiv>
                    <div
                      style={{
                        borderBottom:
                          index !== 4
                            ? "1px solid rgba(28, 157, 135, 0.1)"
                            : "",
                        width: 124 + "%",
                        marginLeft: -60,
                        marginTop: 4,
                        marginBottom: 4,
                      }}
                    >
                      {index == selectedAnswer && (
                        <div
                          style={{
                            maxWidth: 560,
                            textAlign: "left",
                            marginBottom: -13,
                          }}
                        >
                          <S.StartOfferDetails>
                            <TranslationMessage id={currentFaq.answer} />
                          </S.StartOfferDetails>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </S.CenterDiv>
        )}
        {!isMobile && (
          <S.CenterDiv style={{ marginBottom: 40 }}>
            <Button
              onClick={() => window.scrollTo(0, 0)}
              color="#fff"
              style={{
                marginTop: 5,
                width: 160,
                borderRadius: "6px",
                background:
                  "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <TranslationMessage id="start.subscription.button" />
            </Button>
          </S.CenterDiv>
        )}
        {isMobile && (
          <>
            <S.CenterDiv style={{ marginBottom: 0 }}>
              <S.StartSubHeading>
                <TranslationMessage id="start.mission" />
              </S.StartSubHeading>
            </S.CenterDiv>
            <div style={{ marginBottom: 20 }}>
              <S.StartOfferRegular
                style={{ margin: 25, width: 90 + "%", fontSize: 14 }}
              >
                <TranslationMessage id="start.mission.details1" />
              </S.StartOfferRegular>
              <S.StartOfferRegular
                style={{ margin: 25, width: 90 + "%", fontSize: 14 }}
              >
                <TranslationMessage id="start.mission.details2" />
              </S.StartOfferRegular>
              <S.StartOfferRegular
                style={{ margin: 25, width: 90 + "%", fontSize: 14 }}
              >
                <TranslationMessage id="start.mission.details3" />
              </S.StartOfferRegular>
              <S.StartOfferRegular
                style={{ margin: 25, width: 90 + "%", fontSize: 14 }}
              >
                <TranslationMessage id="start.mission.details4" />
              </S.StartOfferRegular>
            </div>
            <S.CenterDiv style={{ marginBottom: 40 }}>
              <Button
                onClick={() => window.scrollTo(0, 0)}
                color="#fff"
                style={{
                  marginTop: 5,
                  width: 90 + "%",
                  marginBottom: 60,
                  borderRadius: "6px",
                  background:
                    "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <TranslationMessage id="start.subscription.button" />
              </Button>
            </S.CenterDiv>
          </>
        )}
      </S.StartContainer>
    </>
  );
};

export default Start;
