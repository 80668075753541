import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Modal = styled.div`
  background-color: #fefefe;
  padding: 20px;
  width: 724px;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 90%;
    max-width: 90%;
    /* max-height: 600px; */
    padding: 16px;
    /* overflow-y: auto;
    overflow-x: hidden; */
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: end !important;
`;

const ModalContent = styled.div`
  padding: 0 1rem;
  margin: 1rem 0;

  @media (max-width: 800px) {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    height: 12px;
    width: 7px;
    background: #dad6d6;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-corner {
    background: #ffffff;
    border-radius: 5px;
  }
`;

const Close = styled.div`
  width: 3rem;
  height: 3rem;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: #142c1f;
  font-size: 3.3rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const PaymentType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

const MonthlyPayment = styled.p`
  color: #a5b5af;
  font-size: 2rem;
  font-weight: 400;

  ${({ active }) =>
    active &&
    `
    color: #2ecf96;
    font-weight: 500;
  `}
`;

const OneTimePayment = styled.p`
  color: #a5b5af;
  font-size: 2rem;
  font-weight: 400;

  ${({ active }) =>
    active &&
    `
    color: #2ecf96;
    font-weight: 600;
  `}

  @media(max-width: 600px) {
    text-align: end;
  }
`;

const PaymentBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 2rem;
  margin-top: 3rem;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  @media (max-width: 600px) {
    align-items: center;
  }
`;

const PaymentLeftBox = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #142c1f;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  padding: 2rem;
  width: 11rem;
  /* height: 10rem; */
  min-width: 10rem;

  span {
    color: #ffffff;
    font-size: 2rem;
  }

  /* @media (max-width: 600px) {
    width: 10 0px;
  } */
`;
const PaymentInfo = styled.div`
  /* padding-block: 1rem; */
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const PlanTitle = styled.div`
  color: #142c1f;
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 0;
`;

const CancelTitle = styled.div`
  color: #a5b5af;
  font-size: 1.88rem;
  margin-top: 0.5rem;
  margin-bottom: 1.4rem;
  font-weight: 400;
`;

const PlanDuration = styled.div`
  color: #44554c;
  font-size: 2rem;
  font-weight: 400;
`;

const PaymentRightBox = styled.div``;

const Price = styled.div`
  background: #ffffff;
  border-radius: 8px;
  color: #1c9d87;
  padding: 2rem;
  font-size: 2.6rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.4rem;
`;

const PriceTitle = styled.div`
  color: #44554c;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  font-family: "RubikMedium";
`;

const PaymentNote = styled.div`
  color: #44554c;
  font-size: 2rem;
  margin-top: 1.6rem;
  margin-bottom: 3rem;
  font-weight: 400;
`;

const CheckBoxes = styled.div`
  input {
    font-size: 2rem;
    background: #f5f5f5;
    border-radius: 4px;
    border: none;
    outline: none;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 0.8rem 1rem;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    &::-webkit-input-placeholder {
      color: #a5b5af;
    }
    &::-moz-placeholder {
      color: #a5b5af;
    }
    &:-ms-input-placeholder {
      color: #a5b5af;
    }
    &::-ms-input-placeholder {
      color: #a5b5af;
    }
    &::placeholder {
      color: #a5b5af;
    }
  }
`;

const FormElement = styled.div`
  flex: 1 1 calc(50% - 0.5rem);
  margin-bottom: 1rem;
`;

const FormElementLabel = styled.div`
  margin-bottom: 0.4rem;
  font-size: 1.8rem;
  font-weight: 400;

  display: block;
  position: relative;
  padding-left: 6rem;
  margin-bottom: 12px;
  cursor: pointer;
  word-break: break-word;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #44554c;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .checkmark {
    background-color: #f5f5f5;
  }

  input:checked ~ .checkmark {
    background-color: #1c9d87;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 7.5px;
    top: 3px;
    width: .7rem;
    height: 1.4rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const GreenText = styled.span`
  color: #2ecf96;
  margin-inline: 6px;
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 3rem;
  width: 3rem;
  background-color: #f5f5f5;
  border-radius: 5px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const Button = styled.button`
  background: transparent linear-gradient(103deg, #2ecf96 0%, #1c9d87 100%);
  border-radius: 8px;
  width: 124px;
  height: 39px;
  border: none;
  color: #ffffff;
  margin-inline: auto;
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  font-family: "RubikMedium";

  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
    cursor: pointer;
  }

  &:disabled {
    background: lightgray;
    color: black;
  }
`;

const ToggleSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2ecf96;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2ecf96;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2ecf96;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
const ValidationError = styled.div`
  color: red;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
`;
export {
  Modal,
  ModalHeader,
  ModalContent,
  ModalContainer,
  Close,
  Title,
  PaymentType,
  MonthlyPayment,
  OneTimePayment,
  PaymentBoxContainer,
  PaymentLeftBox,
  Logo,
  PaymentInfo,
  PlanTitle,
  CancelTitle,
  PlanDuration,
  PaymentRightBox,
  Price,
  PriceTitle,
  PaymentNote,
  CheckBoxes,
  FormElement,
  FormElementLabel,
  GreenText,
  CheckMark,
  Button,
  ToggleSwitch,
  ValidationError,
};
