import React from "react";
import { Link } from "react-router-dom";
import * as F from "./MaximumReimbursement.styled";
import * as G from "styles/General.Styles";
import Chip from "components/general/formComponents/chip/Chip";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";
// Images
import KrankenkasseFoodiary from "assets/images/App/KrankenkasseFoodiary.svg";

const MaximumReimbursement = () => {
  return (
    <F.CoachingGlanceContainer>
      <F.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 3rem 0" />
        <F.GlanceTitle className="ct-text-block font-bold color-dark text-left text-4xl">
          Maximale Kostenerstattung: Dein Kurs bis zu 100% von der Krankenkasse
          erstattet
        </F.GlanceTitle>
        <F.GlanceText className="ct-text-block color-paragraph text-base text-left">
          Geprüftes Expertenwissen, kostenfreie Handouts, individuelle
          Kursleiterunterstützung, Wissensfragen zur Überprüfung, flexible
          Lernmaterialien. Das alles erwartet dich in unserem Präventionskurs
          „Onlinekurs: Mit bewusster Ernährung zu mehr Wohlbefinden“. Das Beste?
          - Die Kostenrückerstattung von bis zu 100% durch deine Krankenkasse.
          Starte jetzt deine gesunde Ernährungsreise!
        </F.GlanceText>
        <F.GlanceTags>
          <img src={KrankenkasseFoodiary} alt="tag" />
        </F.GlanceTags>
      </F.CoachingGlanceLeft>
      <F.PlansBox className="singleBox">
        <F.OnePlan>
          <F.CoachingPlansBox className="plansBox">
            <F.PlanBox className="active">
              <F.PlanBoxHead>
                <F.TitleInfo className="active ct-text-block uppercase text-base font-normal">
                  PRÄVENTIONSKURS
                </F.TitleInfo>
                <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
                  149,00 €
                </F.Title>
                <F.SubTitle className="ct-text-block uppercase text-base font-normal">
                  Bis zu 100% Rückerstattung von deiner Krankenkasse
                </F.SubTitle>
              </F.PlanBoxHead>
              <F.PlanLowerBox>
                <G.PlanBoxIcons>
                  {[
                    "8 Wochen Wissenskurs",
                    "Geprüfter Kursleiter für Feedback",
                    "Wissensquiz zur Unterstützung",
                    "Umfangreiches Lernmaterial",
                  ].map((t) => (
                    <li>
                      <G.PlanBoxIconBox>
                        <img src={GreenTickWhiteBg} alt="Tick" />
                      </G.PlanBoxIconBox>
                      <G.PlanBoxInformation className="ct-text-block text-base">
                        {t}
                      </G.PlanBoxInformation>
                    </li>
                  ))}
                </G.PlanBoxIcons>
              </F.PlanLowerBox>

              <Link
                id="link_text-122-9"
                className="ct-link-text foodiary-green-button cursor-pointer w-full"
                to="/online-kurs/starten"
              >
                Kurs starten
              </Link>
            </F.PlanBox>
          </F.CoachingPlansBox>
        </F.OnePlan>
      </F.PlansBox>
    </F.CoachingGlanceContainer>
  );
};

export default MaximumReimbursement;
