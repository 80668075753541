import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// Components
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper";

import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import PreventionCourse from "components/PreventionCourse/PreventionCourse";
import ConsciousNutritionCourse from "components/ConsciousNutritionCourse/ConsciousNutritionCourse";
import InsuranceCompanies from "components/InsuranceCompanies/InsuranceCompanies";
import LearningMaterial from "components/LearningMaterial/LearningMaterial";
import PreventionCourseSteps from "components/PreventionCourseSteps/PreventionCourseSteps";
import VideoCourseAdvantages from "components/VideoCourseAdvantages/VideoCourseAdvantages";
import FreeCourseSteps from "components/FreeCourseSteps/FreeCourseSteps";
import MaximumReimbursement from "components/MaximumReimbursement/MaximumReimbursement";
import ReimbursementCalculation from "components/ReimbursementCalculation/ReimbursementCalculation";

// Redux
import * as CoachActions from "redux/coach/coach.actions";

// Styles
import * as G from "styles/General.Styles";
import * as C from "./OnlineKurs.styles";

const OnlineKurs = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("window.location.href", window.location.href)
    // console.log("https://felix.foodiary.app", "https://felix.foodiary.app".split('.app'))
    // console.log("https://felix.foodiary.app", "https://felix.foodiary.app/main-coaching".split('.app'))
    // console.log("https://felix.foodiary.app", "https://felix.foodiary.app/erstgespraech".split('.app'))
    // console.log("https://felix.foodiary.app", "https://felix.foodiary.app/coaching-pro".split('.app'))
    // console.log("https://felix.foodiary.app", "https://felix.foodiary.app/successful-booking-pro-r&narim0ken8".split('.app'))
    // const URL = "https://felix.foodiary.app/main-coaching".split('.app')[0] + ".app"
    // const URL = "https://felix-fci1.foodiary.app".split(".app")[0] + ".app";
    // const URL =  "https://victoria-fci78720.foodiary.app".split(".app")[0] + ".app";
    const URL = window.location.href.split(".app")[0] + ".app";
    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: URL },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            {/* <Navbar /> */}
            <StickyNavbar />
          </G.ContainerNavbar>
          <G.Container>
            <SectionWrapper margin="19.5rem 0 0" padding="0 0 35.5rem">
              <ConsciousNutritionCourse />
            </SectionWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>
      <G.Container>
        <SectionWrapper margin="-17rem 0 0">
          <InsuranceCompanies />
        </SectionWrapper>
        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <PreventionCourse />
        </SectionWrapper>
        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <LearningMaterial />
        </SectionWrapper>
        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <PreventionCourseSteps />
        </SectionWrapper>

        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <VideoCourseAdvantages />
        </SectionWrapper>
        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <FreeCourseSteps />
        </SectionWrapper>

        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <MaximumReimbursement />
        </SectionWrapper>

        <SectionWrapper margin="14.8rem 0 0" mobileMargin="6.8rem 0 0">
          <ReimbursementCalculation />
        </SectionWrapper>
      </G.Container>
      <SectionWrapper margin="7.8rem 0 0">
        <InitialConsultationBox />
      </SectionWrapper>
      <FooterNew />
    </>
  );
};

export default OnlineKurs;
