import styled from "styled-components"

export const AccordianContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})``

export const AccordianBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  border-bottom: 1px solid #dddddd;
  p {
    /* font-weight: 400;
    font-size: 1rem; */
    color: #44554c;
    margin: 0px 0 30px;
  }
  &:last-child {
    border: none;
  }
`

export const AccordianHeader = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  cursor: pointer;

  .IconPlus {
    width: 15px;
    height: 15px;
    background-image: "assets/images/myCoaching/Icon-plus.svg";
  }
  .IconClose {
    width: 20px;
    height: 20px;
    background-image: "assets/images/myCoaching/Icon-Close.svg";
    cursor: pointer;
  }
`

export const AccordianTitle = styled.h4`
  color: #142c1f;
  font-weight: 500;
  font-size: 2rem;
  width: calc(100% - 30px);

  ${({ active }) => active && `color: #2ecf96;`}
`
export const AccordianButton = styled.button.attrs({
  className: `${(props) => props.className}`,
})`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`
export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 32px;
  margin-bottom: 52px;
  img {
    width: 100%;
    height: auto;
    max-height: 360px;
    max-width: 300px;
    margin-inline: auto;
    display: block;
  }

  @media (min-width: 768px) {
    display: none;
  }
`
