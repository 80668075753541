import styled from "styled-components";
import { DashboardContainer } from "pages/dashboard/dashboard.styles";
import { LoginHeading, LoginSubHeading } from "pages/login/login.styles";

export const StartContainer = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  text-align: center;
`;

// What Awaits You Card styling
export const StartCardContainer = styled(DashboardContainer)`
  display: flex;
  //flex-direction: row;
  margin-top: 30px;
  width: 70%;
  max-width: 760px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    width: 97%;
    margin-left: 10px;
  }
`;

export const StartCardInnerContainer = styled.div`
  padding: 20px;
  width: 25%;
  @media (max-width: 600px) {
    width: 95%;
    padding: 35px 10px 10px 10px;
  }
`;

export const PerksContainer = styled.div`
  border-left: 1px solid rgba(28, 157, 135, 0.1);
  padding-left: 60px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding: 15px 20px 20px 20px;
    //padding-left: 20px;
  }
`;

export const StartHeading = styled(LoginHeading)``;

export const StartSubHeading = styled(LoginSubHeading)`
  text-align: ${(props) => (props.left ? "left" : "center")};
  @media (max-width: 600px) {
    padding-inline: 40px;
    font-weight: 600;
  }
`;

export const StartDetails = styled(LoginSubHeading)`
  font-weight: 10px;
  @media (max-width: 600px) {
    padding-inline: 40px;
    font-weight: 600;
  }
`;

export const StartOfferContainer = styled(DashboardContainer)`
  display: block;
  margin-top: 30px;
  width: 50%;
  max-width: 640px;
  background: white;
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    width: 91%;
    margin-left: 10px;
    padding: 8px;
    border-radius: 20px;
  }
`;

export const ToolsContainer = styled(DashboardContainer)`
  display: block;
  margin-top: 50px;
  width: 50%;
  max-width: 740px;
  padding: 10px;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    width: 91%;
    margin-left: 10px;
    padding: 8px;
    border-radius: 20px;
  }
`;

export const ToolText = styled(LoginSubHeading)`
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  color: #1c9d87;
  opacity: 1;
`;

export const StartOfferDiv = styled.div`
  text-align: left;
  width: 50%;
  @media (max-width: 600px) {
    width: 97%;
    margin-left: 10px;
  }
`;

export const StartOfferHeading = styled(LoginHeading)`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #44554c;
  opacity: 1;
`;

export const StartOfferRegular = styled(LoginHeading)`
  text-align: left;
  font-family: RubikRegular;
  font-size: 16px;
  letter-spacing: 0px;
  color: #44554c;
  opacity: 1;
`;

export const StartOfferDetails = styled(LoginSubHeading)`
  text-align: left;
  font-family: RubikRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #a5b5af;
  opacity: 1;
`;

export const StartCouponHeading = styled.div`
  color: #142c1f;
  font-size: 22px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 600;
`;

export const StartCardHeader = styled.div`
  background: ${(props) =>
      props.cardIndex === 0
        ? "#1C9D87"
        : props.cardIndex === 1
        ? "#142C1F"
        : "#A5B5AF"}
    0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    height: 30px;
  }
`;

export const ReviewContainer = styled(DashboardContainer)`
  display: block;
  margin-top: 20px;
  width: 50%;
  max-width: 340px;
  padding: 25px;
  background-color: white;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    width: 95%;
    max-width: 500px;
    margin-left: 10px;
    padding: 20px;
    paddingtop: 50px;
    border-radius: 20px;
  }
`;

export const DurationText = styled.div`
  text-align: center;
  font-family: RubikMedium, Georgia;
  font-size: 60px;
  letter-spacing: 0px;
  padding-top: 12px;
  color: #142c1f;
  opacity: 1;
  @media (max-width: 600px) {
    padding-top: 0px;
    font-size: 28px;
  }
`;

export const MonthText = styled.div`
  text-align: center;
  font-family: RubikMedium, Georgia;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0px;
  color: #a5b5af;
  opacity: 1;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const Price = styled.div`
  padding-top: 40px;
  text-align: center;
  font-family: RubikMedium, Georgia;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #1c9d87;
  @media (max-width: 600px) {
    font-size: 18px;
    padding-top: 10px;
  }
`;

export const PerMonth = styled.div`
  padding-top: 5px;
  text-align: center;
  font-family: RubikMedium, Georgia;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #44554c;
`;

export const PerksText = styled.div`
  padding-top: 5px;
  font-family: RubikMedium, Georgia;
  margin-left: 15px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #44554c;
`;

export const PerWeek = styled.div`
  padding-top: 9px;
  text-align: center;
  font-family: Rubik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #a5b5af;
  @media (max-width: 600px) {
    padding-top: 4px;
    color: #44554c;
  }
`;

export const HeaderText = styled.div`
  font: normal normal medium 18px/27px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const NextButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: #F5FAF9;
  z-index:99999; !important
  height:40px; 
  padding-top:10px;
  padding-bottom:20px;
  width: 100%;
  bottom: 1px;
`;

export const StartPageCouponCard = styled.div`
  width: 80%;
  height: 100%;
  min-height: 150px;
  background: white;
  margin: 15px;
  padding: 20px;
  margin-top: 40px;
`;

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100%;
`;

export const RowDiv = styled.div`
  display: flex;
  flexdirection: row;
`;

export const StartPageCouponCardDesktop = styled.div`
  display: flex;
  //flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  //max-width: 680px;
  height: 55px;
  background: white;
  margin: 15px;
  margin-left: 0px;
  position: relative;
`;

export const FAQHeading = styled.div`
  text-align: center;
  font: normal normal medium 22px Rubik;
  font-weight: 600;
  letter-spacing: 0px;
  color: #1c9d87;
  opacity: 1;
`;

export const Error = styled.div`
  margin-top: 0.75rem;
  color: #ef4444;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
`;

export const Success = styled.div`
  margin-top: 0.75rem;
  color: #22c55e;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
`;
