import React, { useState, useEffect } from "react";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import Button from "components/formComponents/button/Button";
import * as S from "./CheckoutForm.styles";

const CheckoutForm = (props) => {
  const { clientSecret, userStripePayment, subscription } = props
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const returnUrl = window.location.origin + "/nutrition/creation";

  const onSubmit = async (e) => {
    e.preventDefault();
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });
    setError("Karte abgelehnt");
  };

  function isDecimal(value) {
    // Convert the value to a string
    var valueStr = String(value);
  
    // Check if the string contains a decimal point
    if (valueStr.includes('.')) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (stripe) {
      let { amount } = userStripePayment;
      if (isDecimal(amount)) {
        amount = amount * 100;
      }
      const { currency } = subscription;
      const pr = stripe.paymentRequest({
        country: 'GR',
        currency: currency,
        total: {
          label: 'Demo total',
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentRequest) {

      paymentRequest.on('paymentmethod', async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        // ev.preventDefault();
        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          console.log('payment failed.');
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          // window.location.origin + "/nutrition/creation",
          ev.complete('success', clientSecret);
          window.location.href = returnUrl

          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded.
            }
          } else {
            // The payment has succeeded.
          }
        }
      });
    }
  }, [paymentRequest])

  return (
    <form onSubmit={onSubmit}>
      <div style={{ marginBottom: "20px" }}>
        {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
      </div>
      <PaymentElement options={{ loader: "always" }} />

      <Button
        type="submit"
        color="#fff"
        style={{
          width: 95 + "%",
          borderRadius: "6px",
          background:
            "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
          marginTop: "20px",
        }}
      >
        Zahlen
      </Button>
      {error && <S.Error>{error}</S.Error>}
    </form>
  );
};

export default CheckoutForm;
