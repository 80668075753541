//import { useNavigate } from "react-router-dom"
import {
  Card,
  CouchItem,
  CouchStats,
  CouchItemForMobile,
} from "./CouchCard.style"
import './CouchCard.scss'
//import DefaultUserProfile from "assets/outer-pages/png/default.png"

const CouchCard = ({ isMobile, coach, baseUrl }) => {
  //const navigate = useNavigate()

  const imgUrl = coach?.profilePic
    ? `${baseUrl}${coach?.profilePic}`
    : null

  return (
    <Card 
    onClick={() => 
      {
        if(coach.pp_first_meeting_calendly_url_profilpage !== null && isMobile) {
          window.location.href = coach.pp_first_meeting_calendly_url_profilpage
        }
      }
    }
    className="text-center" key={coach?.id}>
      <img src={imgUrl} alt="" />
      <h3>
        {coach?.prename} {coach?.surname}
      </h3> 
      <CouchItemForMobile>
        <p className="">{coach.subtitle}</p>
      </CouchItemForMobile>
      <CouchItem className="text-center">
        <small>Coach für</small>
        <p className="mb-0">{coach.subtitle}</p>
      </CouchItem>
      <CouchStats className="d-flex flex-wrap">
        <div className="d-flex flex-column">
          <small>Spezialgebiet</small>
          <p className="mb-0">{coach.step1}</p>
        </div>
        <div className="d-flex flex-column">
          <small>Coach seit</small>
          <p className="mb-0">{coach.step2}</p>
        </div>
        <div className="d-flex flex-column">
          <small>Ausbildungen</small>
          <p className="mb-0">{coach.step3}</p>
        </div>
        <div className="d-flex flex-column">
          <small>Praxiserfahrung</small>
          <p className="mb-0">{coach.step4}</p>
        </div>
      </CouchStats>
      {!isMobile &&
        <button
          className="btn-outline w-100 btn-medium"
          onClick={() => {
            if(coach.pp_first_meeting_calendly_url_profilpage !== null) {
              window.location.href = coach.pp_first_meeting_calendly_url_profilpage
            }
          }
          }
        >
          Erstgespräch buchen
        </button>
      }
    </Card>
  )
}

export default CouchCard
