import styled from "styled-components";
export const FooterWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background-color: #212121;
  padding: 80px 0 50px;
  @media (max-width: 1199px) {
    padding: 50px 0;
  }
`;

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 173px;
  height: 80px;
`;

export const AppLogo = styled.div.attrs((props) => ({
  src: props.src,
}))`
  margin-top: 30px;

  a {
    text-decoration: none;
    margin-right: 20px;
    img {
      width: 133px;
      height: 43px;
    }
  }
`;

export const DescriptionUnderLogos = styled.p`
  color: rgb(255, 255, 255, 0.7);
  /* margin-top: 30px;
  font-size: 1rem;
  font-weight: 300; */
`;

export const FooterContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 150px;
  @media (max-width: 1199px) {
    gap: 30px;
  }
  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

export const FooterContentLogoSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  a {
    width: fit-content;
  }
`;

export const FooterContentUlSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    gap: 0px;
  }
`;

export const FooterContentUlSectionInner = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  @media (max-width: 991.99px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.h4.attrs({
  className: `${(props) => props.className}`,
})`
  color: #ffffff;
  font-weight: 300;
  margin: 0 0 30px;
  /* font-size: 1.3rem;
  font-weight: 400; */

  @media (max-width: 991.99px) {
    margin: 0 0 20px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

export const FooterLinks = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  list-style: none;
  padding-left: 0;
  margin: 0 0 0;
  li {
    margin: 0 0 15px;
    a {
      color: #ffffff;
      /* font-size: 1rem; */
      /* font-weight: 300; */
      text-decoration: none;
      opacity: 0.7;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }

    @media (max-width: 991.99px) {
      a {
        font-size: 16px;
      }
    }
  }
`;

export const FooterInfo = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 85px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    padding-top: 30px;
  }
`;

export const FooterInfoLeft = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  font-weight: 300;

  @media (max-width: 991.99px) {
    order: 2;
  }

  /* @media (max-width: 600px) {
    p {
      font-size: 8px;
    }
  } */

  .ct-text-block {
    gap: 1rem;
    display: flex;
    align-items: center;

    a {
      color: #878787;
    }
  }
`;

export const FooterInfoRight = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0 0 0;
  padding-left: 0;
  gap: 60px;

  @media (max-width: 991.99px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0 30px 0px;
    order: 1;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 30px;
  }

  .links {
    display: flex;
    gap: 30px;
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.5);
      font-family: "RubikLight";
      font-size: 16px;
      font-weight: 300;
    }
  }

  li {
    margin-left: 50px;

    @media (max-width: 1199px) {
      margin-left: 0px;
      margin-right: 50px;
      &:last-child {
        margin: 0px;
      }
    }

    @media (max-width: 600px) {
      margin-right: 20px;
    }
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 19.8px;

  img {
    cursor: pointer;
    width: 3rem;
  }
`;
