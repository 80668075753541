import React from "react";
import * as F from "./dietMadeEasy.style";
import Chip from "components/general/formComponents/chip/Chip";

// Images
import FoodiaryErnahrungsberatung from "assets/images/pngs/Foodiary-Ernahrungsberatung.png";
// import Video from "assets/images/new/FoodiaryCoaching.mp4"
import ReactPlayer from "react-player";

const DietMadeEasy = ({ onPaymentModal }) => {
  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="GESAMTPAKET" margin="0 0 30px 0" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Vollumfängliches Coaching mit mir als dein persönlicher Coach
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Unzählige Standard Diät- und Ernährungsprogramme versprechen den
          schnellen Erfolg, doch häufig wird man hier nur enttäuscht.
          <br />
          <br />
          Ein professionell erstellter Ernährungsplan in Kombination mit einem
          persönlichen Ernährungsberater hält, was es verspricht! Eine
          unschlagbare Kombination aus Technik, Wissen und Erfahrung, welche
          dich unterstützt dein Ziel zu erreichen.
        </F.Description>
        {/* <F.Action>
          <button
            className="ct-link-text foodiary-green-button cursor-pointer"
            onClick={onPaymentModal}
          >
            Coaching PRO buchen
          </button>
        </F.Action> */}
      </F.ContentSection>
      <F.VisualContent>
        {/* <img src={FoodiaryErnahrungsberatung} alt="coach" /> */}
        <ReactPlayer
          url={
            "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/FoodiaryCoaching.mp4"
          }
          playsinline
          playing
          muted
          loop
        />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default DietMadeEasy;
