import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import coachReducer from "redux/coach/coach.reducers";
import authReducer from "redux/auth/auth.reducers";
import nutritionReducer from 'redux/nutrition/nutrition.reducers';
import supplementsReducer from 'redux/supplements/supplements.reducers';

/*************************************************/

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  nutrition: nutritionReducer,
  supplements: supplementsReducer,
  coach: coachReducer
});

/*************************************************/

export default persistReducer(persistConfig, rootReducer);

/*************************************************/
