import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import * as F from "./PreventionCourse.style";

import Chip from "components/general/formComponents/chip/Chip";

const PreventionCourse = () => {
  return (
    <F.Container>
      <F.VisualContent>
        <ReactPlayer
          url={
            "https://prevention.foodiary.app/wp-content/uploads/2023/05/Animated-Video-Course.mp4"
          }
          playsinline
          playing
          muted
          loop
        />
      </F.VisualContent>
      <F.ContentSection>
        <Chip text="12 WOCHEN KURS" />
        <F.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-8">
          Deine Ernährung im Fokus: 8-Wochen Präventionskurs
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Im Rahmen unseres 8-Wochen Präventionskurses bieten wir dir eine
          strukturierte Lernumgebung mit 8 Einheiten. Jede Einheit beinhaltet
          informative Videos, in denen das Grundlagenwissen zur gesunden
          Ernährung und Prävention vermittelt wird. Die Videos haben eine Dauer
          von 60 Minuten und sind praxisbezogen gestaltet, um dir das
          Selbstlernen zu erleichtern.
        </F.Description>
        <br />
        <Link
          id="link_text-122-9"
          className="ct-link-text foodiary-green-button cursor-pointer"
          to="/online-kurs/starten"
        >
          Kurs starten
        </Link>
      </F.ContentSection>
    </F.Container>
  );
};

export default PreventionCourse;
