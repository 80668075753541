import styled from "styled-components"

export const CoachingPlansBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 50px;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 767.99px) {
    padding: 25px;
  }
`

export const PlanBox = styled.div`
  padding: 40px;
  border-radius: 25px;
  @media (max-width: 991.99px) {
    padding: 25px;
  }

  &.active {
    background: #e7faf3;
    h5 {
      color: #2ecf96;
    }
  }
`

export const PlanBoxHead = styled.div`
  border-bottom: 1px solid #142c1f26;
  padding-bottom: 40px;
`

export const TitleInfo = styled.h5`
  /* font-size: 1.1rem; */
  margin-bottom: 20px;
  color: #7a8780;
  /* font-weight: 300; */
`
export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 5px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`
export const SubTitle = styled.h4`
  color: #a5b5af;
  /* font-size: 1rem;
  font-weight: 300; */
`

export const PlanLowerBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  height: 470px;
  overflow: hidden;
  overflow-y: auto;
  margin: 10px 0px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  @media (max-width: 575px) {
    height: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`
