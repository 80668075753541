import styled from "styled-components";

export const Wrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: relative;
  z-index: 50;
`;

export const Background = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color:black;
  opacity:0.25;
`;

export const DWrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
`;

export const DSWrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 1rem;
  text-align: center;
  position: relative;
`;

export const Container =  styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 1rem;
  text-align: center;
  position: relative;
`;

export const DPWrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position:relative;
  width: 100%;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
  background-color: white;
  max-width: 24rem;

`;

export const Title = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 1.875rem;
  line-height: 1.5rem;
  color: #111827;
`;

export const Icon = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: absolute;
  cursor: pointer;
  top: 0.5rem;;
  right: 1rem;
`;
