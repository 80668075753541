import styled from 'styled-components';
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';
import { LoginHeading, LoginSubHeading } from "pages/login/login.styles";

export const NutritionFormAdvantagesContainer = styled(RegistrationContainer)``;

export const NutritionFormAdvantagesHeading = styled(RegistrationHeading)``;

export const NutritionFormAdvantagesSubHeading = styled(RegistrationSubHeading)`
    @media (max-width: 600px) {
        color: #142C1F;
        text-align: center;
        font-weight: 600;
        padding: 0 30px 0 30px;
    }
`;

export const NutritionFormAdvantagesCardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CardHeading = styled.div`
    text-align: left;
    font-size: 18px;
    font-family: Rubik;
    letter-spacing: 0px;
    color: #142C1F;
    opacity: 1;
`;

export const CardSubHeading = styled.div`
    margin-top: 20px;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    font-family: Rubik;
    letter-spacing: 0px;
    color: #44554C;
    opacity: 0.7;
`;

export const NutritionFormAdvantagesCard = styled.div`
    width: 65%;
    margin: 0px auto;
    background: white;
    border-radius: 10px;
    margin-top: 20px;
    height: 90%;
    padding: 20px;
    min-height: 500px;
    display: flex;
    justify-content: center;
    
    @media (max-width: 600px) {
        width: 90%;
        height: 90%;
        padding: 2px;
    }
`;

export const NextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    width:100%;
    bottom: 25px;
`;

export const ButtonContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    width: auto;
    font-size: 16px;
    font-weight: normal;
`;

export const PaymentHeading = styled(LoginHeading)``;

export const PaymentSubHeading = styled(LoginSubHeading)`
    @media (max-width: 600px) {
        font-weight: 600;
    }
`;

export const NutritionFormAdvantagesCardInnerContainer = styled.div`
    margin: 17px 28px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

export const NutritionFormAdvantagesPointNumber = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #2ECF96;
    margin-right: 20px;
    line-height: 32px;
    color: #2ECF96;
    text-align: center;
`;

export const NutritionFormAdvantageData = styled.div`
    font-family: RubikRegular;
    font-size: 14px;
    color: #44554C;
    text-align: center;
    
    @media (max-width: 600px) {
        width: 90%;
    }
`;
export const FormGridContainer = styled.div`
    display: grid; 
    margin-top: 1.25rem; 
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
    gap: 1.25rem; 
`;

export const InputForm = styled.div`
    display: block; 
    position: relative; 
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; 
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
    color: #111827; 
    color: #6B7280; 
    width: 100%; 
    border-radius: 0.375rem; 
    border-width: 1px; 
    border-color: #D1D5DB; 
    appearance: none; 
`;



export const SpinerContainer = styled.div`
   display:flex;
   justify-content:center;
   margin-top:20px;
`