import styled from "styled-components";

export const CustomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: ${(props) => props.margin};
`;

export const CustomButton = styled.button`
  background-color: ${(props) => (props.outlined ? "white" : props.color)};
  background-image: ${(props) => {
    if (props.gradient && props.disabled)
      return "linear-gradient(60deg, rgba(46, 207, 150, .2), rgba(28, 157, 135, .4)) !important";
    else if (props.gradient) return props.gradient;
    else return "none";
  }};
  color: ${(props) => (props.outlined ? props.color : "white")};
  border: ${(props) => (props.outlined ? "2px solid " + props.color : "none")};
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
  min-height: 3.13rem;
  cursor: pointer;
  width: 100%;
  transition-duration: 0.3s;
  transition-property: transform;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;

  &:not(:disabled):hover {
    transform: translateY(-2px);
  }
`;
