import React, { useState, useEffect } from "react"
import axios from "axios"
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather"
import Topbar from 'components/topbar/Topbar';
import { useDispatch } from "react-redux";
import CouchCard from "components/CouchCard/CouchCard"
import CouchFilters from "components/CouchFilters/CouchFilters"
import './selectCoach.scss';

import * as AuthActions from "redux/auth/auth.actions";

const BASE_URL = "https://app.foodiary.app/api/"
const IMG_BASE_URL = "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/"

const initialUserData = {
    sex: 'male',
    aimID: 1,
    height: '0',
    weight: '0',
    nutritiontypeID: 2,
    birthday: '',
    email: "",
    prename: "",
    surname: "",
    grant_type: "password",
    isTraining: true,
    trainingdays: [],
    totalTrainingDays: 0,
    routines: {},
    dailyRoutineData: [],
    movementtypes: [],
    mobile: '',
    sleepValue: 60,
    lyingDownValue: 60,
    standingValue: 60,
    sittingValue: 60,
    walkingValue: 60,
    exercisingValue: 60,
    eatingHabit1: 'registration.eatingHabit.yoghurtWithFruit',
    eatingHabit2: 'registration.eatingHabit.apple',
    eatingHabit3: 'registration.eatingHabit.Avocado',
    eatingHabit4: 'registration.eatingHabit.Oatmeal',
    eatingHabit5: 'registration.eatingHabit.chilliConCarne',
};

function Home() {
    const [isMobile, setIsMobile] = useState(false)
    const [couchList, setCouchList] = useState(null)
    const [couchListActivePage, setCouchListActivePage] = useState("")
    const dispatch = useDispatch();
    const totalPages = Math.ceil(couchList?.total / couchList?.per_page) + 2
    dispatch(AuthActions.setRegistrationData(initialUserData));
    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 600) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        if (window.innerWidth < 600) {
            setIsMobile(true)
        }
        window.addEventListener("resize", handleResize)
        //getPromotedCouches()
        getCouches()
    }, [])

    const getCouches = async (pageUrl = "") => {
        const URL = pageUrl || `${BASE_URL}coach/list?page=1`
        //setIsCouchListFetching(true)
        try {
            const res = await axios.get(URL)
            // console.log(res?.data)
            setCouchList(res?.data?.coachList)
            //setIsCouchListFetching(false)
            // console.log("couchListActivePage", couchListActivePage)
            setCouchListActivePage(pageUrl)
        } catch (e) {
            //setIsCouchListFetching(false)
            console.log(e)
        }
    }

    const paginationItems = () => {
        let pages = Array.from({ length: totalPages }, (_, idx) => idx)
        return pages.map((page, i) => {
            if (i === 0 && couchList?.prev_page_url) {
                return (
                    <li className="page-item" key={page}>
                        <ArrowLeftCircle
                            strokeWidth={1}
                            onClick={() => {
                                getCouches(`${couchList?.prev_page_url}`)
                                setCouchListActivePage(couchList?.prev_page_url)
                            }}
                        />
                    </li>
                )
            }
            if (i === pages.length - 1 && couchList?.next_page_url) {
                return (
                    <li className="page-item" key={page}>
                        <ArrowRightCircle
                            strokeWidth={1}
                            onClick={() => {
                                getCouches(`${couchList?.next_page_url}`)
                                setCouchListActivePage(couchList?.next_page_url)
                            }}
                        />
                    </li>
                )
            }
            if (i !== 0 && i !== pages.length - 1) {
                return (
                    <li className="page-item" key={page}>
                        <span
                            className={
                                `${couchList?.path}?page=${page}` === couchListActivePage
                                    ? "page-link active-page"
                                    : "page-link"
                            }
                            onClick={() => {
                                getCouches(`${couchList?.path}?page=${page}`)
                                setCouchListActivePage(`${couchList?.path}?page=${page}`)
                            }}
                        >
                            {page}
                        </span>
                    </li>
                )
            }
        })
    }

    return (
        <>
            <div className="home-page">
                {!isMobile &&
                    <Topbar progress='100%' />
                }
                <div className="container">
                    <main>
                        <div>
                            <div className="heading">
                                Foodiary Coach
                            </div>
                            <div className="sub-heading">
                                Kostenloses Erstgespräch bei deinem Wunschcoach
                            </div>
                        </div>
                        <CouchFilters />
                        <section className="grid-view cards-view">
                            {couchList?.data.map((item) => (
                                <CouchCard isMobile={isMobile} coach={item} key={item?.id} baseUrl={IMG_BASE_URL} />
                            ))}
                        </section>
                        <div className="load-more-action text-center">
                            <nav className="pagination-nav">
                                <ul className="pagination">{couchList && paginationItems()}</ul>
                            </nav>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default Home
