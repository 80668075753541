import React, { useState } from "react";
import Chip from "components/general/formComponents/chip/Chip";
import Accordian from "components/Accordians/Accordian";
import * as C from "./coachingAccordian.styled";
// Images
import FoodiaryRezepte from "assets/images/pngs/Foodiary-Rezepte.png";
import FoodiaryRezeptkarte from "assets/images/pngs/Foodiary-Rezeptkarte.png";
import FoodiaryCoachCard from "assets/images/pngs/Foodiary-Coach-card.png";
import FoodiaryCoaching from "assets/images/pngs/Coaching-Academy.jpg";
import FoodiaryDashboard from "assets/images/pngs/Foodiary-Dashboard.png";
import FoodiaryKalorienverlauf from "assets/images/pngs/weekly-foodiary.jpg";

const Questions = [
  {
    id: 1,
    img: FoodiaryRezepte,
    title: "Persönlichen Ernährungsplan",
    info: "Als dein Foodiarv Coach ermittle ich in einem persönlichen Gespräch dein Ziel, deine tägliche Aktivität und erstelle deinen persönlichen Ernährungsplan.",
  },
  {
    id: 2,
    img: FoodiaryRezeptkarte,
    title: "Über 1700 Rezepte",
    info: `Über 1700 Rezepte werden dank künstlicher Intelligenz optimal auf deinen
    Ernährungsplan angepasst. Du entscheidest ganz nach deinen Wünschen, welches
    Rezept du zubereiten möchtest.`,
  },
  {
    id: 3,
    img: FoodiaryCoachCard,
    title: "Eigener Ernährungscoach",
    info: "Während deiner Ernährungsumstellung betreue ich dich als dein persönlicher Ernährungscoach, überwache deinen Fortschritt und helfe dir zum Erfolg.",
  },
  {
    id: 4,
    img: FoodiaryCoaching,
    title: "Coaching Academy",
    info: "Damit deine Ernährungsumstellung ein dauerhafter Erfolg bleibt, erhältst du von uns in der Foodiary Academy über 15 Lernvideos und E-Books.",
  },
  {
    id: 5,
    img: FoodiaryDashboard,
    title: "Moderne Ernährungsplaner-App",
    info: "Die tägliche Ernährungsplanung wird dir durch unsere Ernährungsplaner-App deutlich vereinfacht. Du wählst flexibel deine Rezepte und erreichst dein Tagesziel.",
  },
  {
    id: 6,
    img: FoodiaryKalorienverlauf,
    title: "Wöchentliche Erfolgsüberprüfung",
    info: "In einer wöchentlichen Erfolgskontrolle überprüfen wir deinen Fortschritt. Ich schaue mir diesen an und greife bei Auffälligkeiten sofort ein.",
  },
];

const CoachingAccordian = () => {
  const [image, setImage] = useState(FoodiaryRezepte);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const onToggle = (question) => {
    setImage(question?.img);
    setActiveQuestion(question);
  };

  return (
    <C.Wrap>
      <C.VisualContent>
        <img src={image} alt="accord" />
      </C.VisualContent>
      <C.TextContent>
        <Chip text="COACHING" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Bei meinem Coaching erhältst du:
        </C.Title>
        <Accordian
          data={Questions}
          activeQuestion={activeQuestion}
          onExpanded={(question) => onToggle(question)}
        />
      </C.TextContent>
    </C.Wrap>
  );
};

export default CoachingAccordian;
