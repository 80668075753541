import React from "react";
import { useHistory } from "react-router-dom";
import * as F from "./coachingSinglePlan.styled";
import * as G from "styles/General.Styles";
// Images
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";

const CoachingSinglePlan = () => {
  const history = useHistory();

  return (
    <F.CoachingPlansBox className="plansBox">
      <F.PlanBox className="active">
        <F.PlanBoxHead>
          <F.TitleInfo className="active ct-text-block uppercase text-base font-normal">
            Ernährungsplaner-App
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            28,99 €
          </F.Title>
          <F.SubTitle className="ct-text-block uppercase text-base font-normal">
            für 3 Monate
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                1700 leckere Rezepte
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Einkaufsliste
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Eigener Ernährungsplan
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Nährwertanalyse
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Academy
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Und vieles mehr
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <button
          className="ct-link-text foodiary-green-button cursor-pointer w-full"
          onClick={() => history.push("/app-erstellen")}
        >
          Ernährungsplan erstellen
        </button>
      </F.PlanBox>
    </F.CoachingPlansBox>
  );
};

export default CoachingSinglePlan;
