import styled from "styled-components";

export const Wrap = styled.div``;

export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 20px #02332a1a;
  border-radius: 30px;
  text-align: center;
  padding: 1.9rem;
  /* height: 18.1rem; */
  transition: all .3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  img {
    /* margin-bottom: 26px; */
  }
`;

export const CardTitle = styled.h6`
  /* font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 500; */
  color: #142c1f;
`;

export const CardDescription = styled.p`
  color: #44554C;
  /* font-size: 1rem;
  font-weight: 400; */
  margin: 1.2rem 0 0;
`;
