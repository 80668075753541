import styled from "styled-components"

export const Card = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: #f0f4f3;
  border-radius: 30px;
  position: relative;
  padding: 50px 30px 30px;
`

export const Title = styled.h3`
  color: #1c9d87;
  /* font-size: 1.25rem;
  font-weight: 500; */
`

export const Description = styled.p`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  margin: 30px 0 50px;
`

export const IconBox = styled.div`
  background: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 30px;
  bottom: 30px;
  
  img {
    width: 30px;
    height: 36px;
    object-fit: contain;
  }
`
