import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./macroNutrients.styles";
import TranslationMessage from "configs/translations/TranslationMessage";
import { useHistory } from "react-router-dom";

// Components
import Topbar from "components/topbar/Topbar";
import DonutChart from "components/donutChart/DonutChart";
import Loader from "components/loader/Loader";
import Button from "components/formComponents/button/Button";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

// Images
import LeftIcon from "assets/images/leftIcon.svg";

const MacroNutrients = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const donutChartData = useSelector(
    (state) => state.nutrition.macroNutrientsDonutChartData
  );

  useEffect(() => {
    document.body.classList.add("app-register");
    dispatch(
      NutritionActions.getMacroNutrientsDonutRequest({
        nutritiontypeID: registrationData.nutritiontypeID,
      })
    );
    return () => {
      document.body.classList.remove("app-register");
    };
  }, [dispatch, registrationData]);

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);
  });

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleNext = () => {
    history.push("/client-successful");
  };

  const handlePrev = () => {
    history.push("/nutrition/calories");
  };

  const values = donutChartData && [
    {
      label: "Kohlenhydrate",
      value: donutChartData.carbohydratesData,
    },
    {
      label: "Fett",
      value: donutChartData.fatData,
    },
    {
      label: "Eiweiß",
      value: donutChartData.proteinData,
    },
  ];

  const getCurrentNutritionType = () => {
    if (registrationData.nutritiontypeID === 1) return "Anabole Diät";
    else if (registrationData.nutritiontypeID === 2) return "Metabole Diät";
    else if (registrationData.nutritiontypeID === 3) return "High Carb";
    else if (registrationData.nutritiontypeID === 4) return "Low Carb";
  };

  return (
    <div>
      {!isMobile && (
        <Topbar
          prev="/nutrition/calories"
          next="/client-successful"
          progress="83%"
        />
      )}
      <S.NutritionFormMacroNutrientsContainer>
        {isMobile ? (
          <>
            <S.NutritionMacroNutrientsHeadingContainer>
              <S.NutritionMacroNutrientsImageContainer>
                <img
                  onClick={() => handlePrev()}
                  src={LeftIcon}
                  alt="Left Icon"
                />
              </S.NutritionMacroNutrientsImageContainer>
              <S.NutritionMacroNutrientsMobileHeading>
                <TranslationMessage id="nutrition.formMacroNutrients.mobileHeading" />
              </S.NutritionMacroNutrientsMobileHeading>
            </S.NutritionMacroNutrientsHeadingContainer>
          </>
        ) : (
          <>
            <S.NutritionFormMacroNutrientsHeading>
              <TranslationMessage id="nutrition.formMacroNutrients.heading" />
            </S.NutritionFormMacroNutrientsHeading>
            <S.NutritionFormMacroNutrientsSubHeading>
              <TranslationMessage id="nutrition.formMacroNutrients.subHeading" />
            </S.NutritionFormMacroNutrientsSubHeading>
          </>
        )}
        <S.NutritionFormMacroNutrientsMobileCard>
          {isMobile && (
            <S.NutritionFormMacroNutrientsCardHeading>
              <TranslationMessage id="nutrition.formMacroNutrients.cardHeading" />
            </S.NutritionFormMacroNutrientsCardHeading>
          )}
          <S.NutritionFormMacroNutrientsDescription>
            <TranslationMessage id="nutrition.formMacroNutrients.description.p1" />{" "}
            <br />
            <TranslationMessage id="nutrition.formMacroNutrients.description.p2.1" />
            {getCurrentNutritionType()}
            <TranslationMessage id="nutrition.formMacroNutrients.description.p2.2" />
          </S.NutritionFormMacroNutrientsDescription>
          <S.DonutContainer>
            {!loading && <DonutChart series={values} isMobile={isMobile} />}
            {<Loader loading={loading} top="100px" />}
          </S.DonutContainer>
        </S.NutritionFormMacroNutrientsMobileCard>
      </S.NutritionFormMacroNutrientsContainer>
      {isMobile && (
        <S.NextButtonContainer>
          <Button
            onClick={() => handleNext()}
            color="#fff"
            style={{
              width: 95 + "%",
              borderRadius: "6px",
              background:
                "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <TranslationMessage id="button.next" />
          </Button>
        </S.NextButtonContainer>
      )}
    </div>
  );
};

export default MacroNutrients;
