import styled from "styled-components";

export const Wrap = styled.div`
  background-color: #F6F6F6;
  border-radius: 5rem 5rem 0 0;
  padding: 7rem 1rem;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 685px;
  margin-inline: auto;
  text-align: center;
`;

export const Title = styled.h6`
  color: #142C1F;
  /* font-size: 2rem;
  line-height: 3rem;
  font-weight: 500; */
`;

export const Description = styled.p`
  color: #44554C;
  /* font-size: 1rem;
  margin: 3.1rem 0 3.8rem;
  font-weight: 400; */
`;
