import SupplementsActionTypes  from './supplements.types';

  // Get Supplements Data
  export const getSupplementsRequest = () => ({
    type: SupplementsActionTypes.GET_SUPPLEMENTS_REQUEST,
  });
  
  export const getSupplementsSuccess = response => ({
    type: SupplementsActionTypes.GET_SUPPLEMENTS_SUCCESS,
    payload: response
  });
  
  export const getSupplementsFailure = error => ({
    type: SupplementsActionTypes.GET_SUPPLEMENTS_FAILURE,
    payload: error
  });
  
  // Delete Mail Chimp User
  export const deleteMailChimpUserRequest = payload => ({
    type: SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_REQUEST,
    payload: payload
  });
  
  export const deleteMailChimpUserSuccess = response => ({
    type: SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_SUCCESS,
    payload: response
  });
  
  export const deleteMailChimpUserFailure = error => ({
    type: SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_FAILURE,
    payload: error
  });  
  
  // Add Pro Mail Chimp User Data
  export const addProMailChimpUserDataRequest = payload => ({
    type: SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST,
    payload: payload
  });
  
  export const addProMailChimpUserDataSuccess = response => ({
    type: SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_SUCCESS,
    payload: response
  });
  
  export const addProMailChimpUserDataFailure = error => ({
    type: SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_FAILURE,
    payload: error
  });

  // Add Basic Mail Chimp User Data
  export const addBasicMailChimpUserDataRequest = payload => ({
    type: SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST,
    payload: payload
  });
  
  export const addBasicMailChimpUserDataSuccess = response => ({
    type: SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_SUCCESS,
    payload: response
  });
  
  export const addBasicMailChimpUserDataFailure = error => ({
    type: SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_FAILURE,
    payload: error
  });

  // Update User Subscription
  export const updateUserSubscriptionRequest = payload => ({
    type: SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_REQUEST,
    payload: payload
  });
  
  export const updateUserSubscriptionSuccess = response => ({
    type: SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_SUCCESS,
    payload: response
  });
  
  export const updateUserSubscriptionFailure = error => ({
    type: SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_FAILURE,
    payload: error
  });

    // Initiate Stripe Payment
    export const initiateStripePaymentRequest = payload => ({
      type: SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_REQUEST,
      payload: payload
    });
    
    export const initiateStripePaymentSuccess = response => ({
      type: SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_SUCCESS,
      payload: response
    });
    
    export const initiateStripePaymentFailure = error => ({
      type: SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_FAILURE,
      payload: error
    });