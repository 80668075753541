import styled from "styled-components";

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 7.5rem; */
  /* max-width: 1270px; */
  /* max-width: 1366px; */
  /* max-width: 1466px; */
  background: transparent;
  z-index: 19;

  position: fixed;
  top: 1.5rem;
  /* padding: 1.79rem; */
  border-radius: 20px;
  width: 100%;
  /* width: calc(100% - 30px); */
  max-width: 158rem;
  
  box-sizing: border-box;
  transition: all ease-in-out 0.3s;

  @media (max-width: 1360px) {
    max-width: calc(100% - 30px);
    /* left: 15px; */
    margin-inline: auto;
  }

  ${({ isSticky }) =>
    isSticky &&
    `
    box-shadow: 0px 5px 10px #142C1F14;
    background: white;
    
  `}
`;

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  /* width: 8rem; */
  margin-left: -10px;

  @media (max-width: 600px) {
    /* width: 6rem; */
  }
`;
export const NavItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  margin-block: 0;

  li {
    a {
      padding: 1rem;
      color: #44554c;
      cursor: pointer;
      /* font-size: 1rem;
      font-weight: 400; */
      text-decoration: none;
      display: block;

      &:hover,
      &:focus,
      &:active {
        color: #142c1f;
      }

      &.active-nav-link::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 30px;
        border-bottom: 4px solid #2ecf96;
        border-radius: 4px;
        position: absolute;
        top: 100%;
        left: 50%;
        translate: -50% 0;
      }
    }
  }

  @media (max-width: 991.99px) {
    display: none;
  }
`;

export const MobileNavItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0;
  margin-block: 0;

  li {
    img {
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  }

  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 600px) {
    li {
      button {
        padding: 0.8rem 1rem;
      }
    }
  }
`;

export const MobileNavbar = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 22;
  border-radius: 0 0 20px 20px;
  padding: 0 15px;
  box-shadow: 0px 5px 10px #142c1f14;

  @media (max-width: 600px) {
    height: 100vh;
  }
`;

export const NavbarItemsWrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 600px) {
    height: calc(100vh - 200px);
  }
`;

export const MobileNavbarItems = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  list-style-type: none;
  padding: 0;

  li {
    a {
      padding: 0 0 16px 0;
      font-family: "RubikRegular";
      color: #142c1f;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
      display: block;

      &:hover,
      &:focus,
      &:active {
        color: #142c1f;
      }

      &.active-nav-link {
        color: #1c9d87;
      }
    }
  }
`;

export const Header = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 100%;
  margin: 16px 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileNavbarLogo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 8rem;
  margin-left: -10px;
`;

export const Close = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const Divider = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 100%;
  background: #dddddd;
  margin: 1rem 0 2rem;
  height: 1px;
`;

export const AppDownloadItem = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 16px;
  font-weight: 600;
  color: #1c9d87;
  margin-bottom: 16px;
  font-family: "RubikRegular";
  display: flex;
  align-items: center;
  gap: 16px;
  img {
    width: 16px;
    height: 22px;
  }
`;

export const LoginItem = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 16px;
  font-weight: 600;
  font-family: "RubikRegular";
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 16px;
    height: 16px;
  }
`;
