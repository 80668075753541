import styled from 'styled-components';
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';

import {
    ButtonContainer, NutritionFormAdvantagesMobileHeading,
    NutritionFormAdvantagesImageContainer, NutritionFormAdvantagesHeadingContainer
} from 'pages/formAdvantages/formAdvantages.styles';

export const NutritionFormMacroNutrientsContainer = styled(RegistrationContainer)``;

export const NutritionFormMacroNutrientsHeading = styled(RegistrationHeading)`
    font-family: RubikMedium;
`;

export const NutritionFormMacroNutrientsSubHeading = styled(RegistrationSubHeading)``;

export const NutritionMacroNutrientsImageContainer = styled(NutritionFormAdvantagesImageContainer)``;

export const NutritionMacroNutrientsHeadingContainer = styled(NutritionFormAdvantagesHeadingContainer)``;

export const NextButtonContainer = styled(ButtonContainer)``;

export const NutritionMacroNutrientsMobileHeading = styled(NutritionFormAdvantagesMobileHeading)``;

export const NutritionFormMacroNutrientsDescription = styled.div`
    margin: 20px auto 40px;
    font-family: RubikRegular;
    font-size: 16px;
    line-height: 25px;
    color: rgba(68, 68, 68, .6);
    @media (max-width: 600px) {
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        color: #44554C;
        padding: 0px 20px 0px 20px;
    }
`;

export const NutritionFormMacroNutrientsCardHeading = styled.div`
    font-size: 14px;
    text-align: left;
    color: #142C1F;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 20px;
`;

export const NutritionFormMacroNutrientsMobileCard = styled.div`
    @media (max-width: 600px) {
        width: 95%;
        height: 550px;
        background: white;
        margin: 10px;
        margin-top: 40px;
    }
`;

export const DonutContainer = styled.div`
    width: 225px;
    height: 225px;
    margin: 10px auto 40px;
`;
