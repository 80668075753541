
const AuthTypes = {
    SET_REGISTRATION_DATA: 'SET_REGISTRATION_DATA',
    SET_HEALTH_SCORE_DATA: 'SET_HEALTH_SCORE_DATA',

    DELETE_REGISTRATION_DATA: 'DELETE_REGISTRATION_DATA',

    LOADING_START: 'LOADING_START',
    LOADING_STOP: 'LOADING_STOP',

    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

    SIGN_IN_EXISTING_PLAN_REQUEST: 'SIGN_IN_EXISTING_PLAN_REQUEST',
    SIGN_IN_EXISTING_PLAN_SUCCESS: 'SIGN_IN_EXISTING_PLAN_SUCCESS',
    SIGN_IN_EXISTING_PLAN_FAILURE: 'SIGN_IN_EXISTING_PLAN_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    TRAINER_SIGN_UP_REQUEST: 'TRAINER_SIGN_UP_REQUEST',
    TRAINER_SIGN_UP_SUCCESS: 'TRAINER_SIGN_UP_SUCCESS',
    TRAINER_SIGN_UP_FAILURE: 'TRAINER_SIGN_UP_FAILURE',

    TRAINER_DETAILS_EDIT_REQUEST: 'TRAINER_DETAILS_EDIT_REQUEST',
    TRAINER_DETAILS_EDIT_SUCCESS: 'TRAINER_DETAILS_EDIT_SUCCESS',
    TRAINER_DETAILS_EDIT_FAILURE: 'TRAINER_DETAILS_EDIT_FAILURE',

    SIGN_OUT_REQUEST: 'SIGN_OUT_REQUEST',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

    REGISTRATION_PART1_REQUEST: 'REGISTRATION_PART1_REQUEST',
    REGISTRATION_PART1_SUCCESS: 'REGISTRATION_PART1_SUCCESS',
    REGISTRATION_PART1_FAILURE: 'REGISTRATION_PART1_FAILURE',

    GET_TRAINER_PROFILE_REQUEST: 'GET_TRAINER_PROFILE_REQUEST',
    GET_TRAINER_PROFILE_SUCCESS: 'GET_TRAINER_PROFILE_SUCCESS',
    GET_TRAINER_PROFILE_FAILURE: 'GET_TRAINER_PROFILE_FAILURE',
}

export default AuthTypes;
