import styled from "styled-components";

export const CoachingWithMeContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`;

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.8rem;
  line-height: 4.2rem;
  font-weight: 500; */

  span {
    font-family: "AdoreYou";
    font-weight: 400;
    font-size: 80px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const TitleMobile = styled.h3`
  color: #142c1f;
  /* font-size: 50px;
  line-height: 75px;
  font-weight: 600; */

  span {
    font-family: "AdoreYou";
    font-weight: 400;
    font-size: 80px;
  }

  @media (min-width: 600.99px) {
    display: none;
  }
`;

export const SubTitle = styled.p`
  color: #a5b5af;
  /* font-size: 1rem;
  font-weight: 400; */
  margin: 35px 0 30px 0;
`;

export const Description = styled.p`
  color: #44554c;
  /* font-weight: 400;
  font-size: 1rem; */
`;

export const VisualContent = styled.div`
  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-left: auto;
    display: block;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`;

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 8rem;
  position: absolute;
  left: 0;
  top: 60%;
  transform: translate(0, -50%);

  @media (max-width: 700px) {
    left: 50%;
    top: 30px;
    transform: translate(-50%, -15px);
  }
`;
