import React from 'react';
import { useSelector } from "react-redux"
import { getS3ImageUrl } from "configs/utils"

import * as F from './happyToStart.style';
// Images
import Coach from 'assets/images/placeholder.jpg';

const HappyToStart = () => {
  const coach = useSelector((state) => state.coach.coach)
  
  const imgUrl = coach?.cw_sb_picture_1
    ? getS3ImageUrl(coach?.cw_sb_picture_1)
    : Coach

  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <F.Title className='ct-text-block font-bold color-dark text-left text-5xl mt-8 mb-12'>
          Schön, dass wir <br /> starten!
        </F.Title>
        <F.SubTitle className='ct-text-block color-paragraph text-base text-left'>
          Wir freuen uns, dass du dich dafür entschieden hast deine Ernährungsumstellung mit uns zu beginnen. In den nächsten Wochen werden wir gemeinsam den Weg zu einer gesunden und zielorientierten Ernährung bestreiten.
        </F.SubTitle>
        {/* <F.Description>
          Wir freuen uns, dass du dich dafür entschieden hast deine Ernährungsumstellung mit uns zu beginnen. In den nächsten Wochen werden wir gemeinsam den Weg zu einer gesunden und zielorientierten Ernährung bestreiten.
        </F.Description> */}
      </F.ContentSection>
      <F.VisualContent>
        <img src={imgUrl} alt="coach" />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  )
}

export default HappyToStart