import React from "react";
import Dialog from "../Dialog";
import * as S from "./index.style";

const index = ({ open, close }) => {
  return (
    <Dialog open={open} close={close}>
      <S.Wrapper className="shadow-md">
        <S.IconWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "45px", height: "45px" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </S.IconWrapper>
        <S.Title>Something goes wrong</S.Title>
        <S.Message>Something goes wrong try again</S.Message>
      </S.Wrapper>
    </Dialog>
  );
};

export default index;
