import React from 'react';
import * as S from "./input.styles";

// Images
import InputWarning from 'assets/images/inputWarning.svg';
import InputSuccess from 'assets/images/inputSuccess.svg';

const Input = (props) => {
    return (
        <S.InputContainer>
            <S.CustomInput {...props} />
            {props.warning && <S.InputWarningImage src={props.value === 24 ? InputSuccess : InputWarning} alt="Input Status" />}
        </S.InputContainer>
    )
}

export default Input
