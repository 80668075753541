import AuthActionTypes from 'redux/auth/auth.types';


// Registration Data
export const setRegistrationData = data => ({
  type: AuthActionTypes.SET_REGISTRATION_DATA,
  payload: data
})

export const deleteRegistrationData = data => ({
  type: AuthActionTypes.DELETE_REGISTRATION_DATA,
  payload: data
})

// Health Score Data
export const setHealthScoreData = data => ({
  type: AuthActionTypes.SET_HEALTH_SCORE_DATA,
  payload: data
})

// Loading
export const loadingStart = () => ({
  type: AuthActionTypes.LOADING_START,
});

export const loadingStop = () => ({
  type: AuthActionTypes.LOADING_STOP,
});

// Sign In
export const signInRequest = payload => ({
  type: AuthActionTypes.SIGN_IN_REQUEST,
  payload: payload
});

export const signInSuccess = user => ({
  type: AuthActionTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const signInFailure = error => ({
  type: AuthActionTypes.SIGN_IN_FAILURE,
  payload: error
});

// Sign Existing Plan
export const signInExistingPlanRequest = payload => ({
  type: AuthActionTypes.SIGN_IN_EXISTING_PLAN_REQUEST,
  payload: payload
});

export const signInExistingPlanSuccess = response => ({
  type: AuthActionTypes.SIGN_IN_EXISTING_PLAN_SUCCESS,
  payload: response
});

export const signInExistingPlanFailure = error => ({
  type: AuthActionTypes.SIGN_IN_EXISTING_PLAN_FAILURE,
  payload: error
});

// Change Password
export const changePasswordRequest = payload => ({
  type: AuthActionTypes.CHANGE_PASSWORD_REQUEST,
  payload: payload
});

export const changePasswordSuccess = user => ({
  type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
  payload: user
});

export const changePasswordFailure = error => ({
  type: AuthActionTypes.CHANGE_PASSWORD_FAILURE,
  payload: error
});

// Trainer Sign Up
export const trainerSignUpRequest = payload => ({
  type: AuthActionTypes.TRAINER_SIGN_UP_REQUEST,
  payload: payload
});

export const trainerSignUpSuccess = token => ({
  type: AuthActionTypes.TRAINER_SIGN_UP_SUCCESS,
  payload: token
});

export const trainerSignUpFailure = error => ({
  type: AuthActionTypes.TRAINER_SIGN_UP_FAILURE,
  payload: error
});

// Trainer Profile Edit
export const trainerProfileEditRequest = payload => ({
  type: AuthActionTypes.TRAINER_DETAILS_EDIT_REQUEST,
  payload: payload
});

export const trainerProfileEditSuccess = user => ({
  type: AuthActionTypes.TRAINER_DETAILS_EDIT_SUCCESS,
  payload: user
});

export const trainerProfileEditFailure = error => ({
  type: AuthActionTypes.TRAINER_DETAILS_EDIT_FAILURE,
  payload: error
});

// Sign Out
export const signOutRequest = () => ({
  type: AuthActionTypes.SIGN_OUT_REQUEST
});

export const signOutSuccess = () => {
  return ({
    type: AuthActionTypes.SIGN_OUT_SUCCESS
  })
};

export const signOutFailure = error => ({
  type: AuthActionTypes.SIGN_OUT_FAILURE,
  payload: error
});

// Get Trainers Details
export const getTrainerProfileRequest = payload => ({
  type: AuthActionTypes.GET_TRAINER_PROFILE_REQUEST,
  payload: payload
});

export const getTrainerProfileSuccess = payload => ({
  type: AuthActionTypes.GET_TRAINER_PROFILE_SUCCESS,
  payload: payload
});

export const getTrainerProfileFailure = error => ({
  type: AuthActionTypes.GET_TRAINER_PROFILE_FAILURE,
  payload: error
});

// Registration Part 1
export const registration1Request = payload => ({
  type: AuthActionTypes.REGISTRATION_PART1_REQUEST,
  payload: payload
});

export const registration1Success = token => {
  return ({
    type: AuthActionTypes.REGISTRATION_PART1_SUCCESS,
    payload: token
  })
};

export const registration1Failure = error => ({
  type: AuthActionTypes.REGISTRATION_PART1_FAILURE,
  payload: error
});
