import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./nutritionForm.styles";
import TranslationMessage from "configs/translations/TranslationMessage";

// Images
import LowCarbImage from "assets/images/lowCarb.svg";
import LowCarbNotSelectedImage from "assets/images/lowCarbNotSelected.svg";
import LeftIcon from "assets/images/leftIcon.svg";

// Components
import Topbar from "components/topbar/Topbar";
import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import Loader from "components/loader/Loader";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as AuthActions from "redux/auth/auth.actions";

const NutritionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const userData = useSelector((state) => state.nutrition.userData);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const activeNutritionType = useSelector(
    (state) => state.nutrition.activeNutritionType
  );
  const allNutritionTypes = useSelector(
    (state) => state.nutrition.nutritionTypes
  );
  const [selectedNutritionForm, setSelectedNutritionForm] = useState({});
  const [editNutritionFormModal, setEditNutritionFormModal] = useState(false);

  const half = Math.ceil(allNutritionTypes?.length / 2);

  const firstHalf = allNutritionTypes?.slice(0, half);
  const secondHalf = allNutritionTypes?.slice(half);
  const nextLink = "/nutrition/formAdvantages";
  useEffect(() => {
    dispatch(NutritionActions.getUserDataRequest());
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.add("app-register");
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);
    getCurrentNutritionType(registrationData.nutritiontypeID);
    getAllNutritionTypes();
    return () => {
      document.body.classList.remove("app-register");
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handlePrev = () => {
    history.push("/payment");
  };

  const getCurrentNutritionType = (id) =>
    dispatch(
      NutritionActions.getNutritionTypeRequest({
        nutritiontypeID: id,
        aimID: registrationData.aimID,
        sex: registrationData.sex,
      })
    );
  const getAllNutritionTypes = () =>
    dispatch(
      NutritionActions.getAllNutritionTypesRequest({
        aimID: registrationData.aimID,
        sex: registrationData.sex,
      })
    );

  const handleChangeNutritionTypePopup = () => {
    const newNutritionType = allNutritionTypes.filter(
      (data) => data.de_name === activeNutritionType.de_name
    )[0];
    setSelectedNutritionForm(newNutritionType);
    setEditNutritionFormModal(true);
  };

  const handleNutritionTypeChange = () => {
    dispatch(
      NutritionActions.updateNutritionTypeRequest({
        nutritiontypeID: selectedNutritionForm.nutritiontypeID,
        aimID: registrationData.aimID,
        sex: registrationData.sex,
        nutritionconceptID: userData.fitnessdata.nutritionconceptID,
      })
    );
    getCurrentNutritionType(selectedNutritionForm.nutritiontypeID);
    dispatch(
      AuthActions.setRegistrationData({
        ...registrationData,
        nutritiontypeID: selectedNutritionForm.nutritiontypeID,
        userID: userData.userID,
      })
    );
    setEditNutritionFormModal(false);
  };

  const onNext = () => {
    dispatch(
      AuthActions.setRegistrationData({
        ...registrationData,
        userID: userData.userID,
        nutritionconceptID: userData.fitnessdata.nutritionconceptID,
      })
    );
    history.push(nextLink);
  };

  return (
    <div>
      {!isMobile && <Topbar next={nextLink} progress="17%" onNext={onNext} />}
      {<Loader loading={loading} top="35vh" />}
      {!loading && (
        <S.NutritionFormContainer>
          {isMobile ? (
            <>
              <S.NutritionFormHeadingContainer>
                <S.NutritionFormImageContainer>
                  <img
                    onClick={() => handlePrev()}
                    src={LeftIcon}
                    alt="Left Icon"
                  />
                </S.NutritionFormImageContainer>
                <S.NutritionFormMobileHeading>
                  <TranslationMessage id="nutrition.form.carbHeading" />
                </S.NutritionFormMobileHeading>
              </S.NutritionFormHeadingContainer>
            </>
          ) : (
            <>
              <S.NutritionFormHeading>
                <TranslationMessage id="nutrition.form.heading" />
              </S.NutritionFormHeading>
              <S.NutritionFormSubHeading>
                <TranslationMessage id="nutrition.form.subHeading" />
              </S.NutritionFormSubHeading>
            </>
          )}
          <S.NutritionFormMobileDescriptionCard>
            <S.NutritionFormCardContainer justify="center">
              <S.NutritionFormCard backgroundColor="white">
                <S.NutritionCardImage
                  src={LowCarbImage}
                  alt={activeNutritionType && activeNutritionType.de_name}
                />
                <S.NutritionFormMobileTitle>
                  {activeNutritionType && activeNutritionType.de_name}
                </S.NutritionFormMobileTitle>
              </S.NutritionFormCard>
            </S.NutritionFormCardContainer>
            <S.NutritionFormDescriptionContainer>
              <S.NutritionFormDescription
                dangerouslySetInnerHTML={{
                  __html: activeNutritionType?.["01_text"].replace(
                    /\n/g,
                    "<br />"
                  ),
                }}
              />
            </S.NutritionFormDescriptionContainer>
            {isMobile && (
              <Button
                color="#1C9D87"
                style={{
                  height: 28,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 4,
                  fontWeight: "normal",
                  border: "1px solid",
                  marginBottom: 20,
                }}
                outlined
                onClick={handleChangeNutritionTypePopup}
              >
                <TranslationMessage id="button.editNutritionForm" />
              </Button>
            )}
          </S.NutritionFormMobileDescriptionCard>
          {!isMobile && (
            <Button
              color="#1C9D87"
              outlined
              onClick={handleChangeNutritionTypePopup}
            >
              <TranslationMessage id="button.editNutritionForm" />
            </Button>
          )}
        </S.NutritionFormContainer>
      )}
      <Modal
        show={editNutritionFormModal}
        setShow={setEditNutritionFormModal}
        heading={
          <TranslationMessage id="nutrition.form.editNutritionFormHeading" />
        }
        width={isMobile ? "100%" : "848px"}
        height={isMobile ? "100%" : "auto"}
        style={{ overflowY: "scroll" }}
      >
        {<Loader loading={loading} top="200px" />}
        {!loading && (
          <>
            <S.NutritionFormCardContainerModal>
              {firstHalf &&
                firstHalf.map(function (value, index) {
                  return (
                    <S.NutritionFormCardInModal
                      key={value.nutritiontypeID}
                      onClick={() => setSelectedNutritionForm(value)}
                      selected={
                        value.nutritiontypeID ===
                        selectedNutritionForm.nutritiontypeID
                      }
                    >
                      <S.NutritionCardImage
                        src={
                          value.nutritiontypeID ===
                          selectedNutritionForm.nutritiontypeID
                            ? LowCarbImage
                            : LowCarbNotSelectedImage
                        }
                        alt={value.nutritiontypeID}
                      />
                      {value["de_name"]}
                    </S.NutritionFormCardInModal>
                  );
                })}
            </S.NutritionFormCardContainerModal>
            <S.NutritionFormCardContainerModal>
              {secondHalf &&
                secondHalf.map(function (value, index) {
                  return (
                    <S.NutritionFormCardInModal
                      key={value.nutritiontypeID}
                      onClick={() => setSelectedNutritionForm(value)}
                      selected={
                        value.nutritiontypeID ===
                        selectedNutritionForm.nutritiontypeID
                      }
                    >
                      <S.NutritionCardImage
                        src={
                          value.nutritiontypeID ===
                          selectedNutritionForm.nutritiontypeID
                            ? LowCarbImage
                            : LowCarbNotSelectedImage
                        }
                        alt={value.nutritiontypeID}
                      />
                      {value["de_name"]}
                    </S.NutritionFormCardInModal>
                  );
                })}
            </S.NutritionFormCardContainerModal>
            <S.NutritionFormDescriptionInModal
              dangerouslySetInnerHTML={{
                __html: selectedNutritionForm["01_text"]
                  ? selectedNutritionForm["01_text"].replace(/\n/g, "<br />")
                  : "",
              }}
            />
            <Button
              color="rgba(46, 207, 150, .2)"
              gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
              margin="30px auto 0px"
              style={{ fontSize: "16px", height: "43px", marginTop: -10 }}
              onClick={handleNutritionTypeChange}
            >
              <TranslationMessage id="button.saveChanges" />
            </Button>
          </>
        )}
      </Modal>
      {isMobile && (
        <S.NextButtonContainer>
          <Button
            onClick={() => onNext()}
            color="#fff"
            style={{
              width: 95 + "%",
              borderRadius: "6px",
              background:
                "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <TranslationMessage id="button.next" />
          </Button>
        </S.NextButtonContainer>
      )}
    </div>
  );
};

export default NutritionForm;
