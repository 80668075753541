import Close from "assets/images/navbar/Foodiary-modal-close-icon.svg";
import { useState, useEffect } from "react";
import * as M from "./HealthStatusModal.style";

const HealthStatusModal = ({ isOpen, onClose }) => {
  const [IsAccordionOpen, setIsAccordionOpen] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [coachingPlan, setCoachingPlan] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    if (!isOpen) document.body.style.overflow = "unset";
  }, [isOpen]);

  const onSubmit = async () => {};

  return (
    <>
      {isOpen && (
        <M.ModalContainer
          id="myModal"
          className="modal-container health-status-modal rounded-xl"
        >
          <M.Modal className="modal">
            {/* <M.ModalHeader className="modal-header">
              <M.Close className="close" onClick={onClose}>
                <img src={Close} alt="close" />
              </M.Close>
            </M.ModalHeader> */}
            <M.ModalContent className="modal-content">
              <div
                id="div_block-4-24"
                className="ct-div-block bg-white flex centered rounded-xl"
              >
                <div
                  id="headline-7-24"
                  className="ct-headline py-3 px-6 uppercase text-base rounded-full font-medium color-secondary bg-alt"
                >
                  12 WOCHEN KURS
                </div>
                <div
                  id="text_block-11-24"
                  className="ct-text-block font-bold color-dark text-left text-5xl mt-8"
                >
                  Gesundheitsstatus
                </div>
                <div id="div_block-13-24" className="ct-div-block mt-4 p-10">
                  <div
                    id="text_block-12-24"
                    className="ct-text-block foodiary-list-item text-base color-dark font-medium text-left"
                  >
                    Hast du gesundheitliche Einschränkungen?
                  </div>
                  <div
                    id="text_block-16-24"
                    className="ct-text-block text-base text-left color-paragraph"
                  >
                    Die Übungen und Maßnahmen in diesem Präventionskurs sind
                    nicht für jeden geeignet.
                    <br />
                    Du leidest unter chronischen oder akuten Erkrankungen oder
                    bist schwanger?
                    <br />
                    Du bist dir nicht sicher, ob du aufgrund deines
                    Gesundheitszustands am Kurs teilnehmen solltest?
                  </div>
                  <div
                    id="div_block-17-24"
                    className="ct-div-block mt-4 flex align-items-center flex-row"
                  >
                    <div id="code_block-18-24" className="ct-code-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.688"
                        height="19.007"
                        viewBox="0 0 21.688 19.007"
                      >
                        <g
                          id="Icon_feather-alert-triangle"
                          data-name="Icon feather-alert-triangle"
                          transform="translate(-1.301 -3.346)"
                        >
                          <path
                            id="Pfad_47037"
                            data-name="Pfad 47037"
                            d="M10.537,5.25,2.58,18.534a1.879,1.879,0,0,0,1.606,2.818H20.1a1.879,1.879,0,0,0,1.606-2.818L13.75,5.25a1.879,1.879,0,0,0-3.213,0Z"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#c74343"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          ></path>
                          <path
                            id="Pfad_47038"
                            data-name="Pfad 47038"
                            d="M18,13.5v3.758"
                            transform="translate(-5.856 -3.421)"
                            fill="none"
                            stroke="#c74343"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          ></path>
                          <path
                            id="Pfad_47039"
                            data-name="Pfad 47039"
                            d="M18,25.5h0"
                            transform="translate(-5.856 -7.905)"
                            fill="none"
                            stroke="#c74343"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div
                      id="text_block-19-24"
                      className="ct-text-block text-base text-left ml-3"
                    >
                      Dann sprich bitte vor Beginn des Kurses mit deinem Arzt /
                      deiner Ärztin!
                    </div>
                  </div>
                  <div
                    id="text_block-20-24"
                    className="ct-text-block foodiary-list-item text-base color-dark font-medium text-left mt-10"
                  >
                    Kontraindikationen
                  </div>
                  <div
                    id="text_block-21-24"
                    className="ct-text-block text-base text-left color-paragraph"
                  >
                    Bei folgenden Erkrankungen raten wir vehement von einer
                    Kursteilnahme ab:
                    <br />
                    <b>
                      <u>
                        <i>
                          Die Teilnahme am Online Kurs ist für Personen mit
                          psychischen (Ess-) Störungen nicht gestattet.
                        </i>
                      </u>
                    </b>
                  </div>
                  <div id="code_block-33-24" className="ct-code-block mt-5">
                    <div
                      className={
                        IsAccordionOpen
                          ? "dropdown-btn-modal text-left open"
                          : "dropdown-btn-modal text-left"
                      }
                      id="dropdown-btn-modal"
                      onClick={() => setIsAccordionOpen(!IsAccordionOpen)}
                    >
                      Alle Kontraindikationen anzeigen
                    </div>
                    {IsAccordionOpen && (
                      <div
                        className="kontraindikationen flex flex-column"
                        id="kontraindikationen"
                      >
                        <p>Persönlichkeitsstörungen</p>
                        <p>Psychische Erkrankungen</p>
                        <p>Epilepsie</p>
                        <p>Verletzungen am Herzen</p>
                        <p>Bösartige Tumore</p>
                        <p>Schweres Wirbelsäulentrauma</p>
                        <p>Kürzliches Schädel-Hirn-Trauma</p>
                        <p>Herzinfarkt</p>
                        <p>
                          Behandlungsbedürftige Erkrankungen des
                          muskuloskelettalen Bewegungsapparates (z. B. Krebs,
                          Knochenbrüche, Infektionen, Cauda-equina-Syndrom)
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    id="text_block-24-24"
                    className="ct-text-block text-left mt-12 text-base font-bold color-paragraph-alt"
                  >
                    Die Übungen des Präventionskurses sind kein Ersatz für eine
                    ärztliche Untersuchung oder Behandlung.
                    <br />
                    Dieses Online-Programm wird auf eigene Gefahr genutzt.
                  </div>
                </div>
                <div id="div_block-26-24" className="ct-div-block actions">
                  <div id="div_block-27-24" className="ct-div-block">
                    <button
                      id="link_text-31-24"
                      className="ct-link-text foodiary-outline-button w-full"
                      onClick={onClose}
                    >
                      Ich habe eine dieser Erkrankungen
                    </button>
                  </div>
                  <div id="div_block-28-24" className="ct-div-block">
                    <button
                      id="link_text-29-24"
                      className="ct-link-text foodiary-green-button w-full"
                      onClick={onClose}
                    >
                      Ich bin gesund
                    </button>
                  </div>
                </div>
              </div>
            </M.ModalContent>
          </M.Modal>
        </M.ModalContainer>
      )}
    </>
  );
};

export default HealthStatusModal;
