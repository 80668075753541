import styled from "styled-components"

export const ScheduleInitialConsultationContainer = styled.div``

export const Title = styled.h3`
`
export const WidgetWrapper = styled.div`

  .calendly-inline-widget {
    min-height: 730px;
  }

`