import styled from 'styled-components';
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';

export const NutritionFormAdvantagesContainer = styled(RegistrationContainer)``;

export const NutritionFormAdvantagesHeading = styled(RegistrationHeading)``;

export const NutritionFormAdvantagesSubHeading = styled(RegistrationSubHeading)`
    @media (max-width: 600px) {
        color: #142C1F;
        text-align: center;
        font-weight: 600;
        padding: 0 30px 0 30px;
    }
`;

export const NutritionFormAdvantagesCardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CardHeading = styled.div`
    text-align: left;
    font-size: 18px;
    font-family: Rubik;
    letter-spacing: 0px;
    color: #142C1F;
    opacity: 1;
`;

export const CardSubHeading = styled.div`
    margin-top: 20px;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    font-family: Rubik;
    letter-spacing: 0px;
    color: #44554C;
    opacity: 0.7;
`;

export const NutritionFormAdvantagesCard = styled.div`
    width: 455px;
    margin: 0px auto;
    background: white;
    border-radius: 10px;
    margin-top: 20px;
    
    @media (max-width: 600px) {
        width: 90%;
        min-height: 200px;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    width: auto;
    font-size: 16px;
    font-weight: normal;
`;

export const NutritionFormAdvantagesCardInnerContainer = styled.div`
    margin: 17px 28px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
`;

export const NutritionFormAdvantagesPointNumber = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #2ECF96;
    margin-right: 20px;
    line-height: 32px;
    color: #2ECF96;
    text-align: center;
`;

export const NutritionFormAdvantageData = styled.div`
    font-family: RubikRegular;
    font-size: 16px;
    color: #44554C;
    width: 343px;
    text-align: left;
    
    @media (max-width: 600px) {
        width: 90%;
    }
`;