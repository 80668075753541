import styled from "styled-components";

export const ProgressBarContainer = styled.div`
    height: 35px;
    width: 100%;
    background-color: rgb(0, 0, 0, .15);
    position: absolute;
    top: 0;
    @media (max-width: 600px) {
        width: 80%;
        height: 10px;
        position: relative;
        border-radius: 5px;
        background-color: #DCE1E0;
    }
`;

export const ProgressBarFiller = styled.div`
    height: 100%;
    width: ${props => props.width ? props.width : '0%'};
    background-color: rgb(0, 0, 0, .15);
    border-radius: inherit;
    text-align: right;
    @media (max-width: 600px) {
        margin-top: -13px;
        background-color: #2ECF96;
        z-index: 100;
    }
`;

export const ProgressBarLabel = styled.span`
    padding: 0 15px;
    color: #FFF;
    line-height: 35px;
    font-family: RubikRegular;
    font-size: 15px;
`;

export const ProgressBarDotsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3px;
    padding-inline: 42px;
`;


export const ProgressBarDot = styled.span`
    width: 4px;
    height: 4px;
    background-color: #A5B5AF;
    border-radius: 50%;
`;


export const ProgressBarSelectedDot = styled.span`
    width: 12px;
    height: 12px;
    margin-top: -6px;
    z-index: 100;
    border-radius: 50%;
    border: 2px solid white;
    background-color: #1C9D87;
`;