import styled from "styled-components";

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`;

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.2rem;
  font-weight: 500; */
`;

export const Description = styled.p`
  color: #44554c;
  /* font-weight: 400;
  font-size: 1rem;
  margin: 50px 0 60px; */
`;

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

export const VisualContent = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  > div {
    width: 100% !important;
    max-width: 600px;
    height: auto !important;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
  }
`;
