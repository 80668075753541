import styled from "styled-components";

export const RightCoachContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: #ffffff;
  box-shadow: 0px 50px 99px #02332a1a;
  border-radius: 30px;
  padding: 80px 50px;

  @media (max-width: 600px) {
    padding: 50px 20px;
  }
`;

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  text-align: center;
  margin-bottom: 35px;
  @media (max-width: 600px) {
    /* font-size: 1.8rem; */
  }
`;
export const TwoColums = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  list-style: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  padding-left: 0px;
  @media (max-width: 991px) {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    span {
      color: #142c1f;
      /* font-size: 1rem !important; */
      padding-top: 10px;
      &.WidthChanges {
        width: 53px;
        height: 53px;
        padding-top: 0px;
      }
    }
  }
`;
