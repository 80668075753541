import SupplementsActionTypes from './supplements.types';

/*************************************************/

const INITIAL_STATE  = {
    supplements: [],
}

/*************************************************/

const supplementsReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case SupplementsActionTypes.GET_SUPPLEMENTS_SUCCESS:
            return {
                ...state,
                supplements: action.payload
            };
        default: return state;
    }
}

/*************************************************/

export default supplementsReducer;

/*************************************************/
