import styled from 'styled-components';
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';

export const NutritionFormAdvantagesContainer = styled(RegistrationContainer)``;

export const NutritionFormAdvantagesHeading = styled(RegistrationHeading)``;

export const NutritionFormAdvantagesSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormAdvantagesCardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NutritionFormAdvantagesMobileHeading = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #142C1F;
`

export const NutritionFormAdvantagesMobileSubHeading = styled.div`
    font-size: 14px;
    font-weight: normal;
    color: #44554C;
`

export const NutritionFormAdvantagesImageContainer = styled.div`
    position: absolute;
    left: 10px;
`

export const NutritionFormAdvantagesCard = styled.div`
    max-width: 590px;
    margin: 0px auto;
    background: white;
    width:35%;
    border-radius: 10px;
    margin-top: 20px;
    @media (max-width: 600px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: 95%;
      }
`;

export const NutritionFormAdvantagesHeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const NutritionFormAdvantagesCardInnerContainer = styled.div`
    margin: 17px 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const NutritionFormAdvantagesPointNumber = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #2ECF96;
    margin-right: 20px;
    line-height: 32px;
    color: #2ECF96;
    text-align: center;
    @media (max-width: 600px) {
        width: 32px;
        height: 32px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    width:100%;
    bottom: 25px;
`;

export const NutritionFormAdvantageData = styled.div`
    font-family: RubikRegular;
    font-size: 16px;
    color: #44554C;
    width: 85%;
    text-align: left;
`;