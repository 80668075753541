import YoghurtWithFruits from "assets/images/eatingHabits/YoghurtWithFruits.jpg";
import ToastWithEggAndAvocado from "assets/images/eatingHabits/ToastWithEggAndAvocado.jpg";
import Smoothiebowl from "assets/images/eatingHabits/Smoothiebowl.jpg";
import Spiegeleier from "assets/images/eatingHabits/Spiegeleier.jpg";
import Granola from "assets/images/eatingHabits/Granola.jpg";
import Apple from "assets/images/eatingHabits/Apple.jpg";
import LowFatCurd from "assets/images/eatingHabits/LowFatCurd.jpg";
import Nuts from "assets/images/eatingHabits/Nuts.jpg";
import Avocado from "assets/images/eatingHabits/Avocado.jpg";
import Peach from "assets/images/eatingHabits/Peach.jpg";
import RiceCakes from "assets/images/eatingHabits/RiceCakes.jpg";
import Oatmeal from "assets/images/eatingHabits/Oatmeal.jpg";
import ScrambledEggs from "assets/images/eatingHabits/ScrambledEggs.jpg";
import Smoothiebowl1 from "assets/images/eatingHabits/Smoothiebowl1.jpg";
import ChilliConCarne from "assets/images/eatingHabits/ChilliConCarne.jpg";
import SalmonWithAsparagus from "assets/images/eatingHabits/SalmonWithAsparagus.jpg";
import GreekSaladWithFeta from "assets/images/eatingHabits/GreekSaladWithFeta.jpg";


export const RegistrationLinks = ['/app-erstellen', '/registration/gender', '/registration/goal', '/registration/bodyDetails',
    '/registration/dailyRoutine', '/registration/activityLevel', '/registration/trainingFixed',
    '/registration/eatingHabit1', '/registration/eatingHabit2', '/registration/eatingHabit3', '/registration/eatingHabit4',
    '/registration/eatingHabit5', '/registration/socialLogins', '/registration/personalData', '/payment', '/nutrition/creation'
];

export const hourValues = [
    { id: '1', value: '1 h' }, { id: '2', value: '2 h' }, { id: '3', value: '3 h' },
    { id: '4', value: '4 h' }, { id: '5', value: '5 h' }, { id: '6', value: '6 h' }, { id: '7', value: '7 h' },
    { id: '8', value: '8 h' }, { id: '9', value: '9 h' }, { id: '10', value: '10 h' }, { id: '11', value: '11 h' },
    { id: '12', value: '12 h' }, { id: '13', value: '13 h' }, { id: '14', value: '14 h' }, { id: '15', value: '15 h' },
    { id: '16', value: '16 h' }, { id: '17', value: '17 h' }, { id: '18', value: '18 h' }, { id: '19', value: '19 h' },
    { id: '20', value: '20 h' }, { id: '21', value: '21 h' }, { id: '22', value: '22 h' }, { id: '23', value: '23 h' },
    { id: '24', value: '24 h' },
]

export const AddDailyRoutineValues = [
    { id: "input.label.getup", hrs: '07' },
    { id: "input.label.lunchTime", hrs: '12' },
    { id: "input.label.workoutTime", hrs: '17' },
    { id: "input.label.bedTime", hrs: '22' },
];

export const activityLevelValues = [
    { id: "registration.activityLevel.sleep", options: hourValues },
    { id: "registration.activityLevel.lyingDown", options: hourValues },
    { id: "registration.activityLevel.standing", options: hourValues },
    { id: "registration.activityLevel.siting", options: hourValues },
    { id: "registration.activityLevel.walking", options: hourValues },
    { id: "registration.activityLevel.exercising", options: hourValues },
];

export const trainingFixedValues = [
    { id: "registration.trainingFixed.monday", value: 1 },
    { id: "registration.trainingFixed.tuesday", value: 2 },
    { id: "registration.trainingFixed.wednesday", value: 3 },
    { id: "registration.trainingFixed.thursday", value: 4 },
    { id: "registration.trainingFixed.friday", value: 5 },
    { id: "registration.trainingFixed.saturday", value: 6 },
    { id: "registration.trainingFixed.sunday", value: 7 },
];


export const AccordionContentData = [
    { id: "registration.dailyRoutine.getup" },
    { id: "registration.dailyRoutine.lunch" },
    { id: "registration.dailyRoutine.workoutTime" },
    { id: "registration.dailyRoutine.bedTime" },
]

export const getProgress = (id) => {
    let progress = '0%';
    if (id === 'gender') progress = "10%";
    if (id === 'goal') progress = "20%";
    if (id === 'bodyDetails') progress = "30%";
    if (id === 'dailyRoutine') progress = "40%";
    if (id === 'activityLevel') progress = "50%";
    if (id === 'trainingFixed') progress = "60%";
    if (id === 'eatingHabit1') progress = "70%";
    if (id === 'eatingHabit2') progress = "73%";
    if (id === 'eatingHabit3') progress = "76%";
    if (id === 'eatingHabit4') progress = "78%";
    if (id === 'eatingHabit5') progress = "80%";
    if (id === 'socialLogins') progress = "90%";
    if (id === 'personalData') progress = "90%";
    return progress;
}

export const eatingHabits1Data = [
    { id: 'YoghurtWithFruits', image: YoghurtWithFruits, heading: "registration.eatingHabit.yoghurtWithFruit" },
    { id: 'ToastWithEggAndAvocado', image: ToastWithEggAndAvocado, heading: "registration.eatingHabit.toastWithEggAvocada" },
    { id: 'Smoothiebowl', image: Smoothiebowl, heading: "registration.eatingHabit.Smoothiebowl" },
    { id: 'Spiegeleier', image: Spiegeleier, heading: "registration.eatingHabit.Spiegeleier" },
    { id: 'Granola', image: Granola, heading: "registration.eatingHabit.granola" },
]

export const eatingHabits2Data = [
    { id: 'Apple', image: Apple, heading: "registration.eatingHabit.apple" },
    { id: 'LowFatCurd', image: LowFatCurd, heading: "registration.eatingHabit.LowFatCurd" },
    { id: 'Nuts', image: Nuts, heading: "registration.eatingHabit.Nuts" },
]

export const eatingHabits3Data = [
    { id: 'Avocado', image: Avocado, heading: "registration.eatingHabit.Avocado" },
    { id: 'Peach', image: Peach, heading: "registration.eatingHabit.Peach" },
    { id: 'RiceCakes', image: RiceCakes, heading: "registration.eatingHabit.RiceCakes" },
]

export const eatingHabits4Data = [
    { id: 'Oatmeal', image: Oatmeal, heading: "registration.eatingHabit.Oatmeal" },
    { id: 'ScrambledEggs', image: ScrambledEggs, heading: "registration.eatingHabit.ScrambledEggs" },
    { id: 'Smoothiebowl1', image: Smoothiebowl1, heading: "registration.eatingHabit.Smoothiebowl1" },
]

export const eatingHabits5Data = [
    { id: 'ChilliConCarne', image: ChilliConCarne, heading: "registration.eatingHabit.chilliConCarne" },
    { id: 'SalmonWithAsparagus', image: SalmonWithAsparagus, heading: "registration.eatingHabit.salmonWithAsparagus" },
    { id: 'GreekSaladWithFeta', image: GreekSaladWithFeta, heading: "registration.eatingHabit.greekSaladWithFeta" },
]