import styled from "styled-components"

export const CoachingAccordianContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 100px;
  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 50px;
  @media (max-width: 600px) {
    display: none;
  }
`

export const TitleMobile = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 600; */
  margin-bottom: 50px;
 
  @media (min-width: 600.99px) {
    display: none;
  }
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
    height: auto;
  }

  > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    img {
      margin-top: 30px;
    }
  }

  @media (max-width: 991.99px) {
    height: auto;
    order: 1;
    img {
      max-width: 500px;
      margin-inline: auto;
      display: block;
    }
  }
`

export const Info = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 1rem;
  font-weight: 400; */
  margin-bottom: 40px;
`
