import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./nutritionRecipes.styles";
import TranslationMessage from "configs/translations/TranslationMessage";
import { getImageUrl } from "configs/utils";
import { useHistory } from "react-router-dom";
import LeftIcon from "assets/images/leftIcon.svg";

// Components
import Topbar from "components/topbar/Topbar";
import Loader from "components/loader/Loader";
import Button from "components/formComponents/button/Button";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import ToolTip from "components/ToolTip/ToolTip";

const NutritionRecipes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const recipesData = useSelector((state) => state.nutrition.nutritionRecipes);
  const [isMobile, setIsMobile] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState(1);
  const [newDataIndex, setNewDataIndex] = useState(0);
  const allData = recipesData && [
    recipesData[newDataIndex],
    recipesData[newDataIndex + 1],
  ];

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleNext = () => {
    history.push("/nutrition/calories");
  };

  const handlePrev = () => {
    history.push("/nutrition/formAdvantages");
  };

  useEffect(() => {
    document.body.classList.add("app-register");
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);
    setNewDataIndex(0);
    dispatch(
      NutritionActions.getNutritionRecipesRequest({
        nutritiontypeID: registrationData.nutritiontypeID,
        mealId: selectedFilter,
      })
    );
    return () => {
      document.body.classList.remove("app-register");
    };
  }, [dispatch, registrationData, selectedFilter]);

  const buttonValues = [
    { id: 1, value: "Frühstück" },
    { id: 2, value: "Mittagessen" },
    { id: 3, value: "Abendessen" },
  ];

  const EatingHabitsContent = () => {
    return (
      <>
        {allData &&
          allData.map((data) => {
            return (
              <>
                {data && (
                  <S.NutritionFormRecipesCard key={data.id}>
                    <S.NutritionFormRecipesCardImageContainer>
                      <S.NutritionFormRecipesCardImage
                        src={getImageUrl(
                          data.imgpath,
                          data.imgname,
                          data.imgtype
                        )}
                        alt={data.id}
                      />
                    </S.NutritionFormRecipesCardImageContainer>
                    <S.NutritionFormRecipesCardInnerContainer>
                      <S.NutritionFormRecipesCardInnerHeading>
                        <ToolTip value={data.recipeName} maxCharacter={25} />
                        <S.NutritionFormRecipesCardInnerSubHeading>
                          {data.mealName}
                        </S.NutritionFormRecipesCardInnerSubHeading>
                      </S.NutritionFormRecipesCardInnerHeading>
                      <S.NutritionFormRecipesCardInnerCalories>
                        {data.calories} <br />
                        <S.NutritionFormRecipesCardInnerCaloriesValue>
                          kcal
                        </S.NutritionFormRecipesCardInnerCaloriesValue>
                      </S.NutritionFormRecipesCardInnerCalories>
                    </S.NutritionFormRecipesCardInnerContainer>
                  </S.NutritionFormRecipesCard>
                )}
              </>
            );
          })}
      </>
    );
  };

  const filterButtons = () => (
    <>
      {buttonValues.map((button) => {
        return (
          <Button
            outlined
            key={button.value}
            color={
              selectedFilter === button.id
                ? "#1C9D87"
                : "rgba(165, 181, 175, .6)"
            }
            style={{
              padding: "7px 20px",
              marginRight: "10px",
              border: "1px solid",
            }}
            onClick={() => setSelectedFilter(button.id)}
          >
            {button.value}
          </Button>
        );
      })}
    </>
  );

  return (
    <div>
      {!isMobile && (
        <Topbar
          prev="/nutrition/formAdvantages"
          next="/nutrition/calories"
          progress="50%"
        />
      )}
      <S.NutritionFormRecipesContainer>
        {isMobile ? (
          <>
            <S.NutritionRecipeHeadingContainer>
              <S.NutritionRecipeImageContainer>
                <img
                  onClick={() => handlePrev()}
                  src={LeftIcon}
                  alt="Left Icon"
                />
              </S.NutritionRecipeImageContainer>
              <S.NutritionRecipeMobileHeading>
                <TranslationMessage id="nutrition.formRecipes.mobileHeading" />
              </S.NutritionRecipeMobileHeading>
            </S.NutritionRecipeHeadingContainer>
            <S.NutritionRecipeMobileSubHeading>
              <TranslationMessage id="nutrition.formRecipes.mobileSubHeading" />
            </S.NutritionRecipeMobileSubHeading>
          </>
        ) : (
          <>
            <S.NutritionFormRecipesHeading>
              <TranslationMessage id="nutrition.formRecipes.heading" />
            </S.NutritionFormRecipesHeading>
            <S.NutritionFormRecipesSubHeading>
              <TranslationMessage id="nutrition.formRecipes.subHeading" />
            </S.NutritionFormRecipesSubHeading>
          </>
        )}
        <S.NutritionFormRecipesButtonContainer>
          {filterButtons()}
          {!loading && (
            <>
              <S.NutritionFormRecipesButtonLeft
                active={newDataIndex > 0}
                onClick={() => {
                  if (newDataIndex >= 2) setNewDataIndex(newDataIndex - 2);
                }}
              >
                <S.ArrowButton left active={newDataIndex > 0} />
                <S.StraightLine active={newDataIndex > 0} />
              </S.NutritionFormRecipesButtonLeft>
              <S.NutritionFormRecipesButtonRight
                active={recipesData && newDataIndex + 2 < recipesData.length}
                onClick={() => {
                  if (newDataIndex + 2 < recipesData.length)
                    setNewDataIndex(newDataIndex + 2);
                }}
              >
                <S.ArrowButton
                  right
                  active={recipesData && newDataIndex < recipesData.length}
                />
                <S.StraightLine
                  active={recipesData && newDataIndex < recipesData.length}
                />
              </S.NutritionFormRecipesButtonRight>
            </>
          )}
        </S.NutritionFormRecipesButtonContainer>
        {!loading && EatingHabitsContent()}
        {<Loader loading={loading} top="100px" />}
      </S.NutritionFormRecipesContainer>
      {isMobile && (
        <S.NextButtonContainer>
          <Button
            onClick={() => handleNext()}
            color="#fff"
            style={{
              width: 95 + "%",
              borderRadius: "6px",
              background:
                "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <TranslationMessage id="button.next" />
          </Button>
        </S.NextButtonContainer>
      )}
    </div>
  );
};

export default NutritionRecipes;
