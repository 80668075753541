import styled from 'styled-components';
import {
    RegistrationContainer, RegistrationHeading, EatingHabitsCardContainer,
    RegistrationSubHeading, BodyDetailsCard, BodyDetailsCardHeading
} from 'pages/registration/registration.styles';

import {
    ButtonContainer, NutritionFormAdvantagesMobileHeading,
    NutritionFormAdvantagesImageContainer, NutritionFormAdvantagesHeadingContainer
} from 'pages/formAdvantages/formAdvantages.styles';

export const NutritionFormCaloriesContainer = styled(RegistrationContainer)`
    margin: 10px;
`;

export const NutritionFormCaloriesHeading = styled(RegistrationHeading)``;

export const NutritionFormCaloriesSubHeading = styled(RegistrationSubHeading)``;

export const NutritionCaloriesImageContainer = styled(NutritionFormAdvantagesImageContainer)``;

export const NutritionCaloriesHeadingContainer = styled(NutritionFormAdvantagesHeadingContainer)`
    margin-top: 60px;
`;

export const NextButtonContainer = styled(ButtonContainer)``;

export const NutritionCaloriesMobileHeading = styled(NutritionFormAdvantagesMobileHeading)``;

export const NutritionCaloriesCard = styled.div`
    margin: 50px 10px 10px 0px;
    background: white;
    width: 100%;
    height: 200px;
    padding-top: 15px;
    padding-bottom: 20px;
`;

export const NutritionFormCaloriesInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0 30px;
    @media (max-width: 600px) {
        margin-bottom: 40px;
        padding: 10px;
    }
`;

export const NutritionFormCaloriesLineGraphContainer = styled.div`
    width: 447px;
    height: 289px;
    background: white;
    border-radius: 10px;
    @media (max-width: 600px) {
        max-width: 100%;
    }
`;

export const NutritionFormCaloriesDescription = styled.div`
    width: 427px;
    font-family: RubikRegular;
    font-size: 16px;
    color: rgba(68, 68, 68, .6);
    text-align: left;
    line-height: 25px;
    margin-left: 25px;
    @media (max-width: 600px) {
        width: 90%;
        height: 65%;
        overflow-y: scroll;
        margin-left: -20px;
        font-size: 14px;
        color: #44554C;
        line-height: 16px;
        padding: 10px 20px 10px 30px;
    }
`;

export const EditCaloriesWeekHeadingContainer = styled.div`
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    color: #1C9D87;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const EditCaloriesWeekHeading = styled.span`
    line-height: 50px;
`;

export const EditCaloriesNavigationIconContainer = styled.span`
    margin: 10px 30px;
    cursor: pointer;
`;

export const EditCaloriesInputCard = styled(BodyDetailsCard)`
    background-color: #F5FAF9;
`;

export const EditCaloriesInputCardHeading = styled(BodyDetailsCardHeading)``;
