
const SupplementsTypes = {
    GET_SUPPLEMENTS_REQUEST: 'GET_SUPPLEMENTS_REQUEST',
    GET_SUPPLEMENTS_SUCCESS: 'GET_SUPPLEMENTS_SUCCESS',
    GET_SUPPLEMENTS_FAILURE: 'GET_SUPPLEMENTS_FAILURE',
    
    DELETE_MAIL_CHIMP_USER_REQUEST: 'DELETE_MAIL_CHIMP_USER_REQUEST',
    DELETE_MAIL_CHIMP_USER_SUCCESS: 'DELETE_MAIL_CHIMP_USER_SUCCESS',
    DELETE_MAIL_CHIMP_USER_FAILURE: 'DELETE_MAIL_CHIMP_USER_FAILURE',
    
    ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST: 'ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST',
    ADD_PRO_USER_MAIL_CHIMP_DATA_SUCCESS: 'ADD_PRO_USER_MAIL_CHIMP_DATA_SUCCESS',
    ADD_PRO_USER_MAIL_CHIMP_DATA_FAILURE: 'ADD_PRO_USER_MAIL_CHIMP_DATA_FAILURE',
    
    ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST: 'ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST',
    ADD_BASIC_USER_MAIL_CHIMP_DATA_SUCCESS: 'ADD_BASIC_USER_MAIL_CHIMP_DATA_SUCCESS',
    ADD_BASIC_USER_MAIL_CHIMP_DATA_FAILURE: 'ADD_BASIC_USER_MAIL_CHIMP_DATA_FAILURE',

    UPDATE_USER_SUBSCRIPTION_REQUEST: 'UPDATE_USER_SUBSCRIPTION_REQUEST',
    UPDATE_USER_SUBSCRIPTION_SUCCESS: 'UPDATE_USER_SUBSCRIPTION_SUCCESS',
    UPDATE_USER_SUBSCRIPTION_FAILURE: 'UPDATE_USER_SUBSCRIPTION_FAILURE',
    
    INITIATE_STRIPE_PAYMENT_REQUEST: 'INITIATE_STRIPE_PAYMENT_REQUEST',
    INITIATE_STRIPE_PAYMENT_SUCCESS: 'INITIATE_STRIPE_PAYMENT_SUCCESS',
    INITIATE_STRIPE_PAYMENT_FAILURE: 'INITIATE_STRIPE_PAYMENT_FAILURE',
}

export default SupplementsTypes;
