import styled from "styled-components";

export const Wrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: white;
  border-radius: 0.375rem;
`;

export const IconWrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:0.25rem:
  color:white;
  background-color:#ef4444;
  border-radius: 9999px;
  color:white
`;

export const Title = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  text-align: center;
  margin-top: 0.8rem;
`;
export const Message = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  color: #a8a29e;
  font-weight: 600;
  margin-top: 0.8rem;
`;
