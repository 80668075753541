import { takeLatest, put, all, call } from 'redux-saga/effects';

import ApiCollections from 'configs/services/apiCollections'

import * as AuthActions from 'redux/auth/auth.actions';
import NutritionActionTypes from './nutrition.types';
import * as NutritionActions from './nutrition.actions';

/*************************************************/

export function* getUserData() {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getUserData);
    yield put(NutritionActions.getUserDataSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getUserDataFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getNutritionType({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getNutritionType, payload);
    yield put(NutritionActions.getNutritionTypeSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(NutritionActions.getNutritionTypeFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getAllNutritionTypes({ payload }) {
    yield put(AuthActions.loadingStart());
    try {
      const response = yield call(ApiCollections.getAllNutritionTypes, payload);
      yield put(NutritionActions.getAllNutritionTypesSuccess(response.data));
      yield put(AuthActions.loadingStop());
    } catch (error) {
      yield put(NutritionActions.getAllNutritionTypesFailure(error));
      yield put(AuthActions.loadingStop());
    }
  }

/*************************************************/

export function* updateNutritionType({ payload }) {
    yield put(AuthActions.loadingStart());
    try {
      const response = yield call(ApiCollections.updateNutritionType, payload);
      yield put(NutritionActions.updateNutritionTypeSuccess(response.data));
      yield put(AuthActions.loadingStop());
    } catch (error) {
      yield put(NutritionActions.updateNutritionTypeFailure(error));
      yield put(AuthActions.loadingStop());
    }
}

/*************************************************/

export function* updateNutritionCalories({ payload }) {
    yield put(AuthActions.loadingStart());
    try {
      const response = yield call(ApiCollections.updateNutritionCalories, payload);
      yield put(NutritionActions.updateNutritionCaloriesSuccess(response.data));
      yield put(AuthActions.loadingStop());
    } catch (error) {
      yield put(NutritionActions.updateNutritionCaloriesFailure(error));
      yield put(AuthActions.loadingStop());
    }
}

/*************************************************/

export function* getNutritionAdvantages({ payload }) {
    yield put(AuthActions.loadingStart());
    try {
      const response = yield call(ApiCollections.getNutritionAdvantages, payload);
      yield put(NutritionActions.getNutritionAdvantagesSuccess(response.data));
      yield put(AuthActions.loadingStop());
    } catch (error) {
      yield put(NutritionActions.getNutritionAdvantagesFailure(error));
      yield put(AuthActions.loadingStop());
    }
}

/*************************************************/

export function* getNutritionRecipes({ payload }) {
    yield put(AuthActions.loadingStart());
    try {
      const response = yield call(ApiCollections.getNutritionRecipes, payload);
      yield put(NutritionActions.getNutritionRecipesSuccess(response.data));
      yield put(AuthActions.loadingStop());
    } catch (error) {
      yield put(NutritionActions.getNutritionRecipesFailure(error));
      yield put(AuthActions.loadingStop());
    }
}

/*************************************************/

export function* getWeekWiseCalories({ payload }) {
    yield put(AuthActions.loadingStart());
    try {
      const response = yield call(ApiCollections.getWeekWiseCaloriesData, payload);
      yield put(NutritionActions.getWeekWiseCaloriesSuccess({description: response['01_text'] || response['02_text'] || response['03_text'] || response['04_text'], ...response.data}));
      yield put(AuthActions.loadingStop());
    } catch (error) {
      yield put(NutritionActions.getWeekWiseCaloriesFailure(error));
      yield put(AuthActions.loadingStop());
    }
}

/*************************************************/

export function* getMacroNutrientsDonutData({ payload }) {
    yield put(AuthActions.loadingStart());
    try {
      const response = yield call(ApiCollections.getMacroNutrientsDonutData, payload);
      yield put(NutritionActions.getMacroNutrientsDonutSuccess(response));
      yield put(AuthActions.loadingStop());
    } catch (error) {
      yield put(NutritionActions.getMacroNutrientsDonutFailure(error));
      yield put(AuthActions.loadingStop());
    }
}

/*************************************************/

export function* nutritionSagas() {
  yield all([
    yield takeLatest(NutritionActionTypes.GET_USER_DATA_REQUEST, getUserData),
    yield takeLatest(NutritionActionTypes.GET_NUTRITION_TYPE_REQUEST, getNutritionType),
    yield takeLatest(NutritionActionTypes.GET_ALL_NUTRITION_TYPES_REQUEST, getAllNutritionTypes),
    yield takeLatest(NutritionActionTypes.UPDATE_NUTRITION_TYPE_REQUEST, updateNutritionType),
    yield takeLatest(NutritionActionTypes.UPDATE_NUTRITION_CALORIES_REQUEST, updateNutritionCalories),
    yield takeLatest(NutritionActionTypes.GET_NUTRITION_ADVANTAGES_REQUEST, getNutritionAdvantages),
    yield takeLatest(NutritionActionTypes.GET_NUTRITION_RECIPES_REQUEST, getNutritionRecipes),
    yield takeLatest(NutritionActionTypes.GET_WEEK_WISE_CALORIES_REQUEST, getWeekWiseCalories),
    yield takeLatest(NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_REQUEST, getMacroNutrientsDonutData),
  ]);
}

/*************************************************/