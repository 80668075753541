import styled from "styled-components";

export const MealPlanContainer = styled.div`
  .left-title {
    letter-spacing: 0.4px;
    color: #142c1f;
    font-size: 2.5rem;
    font-family: "RubikMedium";
    margin-bottom: 2rem;
  }
  @media (max-width: 991.99px) {
  }
`;

export const Title = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 60px;

  b {
    font-weight: 600;
  }

  @media (max-width: 991.99px) {
    margin-bottom: 50px;
  }
`;

export const RestaurauntsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;

  @media (max-width: 1300px) {
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }

  .restaurant {
    height: 26rem;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0px 20px 40px #02332a1a;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 160px;
      width: 100%;
      filter: grayscale(1);
    }

    @media (max-width: 576px) {
      height: 16rem;

      img {
        max-width: 120px;
      }

      &:last-child {
        display: none;
      }
    }
  }
`;

export const VisualContent = styled.div`
  height: 600px;
  img {
    width: auto;
    height: 100%;
    display: block;
    margin-inline: auto;
  }
  @media (max-width: 991.99px) {
    margin-top: 70px;
  }

  @media (max-width: 600px) {
    height: 400px;
    display: none;
  }
`;

export const VisualContentInside = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
    margin-inline: auto;
    object-position: center center;
    max-width: 30rem;
    /* margin-left: 10%; */
    /* scale: 1.3; */
  }
  @media (max-width: 991.99px) {
    margin-top: 70px;
  }

  @media (min-width: 600.99px) {
    display: none;
  }
`;

export const CardBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const CardBox = styled.div`
  padding: 30px;

  &.active,
  &:hover {
    background: #ffffff;
    box-shadow: 0px 50px 99px #02332a1a;
    border-radius: 30px;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
`;

export const CardBoxIcon = styled.div`
  width: 8rem;
  min-width: 8rem;
  height: 8rem;
  background: #e5f5f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 6rem;
    height: 3rem;
  }
`;

export const CardBoxRight = styled.div`
  margin-left: 20px;
  padding: 15px 0 0;
`;

export const PlanTitle = styled.h4`
  color: #142c1f;
  /* font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 19px; */
`;

export const PlanText = styled.p`
  color: #7a8780;
  /* font-size: 1rem;
  font-weight: 400; */
`;
