import { useSelector } from "react-redux"
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather"
import Slider from "react-slick"
import { getS3ImageUrl } from "configs/utils"
import Placeholder from "assets/images/placeholder.jpg"
import * as C from "./testimonialSlider.style"

function NextArrow(props) {
  const { className, style, onClick } = props

  return (
    <ArrowRightCircle
      className={className}
      style={{ ...style }}
      onClick={onClick}
      strokeWidth={1}
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <ArrowLeftCircle
      className={className}
      style={{ ...style }}
      onClick={onClick}
      strokeWidth={1}
    />
  )
}

const TestimonialSlider = ({ list, baseUrl }) => {
  const coachFeedback = useSelector((state) => state.coach.feedback)

  const slidesToShow = coachFeedback.length === 1 ? 1 : 2; 
console.log(coachFeedback)
  const settings = {
    className: "",
    slidesToShow,
    speed: 500,
    nextArrow: <NextArrow className="" />,
    prevArrow: <PrevArrow className="" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }

  return (
    <>
      <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">Das sagen meine Klienten</C.Title>
      <C.SliderCard className="user-slider">
        <Slider className="slick-slider" {...settings}>
          {coachFeedback?.map((item, i) => (
            <div className="slick-item" key={i}>
              <div className="sliderInner">
                <C.UserContent>
                  <h2 className="ct-text-block color-dark text-xl font-medium mb-2">{`${item?.prename} ${item?.lastname}`}</h2>
                  <small className="ct-text-block color-primary">Zufriedener Klient</small>
                </C.UserContent>
                <C.UserDescription className="ct-text-block text-base color-paragraph text-left mt-10">{item?.text}</C.UserDescription>
                <C.UserProfile>
                  <img
                    src={
                      item?.picture ? getS3ImageUrl(item?.picture) : Placeholder
                    }
                    alt="feedback user"
                  />
                </C.UserProfile>
              </div>
            </div>
          ))}
        </Slider>
      </C.SliderCard>
    </>
  )
}

export default TestimonialSlider
