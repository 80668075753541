import styled from "styled-components";

export const InputContainer = styled.span`
    display: flex;
    flex-direction: row;
`;

export const CustomInput = styled.input`
    background-color: ${props => props.filled ? 'rgba(165, 181, 175, .15)' : 'white'};
    color: #A5B5AF;
    font-size: 14px;
    border: ${props => props.filled ? 'none' : "rgba(165, 181, 175, .15) 2px solid"};
    width: 275px;
    padding: ${props => props.filled ? '11px 15px' : "9px 15px"};
    border-radius: 5px;
    outline: none;
    margin: 0 auto;

    ::focus {
        outline: none;
    

    ::placeholder {
        opacity: .5;
    }
`;

export const InputWarningImage = styled.img`
    width: 12px;
    height: 12px;
    margin: 12.5px 12.5px 0 -25px;
`;