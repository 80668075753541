import styled from "styled-components";

export const AccordianContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})``;

export const AccordianBox = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  /* border-bottom: 1px solid #dddddd; */
  p {
    font-family: "RubikRegular";
    color: #44554c;
    font-size: 18px;
    margin: 0px 0 30px;
    a {
      color: #2ecf96;
    }
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 23px;
      margin: 0px 0 20px;
    }
  }
  &:last-child {
    border: none;
  }
`;

export const AccordianHeader = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  cursor: pointer;

  .IconPlus {
    width: 15px;
    height: 15px;
    background-image: "assets/images/myCoaching/Icon-plus.svg";
  }
  .IconClose {
    width: 20px;
    height: 20px;
    background-image: "assets/images/myCoaching/Icon-Close.svg";
    cursor: pointer;
  }
`;
export const MenuItem = styled.h6.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  font-size: 2rem;
  font-weight: 400;
  width: calc(100% - 30px);
  cursor: pointer;
  margin: 0 0 10px 5px;
  position: relative;
  a {
    color: #44554c;
  }
  &:hover::before {
    content: "";
    height: 90%;
    width: 1.5px;
    /* background-color: #a5b5af; */
    background-color: #2dcc95;
    border-radius: 3px;
    position: absolute;
    top: 5%;
    left: -10px;
  }

  @media (max-width: 767.99px) {
    font-size: 1.5rem;
  }
`;

export const AccordianTitle = styled.span.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  /* font-family: 'RubikBold'; */
  font-size: 2rem;
  font-weight: 400 !important;
  width: calc(100% - 30px);
  margin: 0 0 8px;
  position: relative;
  &.active {
  }

  @media (width <= 767.99px) {
    font-size: 1.8rem;
  }

  ${({ active }) =>
    active &&
    `
  margin: 0 0 24px;
  &::after {
          content: "";
          height: 3px;
          width: 30px;
          margin-inline: auto;
          background-color: #2dcc95;
          border-radius: 3px;
          position: absolute;
          bottom: -14px;
          left: 0;
          transition: all ease-in-out 0.3s;
        }
  `}
`;
export const AccordianButton = styled.button.attrs({
  className: `${(props) => props.className}`,
})`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 32px;
  margin-bottom: 52px;
  img {
    width: 100%;
    height: auto;
    max-height: 360px;
    max-width: 300px;
    margin-inline: auto;
    display: block;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
