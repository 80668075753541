import React, { useState } from "react";
import * as C from "./accordian.styled";

import PlusIcon from "assets/images/myCoaching/Icon-plus.svg";
import CloseIcon from "assets/images/myCoaching/Icon-Close.svg";

const Question = ({ title, info, id, img, onExpanded, activeQuestion }) => {
  const [expanded, setExpanded] = useState(null);

  return (
    <C.AccordianBox className="question">
      <C.AccordianHeader>
        <C.AccordianTitle
          id="text_block-166-29"
          className="ct-text-block accordion-title font-medium color-dark"
          active={expanded === activeQuestion?.id}
          onClick={() => {
            setExpanded(id);
            onExpanded();
          }}
        >
          {title}
        </C.AccordianTitle>
        {expanded === activeQuestion?.id && (
          <C.AccordianButton className="btn" onClick={() => setExpanded(null)}>
            <img src={CloseIcon} alt="close" />
          </C.AccordianButton>
        )}
        {expanded !== activeQuestion?.id && (
          <C.AccordianButton
            className="btn"
            onClick={() => {
              onExpanded();
              setExpanded(id);
            }}
          >
            <img src={PlusIcon} alt="plus" />
          </C.AccordianButton>
        )}
      </C.AccordianHeader>
      {expanded === activeQuestion?.id && (
        <>
          <p className="ct-text-block panel font-normal color-paragraph">
            {info}
          </p>
          <C.VisualContent>
            <img src={img} alt="accord" />
          </C.VisualContent>
        </>
      )}
    </C.AccordianBox>
  );
};

export default Question;
