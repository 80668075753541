import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
  }

  @media(max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
`

export const Description = styled.p`
  color: #44554c;
  margin: 35px 0 20px;
`

export const VisualContent = styled.div`

  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media(max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin-inline: auto;
    order: 1;
  }
`