import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Title = styled.h3``;

export const Description = styled.p`
  color: #44554c;
  /* font-weight: 400;
  font-size: 1rem; */
`;

export const VisualContent = styled.div`
  position: relative;
  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-left: auto;
    display: block;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`;

export const Image = styled.div`
  position: relative;
  height: 100%;
  /* min-height: 500px; */
  img {
    -webkit-mask: url(${(props) => props.maskImage}) center/contain no-repeat;
    max-width: 100%;
  }
  /* .shape,
  .img {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 500px;
    max-width: 550px;
    

  }

  .shape {
    z-index: 12;
    width: 100%;
    height: 100%;
    min-height: 510px;
  }

  .img {
    width: 99%;
    height: 99%;
    margin: 0.5% auto 0;
    display: block;
    object-fit: cover;
    object-position: top;
  } */

  /* @media (max-width: 600px) {

    .shape,
    .img {
      min-height: auto;
    }

    .shape {
      min-height: auto;
    }
  }
  @media (max-width: 500px) {
    .shape,
    .img {
      max-height: 500px;
    }

    .shape {
      max-height: 510px;
    }
  }
  @media (max-width: 400px) {
    .shape,
    .img {
      max-height: 400px;
    }

    .shape {
      max-height: 410px;
    }
  }
  @media (max-width: 300px) {
    .shape,
    .img {
      max-height: 300px;
    }

    .shape {
      max-height: 310px;
    }
  } */
`;

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 8rem;
  position: absolute;
  left: 0;
  top: 60%;
  transform: translate(0, -50%);

  @media (max-width: 700px) {
    left: 50%;
    top: 30px;
    transform: translate(-50%, -15px);
  }
`;
