import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import * as S from './nutritionCreation.styles';
import { useIntl } from "react-intl";

// Images
import BackdropImage from 'assets/images/backdropWave.svg';

// Video
import CreationVideo from 'assets/videos/creationVideo.mp4';
import Loader from 'components/loader/Loader';
import TranslationMessage from 'configs/translations/TranslationMessage';

const NutritionCreation = () => {
    const [isMobile, setIsMobile] = useState(false);
    let history = useHistory();
    const intl = useIntl();

    const handleResize = () => {
        if (window.innerWidth < 600) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.localStorage.removeItem("voucher");
        if (window.innerWidth < 600) {
            setIsMobile(true)
        }
        handleResize();
    })

    const redirectToNextPage = () => {
        history.push('/nutrition/changePassword')
        document.body.style.overflowY = "auto";
    }

    // useEffect(() => {
    //     console.log('in use effect');
    //     //document.body.style.overflowY = "hidden";
    //     const listenerFunction = () => {
    //         history.push('/nutrition/changePassword')
    //         document.body.style.overflowY = "auto";
    //     }
    //     const video = document.getElementById('myvid');
    //     video.addEventListener('ended', listenerFunction)
    //     return () => video.removeEventListener("ended", listenerFunction);
    // }, [])

    return (
        <>
            {!isMobile &&
                <Loader top="350px" left="30%" />
            }
            <S.NutritionCreationTextContainer>
                {!isMobile &&
                    <TranslationMessage id="nutrition.creation.creating" />
                }
            </S.NutritionCreationTextContainer>
            <S.NutritionBackdropImage src={BackdropImage} alt="Backdrop" />
            <S.NutritionCreationVideo mute autostart playsInline autoPlay onEnded={redirectToNextPage} src={CreationVideo} preload={'auto'} type="video/mp4" />
            {/* {isMobile &&
                <S.NutritionCreationMobileTextBottomContainer>
                    <S.NutritionCreationMobileTextBottom height={window.innerHeight - 100}>
                        <TranslationMessage id="nutrition.creation.bottomText" />
                    </S.NutritionCreationMobileTextBottom>
                </S.NutritionCreationMobileTextBottomContainer>
            } */}
        </>
    )
}

export default NutritionCreation;
