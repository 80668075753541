import React from 'react';
import * as S from './progressBar.styles';

const ProgressBar = (props) => {
    const dotsArray = Array(7).fill({})
    console.log('props', props);
    return (
        <S.ProgressBarContainer>
            {props.isMobile &&
                <S.ProgressBarDotsContainer>
                    {dotsArray.map((value, index) => (
                        <>
                            {index === props.index ?
                                <S.ProgressBarSelectedDot />
                                : index > props.index ?
                                    <S.ProgressBarDot />
                                    :
                                    <div></div>
                            }
                        </>
                    ))}
                </S.ProgressBarDotsContainer>
            }
            <S.ProgressBarFiller width={props.progress}>
                {!props.isMobile && <S.ProgressBarLabel>{props.progress}</S.ProgressBarLabel>}
            </S.ProgressBarFiller>
        </S.ProgressBarContainer>
    )
}

export default ProgressBar
