import React from "react";
import { useHistory } from "react-router-dom";
import * as F from "./intelligentRecipeFilter.style";
import Chip from "components/general/formComponents/chip/Chip";
import ReactPlayer from "react-player";

const IntelligentRecipeFilter = () => {
  const history = useHistory();
  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="REZEPTE" margin="0 0 2rem 0" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Verwende die Ernährungsplaner - App für deinen Alltag.
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Setze in deinem Alltag auf die Vorteile der Foodiary App. Plane
          einfach deine Ernährung, priorisiere deine Gesundheit und dein
          Wohlbefinden. Es ist jetzt Zeit deine Ernährungsumstellung zu rocken.
        </F.Description>
        <F.Description className="ct-text-block color-paragraph-alt text-base text-left mt-6">
          Smarte Funktionen wie die Wochenplanung, Einkaufsliste und der
          Rezeptfilter helfen dir dabei, deine Ernährung im Alltag step by step
          gesünder zu gestalten.
        </F.Description>
        <button
          className="ct-link-text foodiary-green-button cursor-pointer mt-8"
          onClick={() => history.push("/app-erstellen")}
        >
          Ernährungsplan erstellen
        </button>
      </F.ContentSection>

      <F.VisualContent>
        {/* <img src={FoodiaryErnahrungsberatung} alt="coach" /> */}
        {/*  https://foodiary.app/data/rezepte-auswaehlen-foodiary.mp4 */}
        <ReactPlayer
          url={"https://foodiary.app/data/LP-Foodiary-App-Dashboard-2.mp4"}
          playsinline
          playing
          muted
          loop
        />
        <div className="white-border" />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default IntelligentRecipeFilter;
