const CoachTypes = {
    LOADING_START: 'LOADING_START',
    LOADING_STOP: 'LOADING_STOP',
    
    TRAINER_ID_REQUEST: 'TRAINER_ID_REQUEST',
    TRAINER_ID_SUCCESS: 'TRAINER_ID_SUCCESS',
    TRAINER_ID_FAILURE: 'TRAINER_ID_FAILURE',

    COACH_DATA_REQUEST: 'COACH_DATA_REQUEST',
    COACH_DATA_SUCCESS: 'COACH_DATA_SUCCESS',
    COACH_DATA_FAILURE: 'COACH_DATA_FAILURE',
}

export default CoachTypes;
