import styled from "styled-components"

export const GeneralWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  align-items: center;
  justify-content: center;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    max-width: 100%;
  }

  @media(max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 35px;
`
export const Description = styled.p`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  color: #7a8780;
  padding-bottom: 60px;
`

export const VisualContent = styled.div`

  height: 600px;

  img {
    width: 100%;
    height: 100%;
  }

  @media(max-width: 991.99px) {
    margin-inline: auto;
    order: 1
  }

  @media (max-width: 767.99px) {
    height: 400px;
  }
`

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;