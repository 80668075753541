import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

// Components
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";

// Redux
import * as CoachActions from "redux/coach/coach.actions";

// Styles
import * as G from "styles/General.Styles";
import * as C from "./OnlineKursStarten.styles";
import HealthStatusModal from "modals/HealthStatusModal/HealthStatusModal";

const OnlineKursStarten = () => {
  const coach = useSelector((state) => state?.coach?.coach);

  const [OpenHealthStatusModal, setOpenHealthStatusModal] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    email: '',
    password: ''
  };

const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    password: Yup.string().required('Required')
    .min(8,'Password is too short - should be 8 chars minimum')
});

const onFormSubmit = async (values) => {

    const paymentType = "one-time"
    const {
      data: { data: url },
    } = await axios.post(
      `https://app.foodiary.app/api/app/coach/stripe-charge-course`,
      {
        email: values.email,
        password: values.password,
        paymentType : paymentType,
        trainerID: coach.trainerID,
        success_url: "https://prevention.foodiary.app/starten/payment/successful-765x23wzb/",
        cancel_url: "https://prevention.foodiary.app/starten/payment/cancel-765x23wzb/",
      }
    );
    window.location.href = url;
}


  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            {/* <Navbar /> */}
            <StickyNavbar />
          </G.ContainerNavbar>
          <G.Container>
            <SectionWrapper margin="19.5rem 0 0" padding="0 0 15.5rem">
              <div
                id="div_block-3-75"
                className="ct-div-block grid items-stretch lg-grid-cols-1 gap-24 grid-cols-5"
              >
                <div
                  id="div_block-4-75"
                  className="ct-div-block col-span-2 col-span-3"
                >
                  <h2
                    id="headline-6-75"
                    className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12"
                  >
                    Starte deinen Prävenstionskurs
                  </h2>
                  <div
                    id="text_block-7-75"
                    className="ct-text-block color-paragraph font-normal text-base mb-5"
                  >
                    Nur noch ein Klick und du kannst mit deinem Kurs Ernährung
                    Foodiary loslegen.
                    <br />
                    Du bezahlst einmalig 149,00€. Sobald du den 8-Wochen-Kurs
                    abgeschlossen hast, erstattet deine Krankenkasse bis zu 100%
                    der Kosten.
                    <br />
                  </div>
                  <div id="code_block-30-75" className="ct-code-block">
                  <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(e)=>onFormSubmit(e)}
                  >
                   {({ handleSubmit,handleChange, values, errors}) => (
                    <form
                      id="register-form"
                      className="start-prevention-course-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="grid-container">
                        <div className="email-field">
                          <label className="uppercase font-medium" for="email">
                            Deine E-Mail:
                          </label>
                          <input
                            className="mt-1 input-field"
                            type="email"
                            id="email"
                            placeholder="info@foodiary.app"
                            name="email" onChange={handleChange} value={values.email}
                            required
                          />
                             <ErrorMessage error={errors.email} />
                        </div>
                        <div className="password-field">
                          <label
                            className="uppercase font-medium"
                            for="password"
                          >
                            Dein Passwort:
                          </label>
                          <input
                            className="mt-1 input-field"
                            type="password"
                            id="password"
                            placeholder="Passwort wählen"
                            name="password" onChange={handleChange} value={values.password}
                            required
                          />
                            <ErrorMessage error={errors.password} />
                        </div>
                        <div className="text-base color-paragraph-alt italic grid-column-span-2">
                          <label className="d-flex">
                            <input
                              type="checkbox"
                              name="agb_datenschutz"
                              required
                            />
                            <div>
                              Ich stimme den{" "}
                              <a href="/agb/" target="_blank">
                                AGB
                              </a>{" "}
                              von Foodiary für Präventionskurse zu und habe die{" "}
                              <a href="/datenschutz/" target="_blank">
                                Datenschutzhinweise
                              </a>{" "}
                              von Foodiary zur Kenntnis genommen.
                            </div>
                          </label>
                        </div>

                        <div className="text-base color-paragraph-alt italic grid-column-span-2">
                          <label className="d-flex">
                            <input
                              type="checkbox"
                              name="kontraindikation"
                              required
                            />
                            <div>
                              Ich bestätige, dass ich die Kontraindikationen zur
                              Kenntnis genommen habe und nicht von diesen oder
                              psychischen (Ess-)Störungen betroffen bin.
                            </div>
                          </label>
                        </div>
                        <div className="text-base color-paragraph-alt italic grid-column-span-2">
                          <label className="d-flex">
                            <input type="checkbox" name="full_age" required />
                            <div>Ich bin volljährig.</div>
                          </label>
                        </div>

                        <div className="flex gap-2 grid-column-span-2 mt-8">
                          <button
                            className="foodiary-green-button disabled-dark cursor-pointer border-none border-0"
                            type="submit"
                            id="submitButton"
                          >
                            Kurs starten
                          </button>
                          <div className="text-xs color-paragraph-alt italic flex grid-column-span-2 align-items-center">
                            Ohne Abo, ohne Kündigung
                          </div>
                        </div>
                      </div>
                    </form>
                    )}
                    </Formik>
                  </div>
                  <div id="error" className="ct-text-block">
                    <br />
                  </div>
                </div>
                <div
                  id="div_block-9-75"
                  className="ct-div-block rounded-xl shadow-md p-8 col-span-2 md-col-span-3 bg-white"
                >
                  <div
                    id="div_block-10-75"
                    className="ct-div-block w-full h-full rounded-xl bg-alt p-10"
                  >
                    <div
                      id="text_block-11-75"
                      className="ct-text-block color-primary uppercase text-left"
                    >
                      Präventionskurs
                    </div>
                    <div
                      id="text_block-12-75"
                      className="ct-text-block color-dark text-3xl font-medium mt-5"
                    >
                      149,00 €
                    </div>
                    <div
                      id="text_block-13-75"
                      className="ct-text-block font-normal text-base color-paragraph-alt mt-4"
                    >
                      Bis zu 100% Rückerstattung von deiner Krankenkasse
                      <br />
                    </div>
                    <div
                      id="div_block-14-75"
                      className="ct-div-block w-full pt-8 mt-10 gap-6"
                    >
                      {[
                        "8 Wochen Präventionskurs",
                        "Geprüfter Kursleiter für Feedback",
                        "Wissensquiz zur Unterstützung",
                        "Umfangreiches Lernmaterial",
                      ].map((item) => (
                        <div
                          id="div_block-15-75"
                          className="ct-div-block oxel_iconlist__row p-0"
                        >
                          <div id="code_block-16-75" className="ct-code-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="4.0rem"
                              height="4.0rem"
                              viewBox="0 0 40 40"
                            >
                              <g
                                id="Gruppe_8351"
                                data-name="Gruppe 8351"
                                transform="translate(-1273.893 -4983.893)"
                              >
                                <circle
                                  id="Ellipse_530"
                                  data-name="Ellipse 530"
                                  cx="20"
                                  cy="20"
                                  r="20"
                                  transform="translate(1273.893 4983.893)"
                                  fill="#fff"
                                ></circle>
                                <path
                                  id="Pfad_15296"
                                  data-name="Pfad 15296"
                                  d="M29.236,6l-12.1,12.116L13.5,14.485"
                                  transform="translate(1272.518 4991.835)"
                                  fill="none"
                                  stroke="#2ecf96"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2.5"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div
                            id="text_block-17-75"
                            className="ct-text-block foodiary-list-item text-base text-left font-normal color-paragraph ml-3"
                          >
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div id="div_block-32-75" className="ct-div-block">
                    <img
                      src="https://prevention.foodiary.app/wp-content/uploads/2023/06/Apple-Pay-Card.svg"
                      alt="Apple Pay Card"
                      width="100%"
                    />
                    <img
                      src="https://prevention.foodiary.app/wp-content/uploads/2023/06/Google_Pay_Logo.svg"
                      alt="Google Pay Card"
                      width="100%"
                    />
                    <img
                      src="https://prevention.foodiary.app/wp-content/uploads/2023/06/SEPA-Payment-Card.svg"
                      alt="SEPA"
                      width="100%"
                    />
                    <img
                      src="https://prevention.foodiary.app/wp-content/uploads/2023/06/Visa-New-2021.svg"
                      alt="VISA Pay Card"
                      width="100%"
                    />
                    <img
                      src="https://prevention.foodiary.app/wp-content/uploads/2023/06/Mastercard-New.svg"
                      alt="Mastercard Pay Card"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </SectionWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>
      <FooterNew />

      <HealthStatusModal
        isOpen={OpenHealthStatusModal}
        onClose={() => setOpenHealthStatusModal(false)}
      />
    </>
  );
};

export default OnlineKursStarten;
