import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";

import * as S from "./index.style";

const index = ({ open, close, title, children }) => {
 
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" onClose={close}>
        <S.Wrapper>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div>
              <S.Background />
            </div>
          </Transition.Child>

          <S.DWrapper>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <S.DSWrapper>
                <Dialog.Panel style={{ position: "relative" }}>
                  <S.Icon onClick={close}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "25px", height: "25px" }}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
       
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </S.Icon>

                  {children}
                </Dialog.Panel>
              </S.DSWrapper>
            </Transition.Child>
          </S.DWrapper>
        </S.Wrapper>
      </Dialog>
    </Transition>
  );
};

export default index;
