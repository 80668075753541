import styled from "styled-components";

export const CoachingWithMeContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`;

export const ContentSection = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 25px;
  @media (max-width: 767.99px) {
    /* font-size: 38px; */
  }
  @media (max-width: 600px) {
    /* font-size: 28px; */
  }
`;

export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  /* font-size: 1rem;
  font-weight:400; */
  margin-bottom: 60px;
`;

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-left: auto;
    display: block;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`;

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
