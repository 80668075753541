import styled from 'styled-components';
import { LoginContainer, LoginHeading, LoginInnerContainer, LoginSubHeading } from 'pages/login/login.styles';

export const ChangePasswordContainer = styled(LoginContainer)``;

export const ChangePasswordInnerContainer = styled(LoginInnerContainer)``;

export const ChangePasswordHeading = styled(LoginHeading)``;

export const ChangePasswordSubHeading = styled(LoginSubHeading)``;

