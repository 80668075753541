import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  @media (max-width: 991.99px) {
    order: 2;
  }
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.2rem;
  font-weight: 500; */
  margin-bottom: 50px;
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
    height: auto;
    max-width: 600px;
    /* max-height: 550px; */
    display: block;
    margin-left: auto;
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 991.99px) {
    height: auto;
    order: 1;
    img {
      margin-inline: auto;
    }
  }

  @media (max-width: 767.99px) {
    display: none;
  }
`
