import { takeLatest, put, all, call } from 'redux-saga/effects';
import Tap from "@tapfiliate/tapfiliate-js";
import Swal from 'sweetalert2';

import ApiCollections from 'configs/services/apiCollections'

import SupplementsActionTypes from 'redux/supplements/supplements.types';
import * as AuthActions from 'redux/auth/auth.actions';
import * as SupplementsActions from 'redux/supplements/supplements.actions';

/*************************************************/

export function* getSupplements() {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getSupplements);
    yield put(SupplementsActions.getSupplementsSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(SupplementsActions.getSupplementsFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* deleteMailChimpUserDetails({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.deleteMailChimpUserDetails, payload.formValues);
    yield put(SupplementsActions.deleteMailChimpUserSuccess(response.data));
    yield put(AuthActions.loadingStop());
    yield put(AuthActions.signOutRequest());
    yield put(payload.func());
  } catch (error) {
    yield put(SupplementsActions.deleteMailChimpUserFailure(error));
    yield put(AuthActions.loadingStop());
    yield put(AuthActions.signOutRequest());
    yield put(payload.func());
  }
}

/*************************************************/

export function* addBasicUserMainChimpData({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.addBasicUserDataMailChimp, payload);
    yield put(SupplementsActions.addBasicMailChimpUserDataSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(SupplementsActions.addBasicMailChimpUserDataFailure(error));
    yield put(AuthActions.loadingStop());
    yield put(payload.func());
  }
}

/*************************************************/

export function* addProUserMainChimpData({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.addProUserDataMailChimp, payload);
    yield put(SupplementsActions.addProMailChimpUserDataSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(SupplementsActions.addProMailChimpUserDataFailure(error));
    yield put(AuthActions.loadingStop());
    yield put(payload.func());
  }
}

/*************************************************/

export function* updateUserSubscription({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.updateUserSubscription, payload.formValues);
    yield put(SupplementsActions.updateUserSubscriptionSuccess(response.data));
    if(payload.affiliateData) {
      Tap.init(process.env.REACT_APP_TAPFILIATE_ID);
      Tap.conversion(payload.affiliateData.userID, payload.affiliateData.conversionAmount, {customer_id: payload.affiliateData.email });
    }
    yield put(AuthActions.loadingStop());
    yield put(payload.func());
  } catch (error) {
    yield put(SupplementsActions.updateUserSubscriptionFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* initiateStripePayment({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.updateUserSubscription, payload.formValues);
    yield put(SupplementsActions.initiateStripePaymentSuccess(response.data));
    if(payload.affiliateData) {
      Tap.init(process.env.REACT_APP_TAPFILIATE_ID);
      Tap.conversion(payload.affiliateData.userID, payload.affiliateData.conversionAmount, {customer_id: payload.affiliateData.email });
    }
    Swal.fire({ icon: "success", title: "Erledigt", text: response.message, timer: 3000 });
    yield put(AuthActions.loadingStop());
    yield put(payload.func());
  } catch (error) {
    yield put(SupplementsActions.initiateStripePaymentFailure(error));
    Swal.fire({ icon: "error", title: "Hinweis", text: error.response.data.message, timer: 3000 });
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* supplementsSagas() {
  yield all([
    yield takeLatest(SupplementsActionTypes.GET_SUPPLEMENTS_REQUEST, getSupplements),
    yield takeLatest(SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_REQUEST, deleteMailChimpUserDetails),
    yield takeLatest(SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST, addBasicUserMainChimpData),
    yield takeLatest(SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST, addProUserMainChimpData),
    yield takeLatest(SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_REQUEST, deleteMailChimpUserDetails),
    yield takeLatest(SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_REQUEST, updateUserSubscription),
    yield takeLatest(SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_REQUEST, initiateStripePayment),
  ]);
}

/*************************************************/