import React from "react";
import Chip from "components/general/formComponents/chip/Chip";

import * as C from "./yoyoEffect.styled";
// Images
import YoyoEffectPng from "assets/images/Page3/Foodiary-Coach-Support-v3.jpg";

const YoyoEffect = () => {
  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="LANGFRISTIGKEIT" margin="0 0 3rem 0" />
        <C.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Der Jojo-Effekt hat keine Chance!
        </C.Title>
        <C.Description className="ct-text-block color-paragraph text-base text-left">
          Während deinem 3-monatigen Coaching werde ich dich persönliche
          Betreuuen und gemeinsam erreichen wir dein gesetztes Ziel. In
          regelmäßigen Erfolgsüberprüfungen werden wir deine Veränderung
          analysieren und deine Ernährung step by step anpassen.
          <br />
          <br />
          Zudem lernst du auch deine Ernährung dauerhaft umzustellen. In der
          Foodiary Academy bringen wir dir alles über die Grundlagen der
          gesunden Ernährung bei, sodass du nach meinem Coaching nicht nur dein
          Ziel erreicht hast, sondern selbst ein Experte im Bereich der
          Ernährung bist.
          <br />
          <br />
          So kannst du eigenständig deine zukünftige Ernährung planen und fällst
          nicht auf die fiesen Tricks der Lebensmittelindustrie rein.
        </C.Description>
      </C.TextContent>
      <C.VisualContent>
        <img src={YoyoEffectPng} alt="graph" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default YoyoEffect;
