import NutritionActionTypes from './nutrition.types';

/*************************************************/

const INITIAL_STATE  = {
    userData: null,
    nutritionTypes: null,
    activeNutritionType: null,
    nutritionAdvantages: null,
    nutritionRecipes: null,
    caloriesLineChartData: null,
    macroNutrientsDonutChartData: null
}

/*************************************************/

const nutritionReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case NutritionActionTypes.GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.payload[0]
            };
        case NutritionActionTypes.GET_ALL_NUTRITION_TYPES_SUCCESS:
            return {
                ...state,
                nutritionTypes: action.payload
            };
        case NutritionActionTypes.GET_NUTRITION_TYPE_SUCCESS:
            return {
                ...state,
                activeNutritionType: action.payload,
            };
        case NutritionActionTypes.GET_NUTRITION_ADVANTAGES_SUCCESS:
            return {
                ...state,
                nutritionAdvantages: action.payload,
            };
        case NutritionActionTypes.GET_NUTRITION_RECIPES_SUCCESS:
            return {
                ...state,
                nutritionRecipes: action.payload,
            };
        case NutritionActionTypes.GET_WEEK_WISE_CALORIES_SUCCESS:
            return {
                ...state,
                caloriesLineChartData: action.payload,
            };
        case NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_SUCCESS:
            return {
                ...state,
                macroNutrientsDonutChartData: action.payload,
            };
        default: return state;
    }
}

/*************************************************/

export default nutritionReducer;

/*************************************************/
