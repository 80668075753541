import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./payment.styles";
import TranslationMessage from "configs/translations/TranslationMessage";
import Button from "components/formComponents/button/Button";
import { useHistory } from "react-router-dom";
import Topbar from "components/topbar/Topbar";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import CheckoutForm from "components/CheckoutForm/CheckoutForm";
import Spiner from "components/Spiner/Spiner";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const BASE_URL = "https://app.foodiary.app/api/";
const Payment = () => {
  const [error, setError] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const registrationData = useSelector((state) => state.auth.registrationData);
  console.log("regisration Data", registrationData);
  const advantagesData = useSelector(
    (state) => state.nutrition.nutritionAdvantages
  );
  const [clientSecret, setClientSecret] = useState(null);
  const [userStripePayment, setUserStripePayment] = useState(null);
  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    document.body.classList.add("app-register");
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      document.body.classList.remove("app-register");
    };
  });

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleNext = () => {
    history.push("/nutrition/creation");
  };

  const handlePrev = () => {
    history.push("/nutrition/macroNutrients");
  };

  const onFieldError = (e) => {
    if (e.error) {
      setError(e.error.message);
    }
    if (e.complete) {
      setError(null);
    }
    if (e.empty) {
      setError("Empty form");
    }
  };
  useEffect(() => {
    const load = async () => {
      const { surname, email, userID, trainerID } = registrationData;
      const customer_res = await axios.post(
        BASE_URL + "v1/stripe/customer/create",
        {
          email,
          name: surname,
        }
      );

      let payload = {};
      payload.customer_id = customer_res.data.data.id;
      payload.price_id = window.localStorage.getItem("price_id");
      payload.userID = userID;
      payload.trainerID = trainerID;
      const coupon = window.localStorage.getItem("voucher");
      if (coupon) {
        payload.coupon = coupon;
      }
      const {
        data: { clientSecret, userStripePayment, subscription },
      } = await axios.post(BASE_URL + "v1/stripe/subscription/create", payload);
      setClientSecret(clientSecret);
      setUserStripePayment(userStripePayment);
      setSubscription(subscription);
    };
    load();
  }, []);

  useEffect(() => {
    dispatch(
      NutritionActions.getNutritionAdvantagesRequest({
        nutritiontypeID: registrationData.nutritiontypeID,
        aimID: registrationData.aimID,
        sex: registrationData.sex,
      })
    );
  }, [dispatch, registrationData]);

  const advantages = [
    {
      key: "advantages1",
      id: "1",
      advantage: advantagesData && advantagesData["01_advantage"],
    },
    {
      key: "advantages2",
      id: "2",
      advantage: advantagesData && advantagesData["02_advantage"],
    },
    {
      key: "advantages3",
      id: "3",
      advantage: advantagesData && advantagesData["03_advantage"],
    },
  ];

  const stripePromise = loadStripe(
    "pk_live_51Lwma4A7QE5sDrDRdxRi9Nn8iCLQ1Pg2v8jHJs7Eoh9XPGGFvWhAIp906WQDGbjQZXHtriAltVVaHSshGRnWk7eF00jO75eGuh"
    //"pk_test_51Lwma4A7QE5sDrDRDgxAzBIcDlEEencYAHz4DVeWLzLpQUAJWy5wBRHumL7Vv3j8PuCE9otm6o9h09WXTzZFYhX800zw0XqwvU"
  );
  return (
    <div>
      {!isMobile && <Topbar progress="100%" />}

      <S.NutritionFormAdvantagesContainer>
        <S.NutritionFormAdvantagesCardContainer>
          <S.NutritionFormAdvantagesCard>
            <S.NutritionFormAdvantagesCardInnerContainer>
              <S.NutritionFormAdvantageData>
                <S.PaymentHeading>
                  <TranslationMessage id="payment.heading" />
                </S.PaymentHeading>
                <S.PaymentSubHeading>
                  <TranslationMessage id="payment.subHeading" />
                </S.PaymentSubHeading>
                {clientSecret ? (
                  <Elements
                    stripe={stripePromise}
                    options={{ clientSecret, loader: "always" }}
                  >
                    <CheckoutForm
                      clientSecret={clientSecret}
                      userStripePayment={userStripePayment}
                      subscription={subscription}
                    />
                  </Elements>
                ) : (
                  <S.SpinerContainer>
                    <Spiner></Spiner>
                  </S.SpinerContainer>
                )}
              </S.NutritionFormAdvantageData>
            </S.NutritionFormAdvantagesCardInnerContainer>
          </S.NutritionFormAdvantagesCard>
        </S.NutritionFormAdvantagesCardContainer>
      </S.NutritionFormAdvantagesContainer>

      {/* {isMobile && (
        <S.NextButtonContainer>
          <Button
            onClick={() => handleNext()}
            color="#fff"
            style={{
              width: 95 + "%",
              borderRadius: "6px",
              background:
                "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <TranslationMessage id="button.prev" />
          </Button>
        </S.NextButtonContainer>
      )} */}
    </div>
  );
};

export default Payment;
