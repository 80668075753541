import styled from "styled-components";

export const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};

  @media screen and (max-width: 600px) {
    margin: ${(props) => props.mobileMargin};
    padding: ${(props) => props.mobilePadding};
  }
`;
