import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  align-items: center;
  justify-content: center;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }
  }
`;

export const TextContent = styled.div``;

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 35px;
  @media (max-width: 767.99px) {
    /* font-size: 35px; */
  }
`;
export const Description = styled.p`
  color: #44554c;
`;

export const InsuranceCompanies = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  .company-item {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 10px 20px #02332a1a;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 16rem;
      object-fit: cover;
    }

    @media screen and (max-width: 600px) {
      height: 22rem;
      &:last-child {
        display: none;
      }
    }
  }
`;

// export const VisualContent = styled.div`
//   height: 600px;

//   img {
//     width: 100%;
//     height: 100%;
//   }

//   @media (max-width: 767.99px) {
//     height: 400px;
//   }
// `;

export const VisualContent = styled.div`
  position: relative;
  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-left: auto;
    display: block;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`;

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
