import styled from "styled-components"

export const CoachingAccordianContainer = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 100px;
  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 0px 15px;
  }
`

export const CoachingAccordianLeft = styled.div``

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 2.3rem;
  font-weight: 500; */
  margin-bottom: 50px;
`

export const CoachingAccordianRight = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`

export const Info = styled.p`
  color: #142c1f;
  /* font-size: 1rem;
  font-weight: 400; */
  margin-bottom: 40px;
`
