import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as S from "./changePassword.styles";
import { useDispatch, useSelector } from "react-redux";

// Components
import Topbar from "components/topbar/Topbar";
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import TranslationMessage from "configs/translations/TranslationMessage";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import { useState } from "react";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const [isMobile, setIsMobile] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const changePasswordRedirection = () =>
    window.location.assign("/nutrition/nutritionForm");
  const initialValues = {
    email: localStorage.getItem("userEmail"),
    password: "",
    confirmPassword: "",
  };

  const handleResize = () => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    document.body.classList.add("app-register");
    const email = localStorage.getItem("userEmail");

    console.log("email", email);
    if (email) {
      setEmail(email);
    }
    handleResize();
    return () => {
      document.body.classList.remove("app-register");
    };
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    password: Yup.string().required("Required").min(8, "Atleast 8 characters!"),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkValidation = () => {
    const emailCheck = validateEmail(email);
    if (emailCheck && password === repeatPassword && password.length > 7) {
      return false;
    }
    return true;
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        return;
      case "password":
        setPassword(e.target.value);
        return;
      case "confirmPassword":
        setRepeatPassword(e.target.value);
        return;
    }
  };

  const onStartClick = () => {
    dispatch(
      AuthActions.changePasswordRequest({
        formValues: {
          email: email,
          password: password,
          confirm_password: repeatPassword,
        },
        func: changePasswordRedirection,
      })
    );
  };

  const onFormSubmit = (values, { resetForm }) => {
    dispatch(
      AuthActions.changePasswordRequest({
        formValues: {
          email: values.email,
          password: values.password,
          confirm_password: values.confirmPassword,
        },
        func: changePasswordRedirection,
      })
    );
    resetForm();
  };

  return (
    <>
      {!isMobile && (
        <Topbar
          next="/nutrition/changePassword"
          prev="/nutrition/creation"
          disabled={checkValidation()}
          onStartClick={onStartClick}
          progress="100%"
          nextButtonValue="Starten"
        />
      )}
      <S.ChangePasswordContainer>
        <Card
          width={isMobile ? "95%" : "700px"}
          padding="20px"
          height="auto"
          margin="auto"
          textAlign="center"
        >
          <S.ChangePasswordInnerContainer>
            <S.ChangePasswordHeading>
              <TranslationMessage id="changePassword.heading" />{" "}
            </S.ChangePasswordHeading>
            <S.ChangePasswordSubHeading>
              <TranslationMessage id="changePassword.subHeading" />
            </S.ChangePasswordSubHeading>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
              }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Input
                    filled
                    type="text"
                    placeholder="Deine E-Mail Adresse"
                    name="email"
                    //value={email}
                    onChange={(e) => {
                      onChange(e);
                      handleChange(e);
                    }}
                    value={values.email}
                  />
                  <ErrorMessage error={errors.email} />
                  <Input
                    filled
                    type="password"
                    placeholder="Passwort"
                    name="password"
                    onChange={(e) => {
                      onChange(e);
                      handleChange(e);
                    }}
                    value={values.password}
                  />
                  <ErrorMessage error={errors.password} />
                  <Input
                    filled
                    type="password"
                    placeholder="Passwort bestätigen"
                    name="confirmPassword"
                    onChange={(e) => {
                      onChange(e);
                      handleChange(e);
                    }}
                    value={values.confirmPassword}
                  />
                  <ErrorMessage error={errors.confirmPassword} />
                  {isMobile && (
                    <Button
                      color="rgba(248, 164, 146, 1)"
                      margin="20px 0 0 0"
                      type="submit"
                    >
                      <TranslationMessage id="button.changePassword" />
                    </Button>
                  )}
                  {/* <Loader loading={loading} top="-135px" /> */}
                </form>
              )}
            </Formik>
          </S.ChangePasswordInnerContainer>
        </Card>
      </S.ChangePasswordContainer>
    </>
  );
};

export default ChangePassword;
