import styled from "styled-components";
import { LoginSubHeading } from "pages/login/login.styles";

export const InformationHeading = styled(LoginSubHeading)`
  padding-top: 45px;
`;

export const InformationDescription = styled.div`
  font-size: 16px;
  font-family: "Rubik-Regular";
  color: #44554c;
  margin: 0 0 40px 0;
`;

export const Header = styled.header`
  background-color: #f0f4f3;
`;

export const HeaderInner = styled.div`
  padding-top: 3.4rem;
`;
