import { useSelector } from "react-redux";
import moment from 'moment';
import { FILE_BASE_URL, FILE_BASE_URL_S3 } from 'configs/services/apiCollections';

export const GetTrainerToken = () => {
    const Authorization = useSelector(state => state.auth.trainer_token);
    if (Authorization.length > 0) return Authorization
    else return false
}

export const GetUserToken = () => {
    const Authorization = useSelector(state => state.auth.token);
    if (Authorization.length > 0) return Authorization
    else return false
}

export const getImageUrl = (imagePath, image, imageType = null) => {
    if (!imageType) return FILE_BASE_URL() + imagePath + "/" + image
    return FILE_BASE_URL() + imagePath + "/" + image + '.' + imageType
}

export const getS3ImageUrl = (image, type = null ) => {
    if (!type) return FILE_BASE_URL_S3() + image
    return FILE_BASE_URL_S3() + image + '.' + type
}

export const findAge = birthday => {
    const a = moment();
    const b = moment(birthday);
    if (!birthday) return 0;
    return moment.duration(a.diff(b)).years();
}

export const getPercent = (position) => {
    if (position === 0) return 60
    else if (position === 1) return 75
    else if (position === 2) return 100
    else return 60
}

export const getTargetValue = (position) => {
    if (position === 0) return "1,50 €";
    else if (position === 1) return "4,50 €";
    else if (position === 2) return "8,00 €";
    else return "0,00 €";
}

export const getRandomValue = (endValue) => Math.floor((Math.random() * endValue) + 1);

export const getRandomValueInRange = (startValue, endValue) => Math.floor(Math.random() * (startValue - endValue + 1) + endValue);

export const isFirefox = typeof InstallTrigger !== 'undefined';

export const formatToGermanCurrency = (number) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);

export const dashboardNavTabs = [
    {
        id: "dashboard",
        value: "Dashboard",
        route: '/main/dashboard/home'
    },
    {
        id: "mainCustomer",
        value: "Meine Kunden",
        route: '/main/dashboard/customers'
    },
    {
        id: "calendar",
        value: "Kalender",
        redirect: 'https://www.calendly.com'
    },
];