import styled from "styled-components";
import { LoginHeading, LoginSubHeading } from "pages/login/login.styles";
import { DashboardContainer } from "pages/dashboard/dashboard.styles";

export const RegistrationContainer = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 60px;
`;

export const RegistrationHeading = styled(LoginHeading)``;

// export const NextButtonContainer = styled(ButtonContainer)``;

// export const RegistrationMobileHeading = styled(NutritionFormAdvantagesMobileHeading)``;

// export const RegistrationMobileSubHeading = styled(NutritionFormAdvantagesMobileSubHeading)`
//     margin-top:30px;
// `;

// export const RegistrationMobileImageContainer = styled(NutritionFormAdvantagesImageContainer)``;

// export const RegistrationMobileHeadingContainer = styled(NutritionFormAdvantagesHeadingContainer)``;

export const RegistrationMobileHeading = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #142c1f;
`;

export const RegistrationMobileSubHeading = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: normal;
  color: #44554c;
  margin-bottom: 20px;
`;

export const RegistrationMobileImageContainer = styled.div`
  position: absolute;
  left: 10px;
`;

export const RegistrationMobileHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const NextButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 25px;
`;

export const RegistrationSubHeading = styled(LoginSubHeading)`
  font-size: 22px;
  font-weight: 600;
`;

export const TrainingFixedHeading = styled.div`
  font-family: RubikMedium;
  font-weight: 16px;
  color: #142c1f;
`;

// Gender Screen
export const GenderCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const GenderCard = styled.div`
  width: 172px;
  height: 138px;
  background-color: white;
  border-radius: 10px;
  margin-left: ${(props) => (props.marginLeft ? "10px" : "0")};
  cursor: pointer;
  border: ${(props) =>
    props.selected ? "2px solid #1C9D87" : "2px solid white"};

  :hover {
    border: 2px solid #1c9d87;
  }
`;

export const GenderCardImage = styled.img`
  height: 33px;
  margin: 33px auto 20px;
`;

export const GenderCardHeading = styled(RegistrationSubHeading)`
  font-size: 16px;
`;

// Goal Screens
export const GoalCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const GoalCard = styled.div`
  width: 355px;
  height: 84px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0px auto 10px;
  position: relative;
  border: ${(props) =>
    props.selected ? "2px solid #1C9D87" : "2px solid white"};

  :hover {
    border: 2px solid #1c9d87;
  }
`;

export const GoalCardImage = styled.img`
  height: 30px;
  margin: auto 23px;
`;

export const GoalCardHeading = styled(RegistrationSubHeading)`
  font-size: 16px;
  padding: ${(props) => (props.withSubtext ? "23px 0 0 0" : "32px 0px")};
`;

export const GoalCardSubHeading = styled.div`
  font-family: RubikLight;
  font-size: 12px;
  color: #44554c;
  position: absolute;
  bottom: 23px;
  left: 70px;
`;

// Body Details
export const BodyDetailsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const BodyDetailsCard = styled.div`
  width: 355px;
  height: 128px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0px auto 10px;
  text-align: center;
`;

export const BodyDetailsCardHeading = styled(RegistrationSubHeading)`
  font-size: 16px;
  padding: 25px 0 0 0;
  margin-bottom: 20px;
`;

// Daily Routine
export const DailyRoutineCardContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const DailyRoutineDescriptionCard = styled.div`
  width: 90%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0px auto 10px;
  text-align: center;
  padding: 10px 10px 25px 10px;
`;

export const DailyRoutineDescription = styled.div`
  font-size: 14px;
  text-align: left;
  color: #44554c;
  margin: 15px 15px 0px 15px;
`;

export const AddNewDailyRoutineButtonContainer = styled.div`
  margin-top: 20px;
`;

// Add Daily Routine Popup
export const DailyRoutineInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`;

export const DailyRoutineInputLabel = styled.div`
  line-height: 35px;
`;

export const DailyRoutineAddButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// Activity Level
export const ActivityLevelContainer = styled.div`
  width: 750px;
  margin: 20px auto 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 86%;
    height: 100vh;
  }
`;

export const ActivityLevelCardContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 10px 28px;
  width: 299px;
  line-height: 36px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    width: 90%;
    padding: 10px;
  }
`;

export const ActivityLevelTotalContainer = styled.div`
  margin: 40px auto 0;
  padding: 10px 28px;
  height: 36px;
  width: 299px;
  line-height: 36px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    margin-top: 2px;
    width: 92%;
    justify-content: space-between;
  }
`;

export const ActivityLevelTotalHeading = styled.span`
  font-family: RubikRegular;
  font-size: 16px;
  color: #44554c;
`;

export const ActivityLevelTotal = styled.span`
  width: 80;
  margin: 0 15px;
`;

export const ActivityLevelTotalDescription = styled.span`
  font-family: RubikRegular;
  font-size: 12px;
  color: #a5b5af;
`;

// Training Fixed
export const TrainingFixedSwitchContainer = styled.span`
  margin: 21px auto 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const TrainingFixedSwitchHeading = styled.span`
  font-family: RubikRegular;
  font-size: 16px;
  color: #44554c;
  margin-right: 35px;
  line-height: 24px;
`;

export const TrainingFixedContainer = styled(ActivityLevelContainer)``;

export const TrainingFixedCardContainer = styled(ActivityLevelCardContainer)`
  justify-content: flex-start;
  @media (max-width: 600px) {
    padding-left: 30px;
    padding-right: 0px;
  }
`;

// Training Flexible
export const TrainingFlexibleCardContainer = styled(BodyDetailsCardContainer)``;

export const TrainingFlexibleCard = styled(BodyDetailsCard)`
  height: 144px;
`;

export const TrainingFlexibleCardHeading = styled(BodyDetailsCardHeading)`
  padding-top: 33px;
`;

// Eating Habit 1 and 2
export const EatingHabitsContainer = styled(BodyDetailsCardContainer)`
  margin: 20px auto 100px;
`;

export const EatingHabitsCardContainer = styled.div`
  width: 355px;
  height: 131px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const EatingHabitsImageContainer = styled.img`
  height: 131px;
  border-radius: 8px;
`;

export const EatingHabitsCardInnerContainer = styled.div`
  width: 233px;
  height: 111px;
  margin: 10px 0;
  background-color: white;
  color: black;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  text-align: left;
`;

export const EatingHabitsInnerCardHeading = styled.div`
  margin: auto 20px;
  width: 140px;
`;

export const EatingHabitsRadioContainer = styled.div`
  margin: 27px 25px 43px 0;
`;

// Personal Data
export const PersonalDataContainer = styled.div`
  background-color: white;
  width: 686px;
  min-height: 330px;
  margin: 0 auto 100px;
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 95%;
    padding-top: 25px;
  }
`;

export const PersonalDataInnerContainer = styled.div`
  margin: 45px 50px;
  @media (max-width: 600px) {
    margin: 10px 20px;
    width: 90%;
  }
`;

export const PersonalDataInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const PersonalDataRulesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: ${(props) => (props.isShortTerm ? "80px" : "140px")};
  margin-top: 40px;
  text-align: left;
  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

export const PersonalDataMobileRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const PersonalDataRulesLabel = styled.label`
  width: 553px;
  margin-bottom: 30px;
  margin-left: 3px;
  color: #44554c;
  font-family: RubikRegular;
  font-size: 16px;
  > a {
    text-decoration: none;
    color: #1c9d87;
  }
`;

export const PersonalDataCancelButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PersonalDataCancelButton = styled.div`
  margin-top: 13px;
  margin-bottom: 30px;
  color: #44554c;
  font-family: RubikRegular;
  font-size: 13px;
  cursor: pointer;
  opacity: 0.5;
`;

export const DesktopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 140px;
  margin-top: -10px;
  justify-content: center;
  text-align: center;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ButtonInnerContainer = styled.div`
  min-width: 140px;
  margin-top: -2px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
`;

export const ButtonImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const MobileLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const MobileLogo = styled.img`
  width: 120px;
  height: 55px;
`;

export const DividerLine = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 72px;
  height: 5px;
  background-color: #a5b5af;
  opacity: 0.5;
  border-radius: 2px;
`;

export const MobileProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 20px 0;
`;

export const Error = styled.div`
  margin-top: 0.75rem;
  color: #ef4444;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
`;
