import AuthActionTypes from "redux/auth/auth.types";

/*************************************************/

const INITIAL_STATE = {
  token: localStorage.getItem("token"),
  trainer_token: localStorage.getItem("trainer_token"),
  error: "",
  registrationData: {
    sex: "male",
    aimID: 1,
    height: "0",
    weight: "0",
    nutritiontypeID: 2,
    birthday: "",
    email: "",
    prename: "",
    surname: "",
    grant_type: "password",
    isTraining: true,
    trainingdays: [],
    totalTrainingDays: 0,
    routines: {},
    dailyRoutineData: [],
    movementtypes: [],
    mobile: "",
    sleepValue: 60,
    lyingDownValue: 60,
    standingValue: 60,
    sittingValue: 60,
    walkingValue: 60,
    exercisingValue: 60,
    eatingHabit1: "registration.eatingHabit.yoghurtWithFruit",
    eatingHabit2: "registration.eatingHabit.apple",
    eatingHabit3: "registration.eatingHabit.Avocado",
    eatingHabit4: "registration.eatingHabit.Oatmeal",
    eatingHabit5: "registration.eatingHabit.chilliConCarne",
  },
  loading: false,
  trainerSignUpData: {},
  trainerData: {},
  healthScoreData: {
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
    button10: false,
    sliderPosition: null,
    supplementsStatus: [],
    foodiaryPro: false,
    payment_type: "permanent",
    totalSupplementsCost: 0.0,
  },
};

/*************************************************/

const userReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case AuthActionTypes.SET_REGISTRATION_DATA:
      console.log("action.payload", action.payload);
      return {
        ...state,
        registrationData: { ...state.registrationData, ...action.payload },
      };
    case AuthActionTypes.DELETE_REGISTRATION_DATA:
      console.log("action.payload", action.payload);
      return {
        ...state,
        registrationData: {},
      };
    case AuthActionTypes.SET_HEALTH_SCORE_DATA:
      return {
        ...state,
        healthScoreData: { ...state.healthScoreData, ...action.payload },
      };
    case AuthActionTypes.REGISTRATION_PART1_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        userID: action.payload.userID,
        error: "",
      };
    case AuthActionTypes.REGISTRATION_PART1_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case AuthActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        trainer_token: action.payload,
      };
    case AuthActionTypes.SIGN_IN_EXISTING_PLAN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case AuthActionTypes.TRAINER_SIGN_UP_SUCCESS:
      return {
        ...state,
        trainer_token: action.payload,
      };
    case AuthActionTypes.TRAINER_DETAILS_EDIT_SUCCESS:
      return {
        ...state,
        trainerData: { ...state.trainerData, ...action.payload },
      };
    case AuthActionTypes.GET_TRAINER_PROFILE_SUCCESS:
      return {
        ...state,
        trainerData: action.payload,
      };
    case AuthActionTypes.SIGN_OUT_SUCCESS:
      return {
        trainer_token: null,
        token: null,
        ...INITIAL_STATE,
      };
    case AuthActionTypes.LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

/*************************************************/

export default userReducer;

/*************************************************/
