
import styled from 'styled-components'

export const Spiner = styled.div`
  border: 10px solid #21AB8E;
  border-top: 10px white solid;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  animation: spin 2s linear infinite;
  /* 
  border: 10px solid #21AB8E; 
  border-top: 10px solid #21AB8E;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite; */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
