import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./clientSuccessful.styles";
import TranslationMessage from "configs/translations/TranslationMessage";
import Button from "components/formComponents/button/Button";
import AppStoreBadge from "assets/images/AppStore-Badge.svg";
import GooglePlayBadge from "assets/images/google-play-badge.svg";

import { useHistory } from "react-router-dom";

// Components
import Topbar from "components/topbar/Topbar";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

const FormAdvantages = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const advantagesData = useSelector(
    (state) => state.nutrition.nutritionAdvantages
  );

  useEffect(() => {
    document.body.classList.add("app-register");
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      document.body.classList.remove("app-register");
    };
  });

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleNext = () => {
    history.push("/select-coach");
  };

  const handlePrev = () => {
    history.push("/nutrition/macroNutrients");
  };

  const handleRedirect = (value) => {
    if (value === 1) {
      window.location.href =
        "https://apps.apple.com/de/app/foodiary-food-meal-planner/id1502395764";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.foodiary.nutrition";
    }
  };

  useEffect(() => {
    dispatch(
      NutritionActions.getNutritionAdvantagesRequest({
        nutritiontypeID: registrationData.nutritiontypeID,
        aimID: registrationData.aimID,
        sex: registrationData.sex,
      })
    );
  }, [dispatch, registrationData]);

  const advantages = [
    {
      key: "advantages1",
      id: "1",
      advantage: advantagesData && advantagesData["01_advantage"],
    },
    {
      key: "advantages2",
      id: "2",
      advantage: advantagesData && advantagesData["02_advantage"],
    },
    {
      key: "advantages3",
      id: "3",
      advantage: advantagesData && advantagesData["03_advantage"],
    },
  ];

  return (
    <div>
      {!isMobile && (
        <Topbar
          next="/client-successful"
          nextButtonStyle={{ width: 230, fontSize: 16, fontWeight: 600 }}
          nextButtonValue="zur Foodiary Startseite"
          progress="100%"
        />
      )}
      <S.NutritionFormAdvantagesContainer>
        <S.NutritionFormAdvantagesHeading>
          <TranslationMessage id="successful.heading" />
        </S.NutritionFormAdvantagesHeading>
        <S.NutritionFormAdvantagesSubHeading>
          <TranslationMessage id="successful.subHeading" />
        </S.NutritionFormAdvantagesSubHeading>
        <S.NutritionFormAdvantagesCardContainer>
          <S.NutritionFormAdvantagesCard>
            <S.NutritionFormAdvantagesCardInnerContainer>
              <S.NutritionFormAdvantageData>
                <S.CardHeading>
                  <TranslationMessage id="successful.app.heading" />
                </S.CardHeading>
                <S.CardSubHeading>
                  <TranslationMessage id="successful.app.subHeading" />
                </S.CardSubHeading>
                <S.ButtonContainer>
                  <img
                    src={AppStoreBadge}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRedirect(1)}
                    alt="IconBackground"
                  />
                  <img
                    src={GooglePlayBadge}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRedirect(2)}
                    alt="IconBackground"
                  />
                </S.ButtonContainer>
              </S.NutritionFormAdvantageData>
            </S.NutritionFormAdvantagesCardInnerContainer>
          </S.NutritionFormAdvantagesCard>
          <S.NutritionFormAdvantagesCard>
            <S.NutritionFormAdvantagesCardInnerContainer>
              <S.NutritionFormAdvantageData>
                <S.CardHeading>
                  <TranslationMessage id="successful.foodiary.coach" />
                </S.CardHeading>
                <S.CardSubHeading>
                  <TranslationMessage id="successful.foodiary.text" />
                </S.CardSubHeading>
                <S.ButtonContainer>
                  <Button
                    onClick={() => handleNext()}
                    color="#fff"
                    style={{
                      fontWeight: 600,
                      fontSize: 16,
                      marginLeft: -145,
                      width: 206,
                      background:
                        "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    <TranslationMessage id="successful.button.text" />
                  </Button>
                </S.ButtonContainer>
              </S.NutritionFormAdvantageData>
            </S.NutritionFormAdvantagesCardInnerContainer>
          </S.NutritionFormAdvantagesCard>
        </S.NutritionFormAdvantagesCardContainer>
      </S.NutritionFormAdvantagesContainer>
    </div>
  );
};

export default FormAdvantages;
