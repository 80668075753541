import React from "react";
import * as C from "./InsuranceCompanies.styled";

// Images
import FoodIconOne from "assets/images/App/techniker-krankenkasse-logo.svg";
import FoodIconTwo from "assets/images/App/aok.svg";
import FoodIconThree from "assets/images/App/DAK-Gesundheit_logo.svg";
import FoodIconFour from "assets/images/App/barmer_krankenkasse_logo.svg";
import FoodIconFive from "assets/images/App/ikk-gesundplus.svg";

const InsuranceCompanies = () => {
  return (
    <C.MealPlanContainer>
      <p className="left-title">Unterstützt:</p>
      <C.RestaurauntsBox>
        <div className="restaurant hover-float">
          <img src={FoodIconOne} alt="" />
        </div>
        <div className="restaurant hover-float">
          <img src={FoodIconTwo} alt="" />
        </div>
        <div className="restaurant hover-float">
          <img src={FoodIconThree} alt="" />
        </div>
        <div className="restaurant hover-float">
          <img src={FoodIconFour} alt="" />
        </div>
        <div className="restaurant hover-float">
          <img src={FoodIconFive} alt="" />
        </div>
      </C.RestaurauntsBox>
    </C.MealPlanContainer>
  );
};

export default InsuranceCompanies;
