import CoachActionTypes from 'redux/coach/coach.types';

// Trainer ID
export const trainerIdRequest = payload => ({
  type: CoachActionTypes.TRAINER_ID_REQUEST,
  payload
});

export const trainerIdSuccess = payload => ({
  type: CoachActionTypes.TRAINER_ID_SUCCESS,
  payload
});

export const trainerIdFailure = error => ({
  type: CoachActionTypes.TRAINER_ID_FAILURE,
  payload: error
});

// Coach Data
export const coachDataRequest = payload => ({
  type: CoachActionTypes.COACH_DATA_REQUEST,
  payload
});

export const coachDataSuccess = payload => ({
  type: CoachActionTypes.COACH_DATA_SUCCESS,
  payload
});

export const coachDataFailure = error => ({
  type: CoachActionTypes.COACH_DATA_FAILURE,
  payload: error
});

  // Loading
  export const loadingStart = () => ({
    type: CoachActionTypes.LOADING_START,
  });

  export const loadingStop = () => ({
    type: CoachActionTypes.LOADING_STOP,
  });
