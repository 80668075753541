import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./formAdvantages.styles";
import TranslationMessage from "configs/translations/TranslationMessage";
import Button from "components/formComponents/button/Button";
import { useHistory } from "react-router-dom";
import LeftIcon from "assets/images/leftIcon.svg";

// Components
import Topbar from "components/topbar/Topbar";
import Loader from "components/loader/Loader";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

const FormAdvantages = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const advantagesData = useSelector(
    (state) => state.nutrition.nutritionAdvantages
  );
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleNext = () => {
    history.push("/nutrition/recipes");
  };

  const handlePrev = () => {
    history.push("/nutrition/nutritionForm");
  };

  useEffect(() => {
    document.body.classList.add("app-register");
    if (window.innerWidth < 600) {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);
    dispatch(
      NutritionActions.getNutritionAdvantagesRequest({
        nutritiontypeID: registrationData.nutritiontypeID,
        aimID: registrationData.aimID,
        sex: registrationData.sex,
      })
    );
    return () => {
      document.body.classList.remove("app-register");
    };
  }, [dispatch, registrationData]);

  const advantages = [
    {
      key: "advantages1",
      id: "1",
      advantage: advantagesData && advantagesData["01_advantage"],
    },
    {
      key: "advantages2",
      id: "2",
      advantage: advantagesData && advantagesData["02_advantage"],
    },
    {
      key: "advantages3",
      id: "3",
      advantage: advantagesData && advantagesData["03_advantage"],
    },
  ];

  return (
    <div style={{ height: window.innerHeight - 60 }}>
      {!isMobile && (
        <Topbar
          prev="/nutrition/nutritionForm"
          next="/nutrition/recipes"
          progress="33%"
        />
      )}
      <S.NutritionFormAdvantagesContainer>
        {isMobile ? (
          <>
            <S.NutritionFormAdvantagesHeadingContainer>
              <S.NutritionFormAdvantagesImageContainer>
                <img
                  onClick={() => handlePrev()}
                  src={LeftIcon}
                  alt="Left Icon"
                />
              </S.NutritionFormAdvantagesImageContainer>
              <S.NutritionFormAdvantagesMobileHeading>
                <TranslationMessage id="nutrition.form.mobileHeading" />
              </S.NutritionFormAdvantagesMobileHeading>
            </S.NutritionFormAdvantagesHeadingContainer>
            <S.NutritionFormAdvantagesMobileSubHeading>
              <TranslationMessage id="nutrition.form.mobileSubHeading" />
            </S.NutritionFormAdvantagesMobileSubHeading>
          </>
        ) : (
          <>
            <S.NutritionFormAdvantagesHeading>
              <TranslationMessage id="nutrition.formAdvantages.heading" />
            </S.NutritionFormAdvantagesHeading>
            <S.NutritionFormAdvantagesSubHeading>
              <TranslationMessage id="nutrition.formAdvantages.subHeading" />
            </S.NutritionFormAdvantagesSubHeading>
          </>
        )}
        <S.NutritionFormAdvantagesCardContainer>
          {!loading &&
            advantagesData &&
            advantages.map((value) => {
              return (
                <S.NutritionFormAdvantagesCard key={value.key}>
                  <S.NutritionFormAdvantagesCardInnerContainer>
                    <S.NutritionFormAdvantagesPointNumber>
                      {value.id}
                    </S.NutritionFormAdvantagesPointNumber>
                    <S.NutritionFormAdvantageData>
                      {value.advantage}
                    </S.NutritionFormAdvantageData>
                  </S.NutritionFormAdvantagesCardInnerContainer>
                </S.NutritionFormAdvantagesCard>
              );
            })}
          {<Loader loading={loading} top="150px" />}
        </S.NutritionFormAdvantagesCardContainer>
      </S.NutritionFormAdvantagesContainer>
      {isMobile && (
        <S.ButtonContainer>
          <Button
            onClick={() => handleNext()}
            color="#fff"
            style={{
              width: 95 + "%",
              borderRadius: "6px",
              background:
                "linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <TranslationMessage id="button.next" />
          </Button>
        </S.ButtonContainer>
      )}
    </div>
  );
};

export default FormAdvantages;
