import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 5rem;

  @media (max-width: 1201px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
  }
  @media (max-width: 701px) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  @media (max-width: 501px) {
    grid-template-columns: 1fr;
  }
`;

export const FunctionCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 20px 40px #02332a1a;
  border-radius: 30px;
  padding: 4rem 2.5rem 4rem;
  position: relative;
  min-height: 24.6rem;

  &:not(:hover) {
    .hover-content {
      display: none;
    }
  }

  img {
    width: 7rem;
    display: block;
    margin-inline: auto;
  }

  .title {
    margin-top: 3rem;
    text-align: center;
    font: 2.5rem/3rem Rubik;
    color: #142c1f;
  }

  .label {
    position: absolute;
    top: 3rem;
    right: 3.1rem;
    background: #a5b5af;
    border-radius: 5px;
    padding: 0.5rem 0.8rem;
    text-align: center;
    font: 1.9rem/3rem Rubik;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
`;

export const ProBadge = styled.span`
  position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    background: #a5b5af;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 0.5rem;
    text-align: center;
    font: 1.5rem/1.5rem Rubik;
    font-weight: 500;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
`;

export const HoverContent = styled.div`
  border-radius: 30px;
  padding: 2.5rem 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  .description {
    margin-bottom: 3rem;
    text-align: center;
    font: 2rem/3rem Rubik;
    color: #44554c;
  }

  .green-line {
    border: 4px solid #2ecf96;
    width: 3.9rem;
    border-radius: 4px;
    margin: 3.5rem auto 0;
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  /* font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 5rem; */
  @media (max-width: 767.99px) {
    /* font-size: 38px; */
  }
  @media (max-width: 600px) {
    /* font-size: 28px; */
  }
`;

export const Description = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  color: #44554c;
  /* font-size: 1rem;
  font-weight:400; */
`;

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin-left: auto;
    display: block;
  }

  @media (max-width: 991.99px) {
    margin-inline: auto;
    order: 1;
  }

  @media (max-width: 767.99px) {
    height: auto;
  }
`;

export const Action = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
