import React, { useState } from "react";
import * as F from "./ReimbursementCalculation.styled";

const ReimbursementCalculation = () => {
  const [DiscountValue, setDiscountValue] = useState("");

  const handleSelectCompany = (value) => {
    setDiscountValue(value);
  };

  return (
    <F.CoachingGlanceContainer>
      <div
        id="div_block-189-9"
        className="ct-div-block w-full cost-calculation rounded-xl"
      >
        <div
          id="div_block-190-9"
          className="ct-div-block flex centered ml-auto mr-auto"
        >
          <div
            id="text_block-200-9"
            className="ct-text-block color-dark text-4xl text-center uppercase mt-8 font-light"
          >
            KOSTENERSTATTUNG DURCH
            <br />
            <span
              id="span-201-9"
              className="ct-span color-dark text-4xl text-center uppercase font-semibold"
            >
              UNSERE PARTNERKRANKENKASSEN 🤘
            </span>
          </div>
          <div
            id="text_block-192-9"
            className="ct-text-block color-paragraph font-normal text-base mt-12"
          >
            Wähle deine Krankenkasse aus und wir sagen dir unter Vorbehalt wie
            hoch der Erstattungsbetrag ist. Die Angaben sind ohne Gewähr.
            <br />
          </div>

          <F.SubscriptionProDrop>
            <select onChange={(e) => handleSelectCompany(e.target.value)}>
              <option value="">Krankenkasse auswählen</option>
              <option value="75">AOK Bayern</option>
              <option value="80">AOK Bremen</option>
              <option value="100">AOK Hamburg</option>
              <option value="100">AOK Hessen</option>
              <option value="75">AOK Niedersachsen</option>
              <option value="85">AOK Nordost</option>
              <option value="80">AOK Nordwest</option>
              <option value="100">AOK Rheinland</option>
              <option value="75">AOK PLUS</option>
              <option value="80">AOK Sachsen-Anhalt</option>
              <option value="100">actimonda</option>
              <option value="100">AGIDA</option>
              <option value="90">Audi BKK</option>
              <option value="80">Bahn BKK</option>
              <option value="100">Barmer</option>
              <option value="100">Bergische Krankenkasse</option>
              <option value="100">BIG direkt</option>
              <option value="80">Bertelsmann BKK</option>
              <option value="100">Betriebskrankenkasse mobil oil</option>
              <option value="100">BKK 24</option>
              <option value="80">BKK Achenbach</option>
              <option value="90">BKK Akzo Nobel </option>
              <option value="80">BKK Diakonie</option>
              <option value="100">BKK Euregio</option>
              <option value="100">BKK Exklusiv</option>
              <option value="75">BKK Faber</option>
              <option value="90">BKK Firmus</option>
              <option value="100">BKK Freudenberg</option>
              <option value="75">BKK Gildemeister</option>
              <option value="95">BKK Herkules</option>
              <option value="100">BKK Linde</option>
              <option value="100">BKK Melitta hmr</option>
              <option value="80">BKK Pfaff</option>
              <option value="90">BKK Pfalz</option>
              <option value="80">BKK ProVita</option>
              <option value="85">BKK Scheufelen</option>
              <option value="85">BKK Schwarzwald-Baar-Heuberg</option>
              <option value="75">BKK Technoform</option>
              <option value="85">BKK TUI</option>
              <option value="80">BKK VBU</option>
              <option value="75">Bkk VerbundPlus</option>
              <option value="75">BKK Wera-Meissner</option>
              <option value="100">BKK Wirtschaft &amp; Finanzen</option>
              <option value="80">Betriebskrankenkasse WMF</option>
              <option value="80">BKK ZF &amp; Partner</option>
              <option value="80">Bosch BKK</option>
              <option value="100">Continentale Betriebskrankenkasse</option>
              <option value="75">DAK-Gesundheit</option>
              <option value="75">Debeka BKK</option>
              <option value="80">energie-BKK</option>
              <option value="80">Heimat Krankenkasse</option>
              <option value="80">HEK - Hanseatische Krankenkasse</option>
              <option value="80">hkk Krankenkasse</option>
              <option value="100">IKK</option>
              <option value="75">IKK Brandenburg und Berlin</option>
              <option value="90">IKK classic</option>
              <option value="75">IKK Südwest</option>
              <option value="100">KHH Kaufmännische Krankenkasse</option>
              <option value="80">Knappschaft</option>
              <option value="70">mhplus Betriebskrankenkasse</option>
              <option value="100">Novitas BKK</option>
              <option value="80">Pronova BKK</option>
              <option value="80">R+V Betriebskrankenkasse</option>
              <option value="80">Salus BKK</option>
              <option value="100">Securvita Krankenkasse</option>
              <option value="90">SKD BKK</option>
              <option value="100">TK - Techniker Krankenkasse</option>
              <option value="100">Viactiv Krankenkasse</option>
              <option value="80">Vivida BKK</option>
            </select>
          </F.SubscriptionProDrop>

          <div id="auswahl" className="ct-text-block">
            {DiscountValue && (
              <p>{`${DiscountValue}% of the course costs will be reimbursed`}</p>
            )}
            {!DiscountValue && (
              <p>{`Hier klicken, um die Höhe Deiner Erstattung zu erfahren!`}</p>
            )}
          </div>
        </div>
      </div>
      <br /> <br /> <br />
      <div id="div_block-186-9" className="ct-div-block">
        <div id="text_block-187-9" className="ct-text-block">
          Unser Foodiary-Präventionskurs ist offiziell nach § 20 Abs. 1 SGB V
          zertifiziert. Die meisten gesetzlichen Krankenkassen erstatten bis zu
          100% der Teilnahmekosten. <br />
          <br />
          Hinweis: Es wird ausdrücklich darauf hingewiesen, dass die Teilnahme
          am Präventionskurs, nicht für jeden geeignet ist und dass dieses
          Online-Kurs auf eigene Gefahr genutzt wird. Im Zweifelsfall sollte vor
          Beginn des Kurses medizinisches Fachpersonal (Arzt) konsultiert
          werden. Der Präventionskurs ist kein Ersatz für eine ärztliche
          Untersuchung oder Behandlung. Mögliche Einschränkungen aufgrund
          chronischer oder akuter Erkrankungen sowie für Frauen während und nach
          der Schwangerschaft sind zu beachten.
        </div>
      </div>
    </F.CoachingGlanceContainer>
  );
};

export default ReimbursementCalculation;
